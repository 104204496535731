import { FC, useCallback, useMemo, useState } from "react";
import { Button, Stack, TableCell, TableRow, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import {
  FilterSet,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";
import { AccountSelect } from "../../../../../common/account-select/AccountSelect";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { allAccountsSelectOptionsSelector } from "../../../../../../store/accounts/selectors/all-providers/allAccountsSelectOptionsSelector";
import { patchFilterTemplateThunk } from "../../../../../../store/filters/thunks/filter-set/patchFilterTemplateThunk";
import { createFilterTemplateThunk } from "../../../../../../store/filters/thunks/filter-set/createFilterTemplateThunk";
import { currentAccountIdSelector } from "../../../../../../store/account/selectors/current-account/currentAccountIdSelector";
import { applyFilterTemplateToCurrentAccount } from "../../../../../../store/filters/thunks/filter-set/applyFilterSetToCurrentAccount";
import { defaultFilterTemplateIdSelector } from "../../../../../../store/filters/selectors/filter-set/default/defaultFilterTemplateIdSelector";
import { TypographyWithTooltip } from "../../../../../common/TypographyWithTooltip";
import c8rLogo from "../../../../../../assets/images/logos/c8r-logo.svg";
import { getOrganisationFilterSetsThunk } from "../../../../../../store/filters/thunks/filter-set/getOrganisationFilterSetsThunk";
import { getAccountsThunk } from "../../../../../../store/accounts/thunks/getAccountsThunk";
import { disabledNonConnectedAllAccountsIdsByProviderSelector } from "../../../../../../store/accounts/selectors/all-providers/disabledNonConnectedAllAccountsIdsByProviderSelector";
import { allAccountsIdsByProviderSelector } from "../../../../../../store/accounts/selectors/all-providers/allAccountsIdsByProviderSelector";

interface FilterTemplatesModifyRowProps {
  type: "duplicate" | "modify";
  filterSet: FilterSet;
  provider?: ProviderType;
  onCancel(): void;
  hideEms?: boolean;
  accountsModify?: boolean;
}

export const FilterTemplatesModifyRow: FC<FilterTemplatesModifyRowProps> = ({
  filterSet,
  onCancel,
  type,
  accountsModify,
  provider,
  hideEms,
}) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState(filterSet.name);
  const [loading, setLoading] = useState(false);
  const groupedAllAccounts = useAppSelector(allAccountsSelectOptionsSelector);
  const accountId = useAppSelector(currentAccountIdSelector);
  const defaultFilterTemplateId = useAppSelector((state) =>
    defaultFilterTemplateIdSelector(state, accountId ?? ""),
  );
  const allAccountsIds = useAppSelector((state) =>
    allAccountsIdsByProviderSelector(state, provider),
  );
  const disabledAccountsIds = useAppSelector((state) =>
    disabledNonConnectedAllAccountsIdsByProviderSelector(state, provider),
  );

  const [accountIds, setAccountIds] = useState(
    filterSet.accounts
      ?.map(({ id }) => id)
      .filter(
        (id) =>
          !disabledAccountsIds?.includes(id) && allAccountsIds?.includes(id),
      ) ?? [],
  );

  const options = useMemo(() => {
    return groupedAllAccounts
      .filter(({ accountType }) => accountType === provider)
      .map((data) => ({
        ...data,
        disabled: data.disabled || data.status !== "connected",
      }));
  }, [groupedAllAccounts, provider]);

  const nameChangeHandler = useCallback((event: any) => {
    setName(event.target.value);
  }, []);

  const accountsChangeHandler = useCallback((ids: any) => {
    setAccountIds(ids);
  }, []);

  const modifyHandler = useCallback(async () => {
    if (!provider) {
      return;
    }
    setLoading(true);

    await dispatch(
      patchFilterTemplateThunk({
        name,
        provider,
        accountIds,
        filterTemplateId: filterSet.id,
      }),
    );

    if (!accountIds.length && defaultFilterTemplateId && !hideEms) {
      await dispatch(
        applyFilterTemplateToCurrentAccount(defaultFilterTemplateId),
      );
    }

    if (hideEms) {
      await dispatch(getOrganisationFilterSetsThunk());
      await dispatch(getAccountsThunk());
    }

    setLoading(false);
    onCancel();
  }, [
    dispatch,
    onCancel,
    provider,
    defaultFilterTemplateId,
    filterSet.id,
    name,
    accountIds,
    hideEms,
  ]);

  const duplicateHandler = useCallback(async () => {
    if (!provider) {
      return;
    }

    setLoading(true);

    await dispatch(
      createFilterTemplateThunk({
        name,
        provider,
        accountIds,
        filters: filterSet.filters,
      }),
    );

    if (hideEms) {
      await dispatch(getOrganisationFilterSetsThunk());
      await dispatch(getAccountsThunk());
    }

    setLoading(false);
    onCancel();
  }, [
    dispatch,
    onCancel,
    hideEms,
    provider,
    accountIds,
    filterSet.filters,
    name,
  ]);

  return (
    <TableRow>
      <TableCell sx={{ py: 2, maxWidth: 200 }}>
        {accountsModify ? (
          <Stack direction="row" spacing={2}>
            <TypographyWithTooltip variant="body2" title={filterSet?.name} />

            {filterSet.source === "cloudchipr" && (
              <img src={c8rLogo} alt="C8R-logo" />
            )}
          </Stack>
        ) : (
          <TextField
            fullWidth
            type="text"
            size="small"
            placeholder="Name"
            value={name}
            onChange={nameChangeHandler}
            disabled={filterSet.source === "cloudchipr" && type === "modify"}
          />
        )}
      </TableCell>

      <TableCell>
        <AccountSelect
          onChange={accountsChangeHandler}
          selectedValue={accountIds}
          options={options}
        />
      </TableCell>

      <TableCell sx={{ pr: 0, maxWidth: 100 }}>
        <Stack direction="row" spacing={2}>
          <Button onClick={onCancel} color="tertiary" size="small">
            Cancel
          </Button>

          <LoadingButton
            loading={loading}
            disabled={loading || !name}
            size="small"
            variant="contained"
            onClick={type === "duplicate" ? duplicateHandler : modifyHandler}
          >
            Save
          </LoadingButton>
        </Stack>
      </TableCell>

      <TableCell sx={{ p: 0 }} />
    </TableRow>
  );
};
