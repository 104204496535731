import { FC } from "react";
import { FilterValueInput } from "./FilterValueInput";
import { FilterValueAutocomplete } from "./FilterValueAutocomplete";
import { FilterValueByKeyAutocomplete } from "./FilterValueByKeyAutocomplete";
import {
  ResourceExplorerDynamicFilterItem,
  KeyValuePair,
} from "../../../../../../../../../services/cloudchipr.api";
import { filterAvailableFieldsChecker } from "../../../../../utils/helpers/filterAvailableFieldsChecker";
import { getFilterValueDataTypeByOperator } from "../../../../../utils/helpers/operators";
import { DynamicProviderFilterItem } from "../../../../../utils/types/common";

interface FilterValueProps {
  filter: DynamicProviderFilterItem;
  onChange(value: ResourceExplorerDynamicFilterItem["value"]): void;
}

export const FilterValue: FC<FilterValueProps> = ({ filter, onChange }) => {
  const { hasKey } = filterAvailableFieldsChecker(filter.type, filter.operator);
  const valueType = getFilterValueDataTypeByOperator(filter.operator);
  const filterValue = hasKey
    ? (filter.value as KeyValuePair)?.value
    : filter.value;

  const valueIsPrimitive = valueType === "string";

  if (valueIsPrimitive) {
    return (
      <FilterValueInput
        onChange={onChange}
        filterValue={filterValue as string}
      />
    );
  }

  if (hasKey) {
    return (
      <FilterValueByKeyAutocomplete
        onChange={onChange}
        filterType={filter.type}
        provider={filter.cloud_provider}
        value={filter?.value as KeyValuePair}
      />
    );
  }

  return (
    <FilterValueAutocomplete
      onChange={onChange}
      filterValue={filterValue as string[]}
      filterType={filter.type}
      provider={filter.cloud_provider}
    />
  );
};
