import { v4 as uuid } from "uuid";
import {
  CategoryFilter,
  FilterGroup,
  ResourceExplorerDynamicFilterItem,
  ProviderType,
  ResourceExplorerFilterProvider,
} from "../../../../../services/cloudchipr.api";
import {
  DynamicFiltersType,
  DynamicProviderFilter,
  FiltersCombinationOperatorType,
} from "../../../common/dynamic-filters/utils/types/common";

export const convertCategoriesFiltersToDynamicFilters = (
  filters: CategoryFilter,
): DynamicFiltersType => {
  if (!filters.filters) {
    return {};
  }

  const operators = filters.filters.operators;
  const filterItems = filters.filters.filter_items;
  const filterGroups = filters.filters.filter_groups;
  let operatorIndex = 0;

  const dynamicFilters: DynamicFiltersType = generateDynamicFilters(
    filterGroups,
    filterItems,
    operators.at(operatorIndex),
  );
  operatorIndex++;

  const addRecursive = (
    filterGroups: FilterGroup,
    provider: ProviderType,
  ): DynamicProviderFilter[] => {
    return filterGroups.map((filterGroup) => {
      if (Array.isArray(filterGroup)) {
        const newFiltersGroup = {
          operator: operators[operatorIndex],
          filters: addRecursive(filterGroup, provider),
        };

        operatorIndex++;

        return newFiltersGroup;
      } else {
        return { ...filterItems[filterGroup], id: uuid() };
      }
    });
  };

  Object.keys(dynamicFilters).forEach(
    (dynamicFilterKey, dynamicFilerProviderIndex) => {
      const provider = dynamicFilterKey as ProviderType;
      const providerFilterGroups = filterGroups[dynamicFilerProviderIndex];

      const smthWentWrong =
        !providerFilterGroups || typeof providerFilterGroups === "number";

      if (smthWentWrong) {
        return;
      }

      providerFilterGroups.forEach((filterGroup) => {
        if (!dynamicFilters[provider]) {
          return;
        }

        if (Array.isArray(filterGroup)) {
          const newFiltersGroup = {
            operator: operators[operatorIndex++],
            filters: addRecursive(filterGroup, provider),
          };

          dynamicFilters[provider].filters.push(newFiltersGroup);
        } else {
          dynamicFilters[provider].filters.push({
            ...filterItems[filterGroup],
            id: uuid(),
          });
        }
      });
    },
  );

  return dynamicFilters;
};

const findProvider = (
  filterItems?: ResourceExplorerDynamicFilterItem[],
  groupOrIndex?: FilterGroup | number,
): ResourceExplorerFilterProvider | undefined => {
  if (!groupOrIndex && groupOrIndex !== 0) {
    return;
  }

  if (typeof groupOrIndex === "number") {
    return filterItems?.[groupOrIndex]?.cloud_provider;
  }

  return findProvider(filterItems, groupOrIndex?.at(0));
};

const generateDynamicFilters = (
  filterGroups: FilterGroup,
  filterItems: ResourceExplorerDynamicFilterItem[],
  operator?: FiltersCombinationOperatorType,
) => {
  return filterGroups.reduce((dynamicFilters, filterGroup) => {
    const provider = findProvider(filterItems, filterGroup);

    if (!provider || !operator) {
      return dynamicFilters;
    }

    dynamicFilters[provider] = { operator, filters: [] };

    return dynamicFilters;
  }, {} as DynamicFiltersType);
};
