import { htmlToSlackMd } from "../../../../../common/integration-dialogs/components/slack/utils/htmlToSlackMd";
import { Schedule } from "../../../../../../services/cloudchipr.api";

export const formatWorkflowNotificationsFromHtmlToSlack = (
  notifications: Schedule["notifications"],
) => {
  return notifications?.map((item) => {
    if (item.type === "slack") {
      item = {
        ...item,
        custom_message: htmlToSlackMd(item.custom_message ?? []),
      };
      return item;
    }
    return item;
  });
};
