import { ChangeEvent, FC, useCallback, useState } from "react";
import { TextField } from "@mui/material";
import { useDebounce } from "rooks";

interface FilterValueInputProps {
  filterValue?: string;
  onChange(value: string): void;
}

export const FilterValueInput: FC<FilterValueInputProps> = ({
  filterValue,
  onChange,
}) => {
  const [value, setValue] = useState(filterValue ?? "");

  const debouncedChangeHandler = useDebounce(onChange, 500);

  const inputValueChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;

      setValue(val);
      debouncedChangeHandler(val);
    },
    [debouncedChangeHandler],
  );

  return (
    <TextField
      fullWidth
      label="Value"
      size="xsmall"
      value={value}
      onChange={inputValueChangeHandler}
    />
  );
};
