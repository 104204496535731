import { KeyValuePair } from "../../../../services/cloudchipr.api";
import { DimensionCategory } from "../types/common";
import { isDynamicProviderFilters } from "../../../../components/pages/common/dynamic-filters/utils/helpers/isDynamicProviderFilters";
import { filterAvailableFieldsChecker } from "../../../../components/pages/common/dynamic-filters/utils/helpers/filterAvailableFieldsChecker";
import { DynamicProviderFilter } from "../../../../components/pages/common/dynamic-filters/utils/types/common";

const validateRecursive = (filters: DynamicProviderFilter[]): boolean => {
  if (!filters.length) {
    return true;
  }

  return filters.some((filter) => {
    if (isDynamicProviderFilters(filter)) {
      return validateRecursive(filter.filters);
    } else {
      const { hasKey } = filterAvailableFieldsChecker(
        filter.type,
        filter.operator,
      );

      const filterValue = hasKey
        ? (filter.value as KeyValuePair)?.value
        : filter.value;

      if (typeof filterValue === "number") {
        return !filter.value;
      }

      if (typeof filterValue === "string" || Array.isArray(filterValue)) {
        return !filterValue?.length;
      }

      return !filterValue;
    }
  });
};

export const categoryFiltersAreInvalid = (category: DimensionCategory) => {
  if (!category.name.trim()) {
    return true;
  }

  const filtersValues = Object.values(category.filters);

  if (!filtersValues.length) {
    return true;
  }

  return filtersValues.some((filters) => {
    return validateRecursive(filters.filters);
  });
};
