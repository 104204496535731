import { FC, useCallback } from "react";
import { enqueueSnackbar } from "notistack";
import { ConfirmationDialog } from "../../../../common/modals/ConfirmationDialog";
import {
  generateWorkflowUpdatingData,
  getWorkflowUpdatePayloadData,
} from "../utils/helpers/helpers";
import {
  Schedule,
  usePatchV2UsersMeSchedulesByScheduleIdMutation,
} from "../../../../../services/cloudchipr.api";
import { getSchedulesThunk } from "../../../../../store/schedules/thunks/getSchedulesThunk";
import { useAppDispatch } from "../../../../../store/hooks";

interface WithoutAccountsWorkflowDialogProps {
  workflow: Schedule;
  onClose(): void;
}

export const WithoutAccountsWorkflowDialog: FC<
  WithoutAccountsWorkflowDialogProps
> = ({ onClose, workflow }) => {
  const dispatch = useAppDispatch();

  const [updateSchedule, { isLoading }] =
    usePatchV2UsersMeSchedulesByScheduleIdMutation();

  const deleteHandler = useCallback(async () => {
    await updateSchedule(
      getWorkflowUpdatePayloadData(
        generateWorkflowUpdatingData({ ...workflow, status: "deleted" }),
      ),
    );
    enqueueSnackbar("Workflow successfully deleted.", {
      variant: "snackbarAlert",
      AlertSnackBarProps: { severity: "success" },
    });

    await dispatch(getSchedulesThunk(true));
    onClose();
  }, [workflow, onClose, dispatch, updateSchedule]);

  return (
    <ConfirmationDialog
      open
      title="Schedule has no accounts"
      dialogContent="The account you previously added to this schedule is no longer connected. Want to delete incomplete schedule?"
      CancelButtonProps={{
        onClick: onClose,
      }}
      ConfirmButtonProps={{
        loading: isLoading,
        onClick: deleteHandler,
        children: "Delete",
      }}
      onClose={onClose}
    />
  );
};
