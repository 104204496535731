import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { createWidgetThunk } from "../common/createWidgetThunk";
import { openWidgetBuildingSnackbar } from "../utils/openWidgetBuildingSnackbar";
import { costBreakdownV2WidgetByIdSelector } from "../../../selectors/widgets/cost-breakdown-v2/widget-view/costBreakdownV2WidgetByIdSelector";

export const duplicateCostBreakdownV2WidgetThunk = createAsyncThunk(
  "dashboards/duplicateCostBreakdownV2WidgetThunk",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = costBreakdownV2WidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        name: widgetName,
        grouping: widget.grouping,
        group_values: widget.group_values,
        type: "cost_breakdown",
        chart_type: widget.chart_type,
        date_granularity: widget.date_granularity,
        view_id: widget.view_id,
        layout: null,
        forecast_option: widget.forecast_option,
        dates: widget.dates,
        trend_type: widget.trend_type ?? "month_over_month",
      }),
    ).unwrap();

    if (response) {
      openWidgetBuildingSnackbar(widget.type, "duplicate");
    }
    return response;
  },
);
