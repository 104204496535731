import { FC, useState } from "react";

import { UserInvitationSent } from "./UserInvitationSent";
import { UserAwaitingInvitation } from "./UserAwaitingInvitation";
import { UserStatus } from "../../../services/cloudchipr.api";

interface UserInvitationWrapperProps {
  status: UserStatus;
}
export const UserInvitationWrapper: FC<UserInvitationWrapperProps> = ({
  status,
}) => {
  const [localStatus, setLocalStatus] = useState(status);

  if (localStatus === "awaiting_invitation") {
    return <UserAwaitingInvitation onStatusChange={setLocalStatus} />;
  }

  if (localStatus === "in_review") {
    return <UserInvitationSent />;
  }

  return null;
};
