import { FC, SyntheticEvent, useCallback } from "react";
import { RadioGroup, Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { ViewTrendSettingsRadioButton } from "./ViewTrendSettingsRadioButton";
import { setResourceExplorerTrendType } from "../../../../../../../../../../store/resource-explorer/resourceExplorerSlice";
import { TrendType } from "../../../../../../../../../../services/cloudchipr.api";
import { getResourceExplorerDataThunk } from "../../../../../../../../../../store/resource-explorer/thunks/widgets/getResourceExplorerDataThunk";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../../store/hooks";
import { resourceExplorerPathLastItemFiltersSelector } from "../../../../../../../../../../store/resource-explorer/selectors/current-resource-explorer/path/resourceExplorerPathLastItemFiltersSelector";

export const ViewTrendSettings: FC = () => {
  const dispatch = useAppDispatch();
  const pathLastItemFilters = useAppSelector(
    resourceExplorerPathLastItemFiltersSelector,
  );

  const handleChange = useCallback(
    (event: SyntheticEvent, checked: boolean) => {
      const target = event.target as HTMLInputElement;

      if (checked) {
        dispatch(
          setResourceExplorerTrendType(target.defaultValue as TrendType),
        );
        dispatch(
          getResourceExplorerDataThunk({
            filtersFromPath: pathLastItemFilters,
          }),
        );
      }
    },
    [dispatch, pathLastItemFilters],
  );

  return (
    <Stack px={2} py={1} bgcolor="grey.100" spacing={1}>
      <Typography variant="body2" fontWeight="bold">
        Trend Settings
      </Typography>
      <Typography variant="caption">
        This setting is relevant for date ranges less than 30 days.
      </Typography>
      <RadioGroup>
        <ViewTrendSettingsRadioButton
          value="month_over_month"
          onChange={handleChange}
        />
        <ViewTrendSettingsRadioButton
          value="period_over_period"
          onChange={handleChange}
        />
      </RadioGroup>
    </Stack>
  );
};
