import { FC, ReactElement, useCallback } from "react";
import { IconButton, Stack } from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  EmailsSelectionIntegrationsSelect,
  EmailsSelectionIntegrationsSelectProps,
} from "./EmailsSelectionIntegrationsSelect";
import {
  EmailsSelection,
  EmailsSelectionErrors,
} from "../../../../../common/integration-dialogs/components/email/components/email-selection/EmailsSelection";
import { Emails } from "../../../../../../services/cloudchipr.api";

export interface NotificationsEmailSelectionProps
  extends EmailsSelectionIntegrationsSelectProps {
  emailsErrors: EmailsSelectionErrors;
  CustomMessage?: ReactElement;
  selectedEmails: Emails | null;
}

export const NotificationsEmailSelection: FC<
  NotificationsEmailSelectionProps
> = ({ emailsErrors, selectedEmails, CustomMessage, ...props }) => {
  const { emailsChangeHandler } = props;

  const deleteEmailsHandler = useCallback(() => {
    emailsChangeHandler(null);
  }, [emailsChangeHandler]);

  if (!selectedEmails) {
    return null;
  }

  return (
    <Stack direction="row" spacing={2} mt={2} alignItems="start">
      <Stack flexGrow={1} gap={2}>
        <EmailsSelectionIntegrationsSelect {...props} />

        {CustomMessage ? (
          <Stack spacing={2}>
            <EmailsSelection
              errors={emailsErrors}
              toEmails={selectedEmails.to}
              ccEmails={selectedEmails.cc}
              bccEmails={selectedEmails.bcc}
              onEmailsChange={emailsChangeHandler}
            />

            {CustomMessage}
          </Stack>
        ) : (
          <EmailsSelection
            errors={emailsErrors}
            toEmails={selectedEmails.to}
            ccEmails={selectedEmails.cc}
            bccEmails={selectedEmails.bcc}
            onEmailsChange={emailsChangeHandler}
          />
        )}
      </Stack>

      <IconButton onClick={deleteEmailsHandler}>
        <DeleteOutlineIcon />
      </IconButton>
    </Stack>
  );
};
