import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { costAndUsageSumWidgetByIdSelector } from "../../../selectors/widgets/cost-and-usage/costAndUsageSumWidgetByIdSelector";
import { createWidgetThunk } from "../common/createWidgetThunk";
import { openWidgetBuildingSnackbar } from "../utils/openWidgetBuildingSnackbar";

export const duplicateCostAndUsageSumWidgetThunk = createAsyncThunk(
  "dashboards/duplicateCostAndUsageSumWidget",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = costAndUsageSumWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        name: widgetName,
        filter: widget.filter,
        type: "cost_and_usage_summary",
        date_from: widget.date_from,
        date_to: widget.date_to,
        date_label: widget.date_label,
        view_id: widget.view_id,
        layout: null,
        trend_type: widget.trend_type ?? "month_over_month",
      }),
    ).unwrap();

    if (response) {
      openWidgetBuildingSnackbar(widget.type, "duplicate");
    }

    return response;
  },
);
