import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { costBreakdownWidgetByIdSelector } from "../../../selectors/widgets/cost-breakdown/costBreakdownWidgetByIdSelector";
import { createWidgetThunk } from "../common/createWidgetThunk";
import { openWidgetBuildingSnackbar } from "../utils/openWidgetBuildingSnackbar";

export const duplicateCostBreakdownWidgetThunk = createAsyncThunk(
  "dashboards/duplicateCostBreakdownWidgetThunk",
  async (
    { widgetId, widgetName }: { widgetId: string; widgetName: string },
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const widget = costBreakdownWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    const response = await dispatch(
      createWidgetThunk({
        name: widgetName,
        date_to: widget.date_to,
        grouping: widget.grouping,
        group_values: widget.group_values,
        type: "cost_breakdown",
        date_from: widget.date_from,
        date_label: widget.date_label,
        chart_type: widget.chart_type,
        date_granularity: widget.date_granularity,
        view_id: widget.view_id,
        layout: null,
        forecast_option: widget.forecast_option,
        trend_type: widget.trend_type ?? "month_over_month",
      }),
    ).unwrap();

    if (response) {
      openWidgetBuildingSnackbar(widget.type, "duplicate");
    }
    return response;
  },
);
