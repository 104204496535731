import { emptySplitApi as api } from "./empty.api";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getUsersMe: build.query<GetUsersMeApiResponse, GetUsersMeApiArg>({
      query: (queryArg) => ({
        url: `/users/me`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    patchUsersMe: build.mutation<PatchUsersMeApiResponse, PatchUsersMeApiArg>({
      query: (queryArg) => ({
        url: `/users/me`,
        method: "PATCH",
        body: queryArg.body,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    putUsersMe: build.mutation<PutUsersMeApiResponse, PutUsersMeApiArg>({
      query: (queryArg) => ({
        url: `/users/me`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteUsersMe: build.mutation<
      DeleteUsersMeApiResponse,
      DeleteUsersMeApiArg
    >({
      query: () => ({ url: `/users/me`, method: "DELETE" }),
    }),
    postUsersMeOrganisationsCurrentAccountsByAccountIdRecommendationsStatus:
      build.mutation<
        PostUsersMeOrganisationsCurrentAccountsByAccountIdRecommendationsStatusApiResponse,
        PostUsersMeOrganisationsCurrentAccountsByAccountIdRecommendationsStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/accounts/${queryArg.accountId}/recommendations/status`,
          method: "POST",
        }),
      }),
    getUsersMeOrganisationsCurrentRecommendationsStatus: build.query<
      GetUsersMeOrganisationsCurrentRecommendationsStatusApiResponse,
      GetUsersMeOrganisationsCurrentRecommendationsStatusApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/recommendations/status`,
      }),
    }),
    postUsersMeOrganisationsCurrentUsers: build.mutation<
      PostUsersMeOrganisationsCurrentUsersApiResponse,
      PostUsersMeOrganisationsCurrentUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/users`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentUsers: build.query<
      GetUsersMeOrganisationsCurrentUsersApiResponse,
      GetUsersMeOrganisationsCurrentUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/users`,
        params: {
          page: queryArg.page,
          size: queryArg.size,
          role: queryArg.role,
        },
      }),
    }),
    postUsersMeInvitationRequests: build.mutation<
      PostUsersMeInvitationRequestsApiResponse,
      PostUsersMeInvitationRequestsApiArg
    >({
      query: () => ({ url: `/users/me/invitation-requests`, method: "POST" }),
    }),
    getUsersMeOrganisationsCurrentInvitationRequests: build.query<
      GetUsersMeOrganisationsCurrentInvitationRequestsApiResponse,
      GetUsersMeOrganisationsCurrentInvitationRequestsApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/invitation-requests`,
      }),
    }),
    deleteUsersMeOrganisationsCurrentInvitationRequestsByUserId: build.mutation<
      DeleteUsersMeOrganisationsCurrentInvitationRequestsByUserIdApiResponse,
      DeleteUsersMeOrganisationsCurrentInvitationRequestsByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/invitation-requests/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    postUsersMeOrganisationsCurrentUsersByUserIdReInvitations: build.mutation<
      PostUsersMeOrganisationsCurrentUsersByUserIdReInvitationsApiResponse,
      PostUsersMeOrganisationsCurrentUsersByUserIdReInvitationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/users/${queryArg.userId}/re-invitations`,
        method: "POST",
      }),
    }),
    getUsersMeOrganisationsCurrentUsersByUserId: build.query<
      GetUsersMeOrganisationsCurrentUsersByUserIdApiResponse,
      GetUsersMeOrganisationsCurrentUsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/users/${queryArg.userId}`,
      }),
    }),
    patchUsersMeOrganisationsCurrentUsersByUserId: build.mutation<
      PatchUsersMeOrganisationsCurrentUsersByUserIdApiResponse,
      PatchUsersMeOrganisationsCurrentUsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/users/${queryArg.userId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeOrganisationsCurrentUsersByUserId: build.mutation<
      DeleteUsersMeOrganisationsCurrentUsersByUserIdApiResponse,
      DeleteUsersMeOrganisationsCurrentUsersByUserIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/users/${queryArg.userId}`,
        method: "DELETE",
      }),
    }),
    postUsersMeAccountAttempts: build.mutation<
      PostUsersMeAccountAttemptsApiResponse,
      PostUsersMeAccountAttemptsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/account-attempts`,
        method: "POST",
        body: queryArg.body,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    getUsersMeAccountAttemptsByAccountAttemptId: build.query<
      GetUsersMeAccountAttemptsByAccountAttemptIdApiResponse,
      GetUsersMeAccountAttemptsByAccountAttemptIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/account-attempts/${queryArg.accountAttemptId}`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    patchProvidersAwsAccountAttemptsByAccountAttemptId: build.mutation<
      PatchProvidersAwsAccountAttemptsByAccountAttemptIdApiResponse,
      PatchProvidersAwsAccountAttemptsByAccountAttemptIdApiArg
    >({
      query: (queryArg) => ({
        url: `/providers/aws/account-attempts/${queryArg.accountAttemptId}`,
        method: "PATCH",
        body: queryArg.body,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    postProvidersAwsCfnTemplates: build.mutation<
      PostProvidersAwsCfnTemplatesApiResponse,
      PostProvidersAwsCfnTemplatesApiArg
    >({
      query: (queryArg) => ({
        url: `/providers/aws/cfn-templates`,
        method: "POST",
        headers: { "Content-Type": queryArg["Content-Type"] },
        params: { access_type: queryArg.accessType },
      }),
    }),
    patchUsersMeProvidersAzureAccountAttemptsByAccountAttemptId: build.mutation<
      PatchUsersMeProvidersAzureAccountAttemptsByAccountAttemptIdApiResponse,
      PatchUsersMeProvidersAzureAccountAttemptsByAccountAttemptIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/azure/account-attempts/${queryArg.accountAttemptId}`,
        method: "PATCH",
        body: queryArg.body,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    getUsersMeAccounts: build.query<
      GetUsersMeAccountsApiResponse,
      GetUsersMeAccountsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts`,
        headers: { "Content-Type": queryArg["Content-Type"] },
        params: {
          statuses: queryArg.statuses,
          type: queryArg["type"],
          access_type: queryArg.accessType,
        },
      }),
    }),
    getUsersMeAccountsByAccountId: build.query<
      GetUsersMeAccountsByAccountIdApiResponse,
      GetUsersMeAccountsByAccountIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    patchUsersMeAccountsByAccountId: build.mutation<
      PatchUsersMeAccountsByAccountIdApiResponse,
      PatchUsersMeAccountsByAccountIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}`,
        method: "PATCH",
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    deleteUsersMeAccountsByAccountId: build.mutation<
      DeleteUsersMeAccountsByAccountIdApiResponse,
      DeleteUsersMeAccountsByAccountIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}`,
        method: "DELETE",
      }),
    }),
    patchUsersMeProvidersByProviderAccountsAndAccountIdAccessMode:
      build.mutation<
        PatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeApiResponse,
        PatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/providers/${queryArg.provider}/accounts/${queryArg.accountId}/access-mode`,
          method: "PATCH",
          body: queryArg.body,
          headers: { "Content-Type": queryArg["Content-Type"] },
        }),
      }),
    postUsersMeAccountsByAccountIdFilters: build.mutation<
      PostUsersMeAccountsByAccountIdFiltersApiResponse,
      PostUsersMeAccountsByAccountIdFiltersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/filters`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeAccountsByAccountIdFiltersV2: build.mutation<
      PostUsersMeAccountsByAccountIdFiltersV2ApiResponse,
      PostUsersMeAccountsByAccountIdFiltersV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/filters/v2`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeAccountsByAccountIdFiltersCurrent: build.query<
      GetUsersMeAccountsByAccountIdFiltersCurrentApiResponse,
      GetUsersMeAccountsByAccountIdFiltersCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/filters/current`,
      }),
    }),
    getUsersMeAccountsByAccountIdFiltersCurrentV2: build.query<
      GetUsersMeAccountsByAccountIdFiltersCurrentV2ApiResponse,
      GetUsersMeAccountsByAccountIdFiltersCurrentV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/filters/current/v2`,
      }),
    }),
    getUsersMeAccountsByAccountIdCosts: build.query<
      GetUsersMeAccountsByAccountIdCostsApiResponse,
      GetUsersMeAccountsByAccountIdCostsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/costs`,
        params: {
          data_point: queryArg.dataPoint,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
        },
      }),
    }),
    getUsersMeOrganisationsCurrentProvidersByProviderBillingStatus: build.query<
      GetUsersMeOrganisationsCurrentProvidersByProviderBillingStatusApiResponse,
      GetUsersMeOrganisationsCurrentProvidersByProviderBillingStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/providers/${queryArg.provider}/billing/status`,
      }),
    }),
    getV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatus:
      build.query<
        GetV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatusApiResponse,
        GetV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/users/me/organisations/current/providers/${queryArg.provider}/billing/status`,
        }),
      }),
    getUsersMeOrganisationsCurrentProvidersByProviderBillingCurrent:
      build.query<
        GetUsersMeOrganisationsCurrentProvidersByProviderBillingCurrentApiResponse,
        GetUsersMeOrganisationsCurrentProvidersByProviderBillingCurrentApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/providers/${queryArg.provider}/billing/current`,
        }),
      }),
    getUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendations:
      build.query<
        GetUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendationsApiResponse,
        GetUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendationsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resources/daily-off-hours-recommendations`,
          params: { group: queryArg.group, is_dismissed: queryArg.isDismissed },
        }),
      }),
    getUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendations:
      build.query<
        GetUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendationsApiResponse,
        GetUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendationsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resources/weekly-off-hours-recommendations`,
          params: { group: queryArg.group, is_dismissed: queryArg.isDismissed },
        }),
      }),
    postV3UsersMeExecutionLogs: build.mutation<
      PostV3UsersMeExecutionLogsApiResponse,
      PostV3UsersMeExecutionLogsApiArg
    >({
      query: (queryArg) => ({
        url: `/v3/users/me/execution-logs`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeExecutionLogsDetails: build.mutation<
      PostUsersMeExecutionLogsDetailsApiResponse,
      PostUsersMeExecutionLogsDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/execution-logs/details`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getV3UsersMeExecutionLogsByExecutionId: build.query<
      GetV3UsersMeExecutionLogsByExecutionIdApiResponse,
      GetV3UsersMeExecutionLogsByExecutionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v3/users/me/execution-logs/${queryArg.executionId}`,
      }),
    }),
    postUsersMeOrganisationsCurrentByTypeResourcesTagKeys: build.mutation<
      PostUsersMeOrganisationsCurrentByTypeResourcesTagKeysApiResponse,
      PostUsersMeOrganisationsCurrentByTypeResourcesTagKeysApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/${queryArg["type"]}-resources/tag-keys`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeOrganisationsCurrentByTypeResourcesTagValues: build.mutation<
      PostUsersMeOrganisationsCurrentByTypeResourcesTagValuesApiResponse,
      PostUsersMeOrganisationsCurrentByTypeResourcesTagValuesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/${queryArg["type"]}-resources/tag-values`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeV2OrganisationsCurrentByTypeResources: build.mutation<
      PostUsersMeV2OrganisationsCurrentByTypeResourcesApiResponse,
      PostUsersMeV2OrganisationsCurrentByTypeResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/v2/organisations/current/${queryArg["type"]}-resources`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeDynamoDbBackupTypes: build.query<
      GetUsersMeDynamoDbBackupTypesApiResponse,
      GetUsersMeDynamoDbBackupTypesApiArg
    >({
      query: () => ({ url: `/users/me/dynamo_db_backup-types` }),
    }),
    postUsersMeDynamoDbBackupTableNames: build.mutation<
      PostUsersMeDynamoDbBackupTableNamesApiResponse,
      PostUsersMeDynamoDbBackupTableNamesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/dynamo_db_backup-table-names`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeByTypeStorageTiers: build.query<
      GetUsersMeByTypeStorageTiersApiResponse,
      GetUsersMeByTypeStorageTiersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/${queryArg["type"]}-storage-tiers`,
      }),
    }),
    putUsersMeAccountsByAccountIdResources: build.mutation<
      PutUsersMeAccountsByAccountIdResourcesApiResponse,
      PutUsersMeAccountsByAccountIdResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/resources`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    patchUsersMeAccountsByAccountIdResources: build.mutation<
      PatchUsersMeAccountsByAccountIdResourcesApiResponse,
      PatchUsersMeAccountsByAccountIdResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/resources`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getProvidersByProviderRegions: build.query<
      GetProvidersByProviderRegionsApiResponse,
      GetProvidersByProviderRegionsApiArg
    >({
      query: (queryArg) => ({ url: `/providers/${queryArg.provider}/regions` }),
    }),
    getProvidersByProviderFilters: build.query<
      GetProvidersByProviderFiltersApiResponse,
      GetProvidersByProviderFiltersApiArg
    >({
      query: (queryArg) => ({ url: `/providers/${queryArg.provider}/filters` }),
    }),
    getV2Plans: build.query<GetV2PlansApiResponse, GetV2PlansApiArg>({
      query: () => ({ url: `/v2/plans` }),
    }),
    postUsersMeOrganisationsCurrentGcpBillingExportData: build.mutation<
      PostUsersMeOrganisationsCurrentGcpBillingExportDataApiResponse,
      PostUsersMeOrganisationsCurrentGcpBillingExportDataApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/gcp-billing-export-data`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentGcpBillingExportData: build.query<
      GetUsersMeOrganisationsCurrentGcpBillingExportDataApiResponse,
      GetUsersMeOrganisationsCurrentGcpBillingExportDataApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/gcp-billing-export-data`,
      }),
    }),
    getUsersMeSubscriptionsCurrent: build.query<
      GetUsersMeSubscriptionsCurrentApiResponse,
      GetUsersMeSubscriptionsCurrentApiArg
    >({
      query: () => ({ url: `/users/me/subscriptions/current` }),
    }),
    postV2UsersMeSchedules: build.mutation<
      PostV2UsersMeSchedulesApiResponse,
      PostV2UsersMeSchedulesApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/schedules`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getV2UsersMeSchedules: build.query<
      GetV2UsersMeSchedulesApiResponse,
      GetV2UsersMeSchedulesApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/schedules`,
        params: { account_id: queryArg.accountId },
      }),
    }),
    getV2UsersMeSchedulesByScheduleId: build.query<
      GetV2UsersMeSchedulesByScheduleIdApiResponse,
      GetV2UsersMeSchedulesByScheduleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/schedules/${queryArg.scheduleId}`,
      }),
    }),
    patchV2UsersMeSchedulesByScheduleId: build.mutation<
      PatchV2UsersMeSchedulesByScheduleIdApiResponse,
      PatchV2UsersMeSchedulesByScheduleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/schedules/${queryArg.scheduleId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getUsersMeWorkflowsByWorkflowIdBatchesAndBatchId: build.query<
      GetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdApiResponse,
      GetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/workflows/${queryArg.workflowId}/batches/${queryArg.batchId}`,
      }),
    }),
    getUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResources: build.query<
      GetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesApiResponse,
      GetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/workflows/${queryArg.workflowId}/batches/${queryArg.batchId}/resources`,
      }),
    }),
    postUsersMeOrganisationsCurrentBillingPortal: build.mutation<
      PostUsersMeOrganisationsCurrentBillingPortalApiResponse,
      PostUsersMeOrganisationsCurrentBillingPortalApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/billing-portal`,
        method: "POST",
      }),
    }),
    postUsersMeOrganisationsCurrentBillingPortalPlansByPlanId: build.mutation<
      PostUsersMeOrganisationsCurrentBillingPortalPlansByPlanIdApiResponse,
      PostUsersMeOrganisationsCurrentBillingPortalPlansByPlanIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/billing-portal/plans/${queryArg.planId}`,
        method: "POST",
      }),
    }),
    getUsersMeOrganisationsCurrent: build.query<
      GetUsersMeOrganisationsCurrentApiResponse,
      GetUsersMeOrganisationsCurrentApiArg
    >({
      query: () => ({ url: `/users/me/organisations/current` }),
    }),
    patchUsersMeOrganisationsCurrent: build.mutation<
      PatchUsersMeOrganisationsCurrentApiResponse,
      PatchUsersMeOrganisationsCurrentApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    postV2UsersMeOffHoursSchedules: build.mutation<
      PostV2UsersMeOffHoursSchedulesApiResponse,
      PostV2UsersMeOffHoursSchedulesApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/off-hours-schedules`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getV2UsersMeOffHoursSchedules: build.query<
      GetV2UsersMeOffHoursSchedulesApiResponse,
      GetV2UsersMeOffHoursSchedulesApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/off-hours-schedules`,
        params: { account_id: queryArg.accountId },
      }),
    }),
    getV2UsersMeOffHoursSchedulesByScheduleId: build.query<
      GetV2UsersMeOffHoursSchedulesByScheduleIdApiResponse,
      GetV2UsersMeOffHoursSchedulesByScheduleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/off-hours-schedules/${queryArg.scheduleId}`,
      }),
    }),
    patchV2UsersMeOffHoursSchedulesByScheduleId: build.mutation<
      PatchV2UsersMeOffHoursSchedulesByScheduleIdApiResponse,
      PatchV2UsersMeOffHoursSchedulesByScheduleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/off-hours-schedules/${queryArg.scheduleId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getUsersMeFeatures: build.query<
      GetUsersMeFeaturesApiResponse,
      GetUsersMeFeaturesApiArg
    >({
      query: () => ({ url: `/users/me/features` }),
    }),
    postUsersMeFeatures: build.mutation<
      PostUsersMeFeaturesApiResponse,
      PostUsersMeFeaturesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/features`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeProvidersAwsAccountsByAccountIdMissingPermissions: build.query<
      GetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsApiResponse,
      GetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/aws/accounts/${queryArg.accountId}/missing-permissions`,
      }),
    }),
    getUsersMeProvidersGcpAccountsByAccountIdMissingRoles: build.query<
      GetUsersMeProvidersGcpAccountsByAccountIdMissingRolesApiResponse,
      GetUsersMeProvidersGcpAccountsByAccountIdMissingRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/gcp/accounts/${queryArg.accountId}/missing-roles`,
      }),
    }),
    getUsersMeProvidersGcpAccountsByAccountIdMissingRolesOptions: build.query<
      GetUsersMeProvidersGcpAccountsByAccountIdMissingRolesOptionsApiResponse,
      GetUsersMeProvidersGcpAccountsByAccountIdMissingRolesOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/gcp/accounts/${queryArg.accountId}/missing-roles/options`,
      }),
    }),
    getUsersMeProvidersGcpAccountsByAccountIdMissingApis: build.query<
      GetUsersMeProvidersGcpAccountsByAccountIdMissingApisApiResponse,
      GetUsersMeProvidersGcpAccountsByAccountIdMissingApisApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/gcp/accounts/${queryArg.accountId}/missing-apis`,
      }),
    }),
    getUsersMeProvidersAzureAccountsByAccountIdMissingRoles: build.query<
      GetUsersMeProvidersAzureAccountsByAccountIdMissingRolesApiResponse,
      GetUsersMeProvidersAzureAccountsByAccountIdMissingRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/azure/accounts/${queryArg.accountId}/missing-roles`,
      }),
    }),
    getUsersMeProvidersAzureAccountsByAccountIdMissingRolesOptions: build.query<
      GetUsersMeProvidersAzureAccountsByAccountIdMissingRolesOptionsApiResponse,
      GetUsersMeProvidersAzureAccountsByAccountIdMissingRolesOptionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/azure/accounts/${queryArg.accountId}/missing-roles/options`,
      }),
    }),
    patchUsersMeAccountsByAccountIdPermissions: build.mutation<
      PatchUsersMeAccountsByAccountIdPermissionsApiResponse,
      PatchUsersMeAccountsByAccountIdPermissionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/permissions`,
        method: "PATCH",
      }),
    }),
    getUsersMeIntegrations: build.query<
      GetUsersMeIntegrationsApiResponse,
      GetUsersMeIntegrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/integrations`,
        params: { type: queryArg["type"] },
      }),
    }),
    deleteUsersMeIntegrationsByIntegrationId: build.mutation<
      DeleteUsersMeIntegrationsByIntegrationIdApiResponse,
      DeleteUsersMeIntegrationsByIntegrationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/integrations/${queryArg.integrationId}`,
        method: "DELETE",
      }),
    }),
    postUsersMeByTypeIntegrations: build.mutation<
      PostUsersMeByTypeIntegrationsApiResponse,
      PostUsersMeByTypeIntegrationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/${queryArg["type"]}-integrations`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeByTypeIntegrationsAndIntegrationId: build.query<
      GetUsersMeByTypeIntegrationsAndIntegrationIdApiResponse,
      GetUsersMeByTypeIntegrationsAndIntegrationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/${queryArg["type"]}-integrations/${queryArg.integrationId}`,
      }),
    }),
    patchUsersMeByTypeIntegrationsAndIntegrationId: build.mutation<
      PatchUsersMeByTypeIntegrationsAndIntegrationIdApiResponse,
      PatchUsersMeByTypeIntegrationsAndIntegrationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/${queryArg["type"]}-integrations/${queryArg.integrationId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    postUsersMeSlackIntegrationsByIntegrationIdTestMessages: build.mutation<
      PostUsersMeSlackIntegrationsByIntegrationIdTestMessagesApiResponse,
      PostUsersMeSlackIntegrationsByIntegrationIdTestMessagesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/slack-integrations/${queryArg.integrationId}/test-messages`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeProvidersAwsAccountsByAccountIdCfnChangeSets: build.mutation<
      PostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsApiResponse,
      PostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/aws/accounts/${queryArg.accountId}/cfn-change-sets`,
        method: "POST",
      }),
    }),
    postUsersMeProvidersAwsAccountsByAccountIdCfnChangeAccessModeChangeSets:
      build.mutation<
        PostUsersMeProvidersAwsAccountsByAccountIdCfnChangeAccessModeChangeSetsApiResponse,
        PostUsersMeProvidersAwsAccountsByAccountIdCfnChangeAccessModeChangeSetsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/providers/aws/accounts/${queryArg.accountId}/cfn-change-access-mode-change-sets`,
          method: "POST",
        }),
      }),
    getUsersMeOrganisationsCurrentProvidersByProviderTotalSavedAmount:
      build.query<
        GetUsersMeOrganisationsCurrentProvidersByProviderTotalSavedAmountApiResponse,
        GetUsersMeOrganisationsCurrentProvidersByProviderTotalSavedAmountApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/providers/${queryArg.provider}/total-saved-amount`,
        }),
      }),
    getUsersMeOrganisationsCurrentSsoOptionsCurrent: build.query<
      GetUsersMeOrganisationsCurrentSsoOptionsCurrentApiResponse,
      GetUsersMeOrganisationsCurrentSsoOptionsCurrentApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/sso-options/current`,
      }),
    }),
    postUsersMeOrganisationsCurrentSso: build.mutation<
      PostUsersMeOrganisationsCurrentSsoApiResponse,
      PostUsersMeOrganisationsCurrentSsoApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/sso`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeOrganisationsCurrentSsoCurrent: build.mutation<
      DeleteUsersMeOrganisationsCurrentSsoCurrentApiResponse,
      DeleteUsersMeOrganisationsCurrentSsoCurrentApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/sso/current`,
        method: "DELETE",
      }),
    }),
    getUsersMeOrganisationsCurrentSettings: build.query<
      GetUsersMeOrganisationsCurrentSettingsApiResponse,
      GetUsersMeOrganisationsCurrentSettingsApiArg
    >({
      query: () => ({ url: `/users/me/organisations/current/settings` }),
    }),
    getUsersMeOrganisationsCurrentSettingsByKey: build.query<
      GetUsersMeOrganisationsCurrentSettingsByKeyApiResponse,
      GetUsersMeOrganisationsCurrentSettingsByKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/settings/${queryArg.key}`,
      }),
    }),
    patchUsersMeOrganisationsCurrentSettingsByKey: build.mutation<
      PatchUsersMeOrganisationsCurrentSettingsByKeyApiResponse,
      PatchUsersMeOrganisationsCurrentSettingsByKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/settings/${queryArg.key}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    postUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummary:
      build.mutation<
        PostUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummaryApiResponse,
        PostUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummaryApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/savings-plans/recommendations-summary`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
    getUsersMeOrganisationsCurrentSavingsPlansPotentialSavings: build.query<
      GetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsApiResponse,
      GetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/savings-plans/potential-savings`,
        params: { provider_organisation_id: queryArg.providerOrganisationId },
      }),
    }),
    getUsersMeOrganisationsCurrentSavingsPlansUtilizationRecommendations:
      build.query<
        GetUsersMeOrganisationsCurrentSavingsPlansUtilizationRecommendationsApiResponse,
        GetUsersMeOrganisationsCurrentSavingsPlansUtilizationRecommendationsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/savings-plans/utilization-recommendations`,
          params: { savings_plan_id: queryArg.savingsPlanId },
        }),
      }),
    getUsersMeOrganisationsCurrentSavingsPlansRecommendations: build.query<
      GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiResponse,
      GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/savings-plans/recommendations`,
        params: {
          provider_organisation_id: queryArg.providerOrganisationId,
          account_id: queryArg.accountId,
          resource_type: queryArg.resourceType,
          region: queryArg.region,
          instance_family: queryArg.instanceFamily,
        },
      }),
    }),
    getUsersMeOrganisationsCurrentSavingsPlans: build.query<
      GetUsersMeOrganisationsCurrentSavingsPlansApiResponse,
      GetUsersMeOrganisationsCurrentSavingsPlansApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/savings-plans`,
        params: {
          provider_organisation_id: queryArg.providerOrganisationId,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          date_label: queryArg.dateLabel,
          date_type: queryArg.dateType,
          date_value: queryArg.dateValue,
          date_unit: queryArg.dateUnit,
          data_granularity: queryArg.dataGranularity,
          coverage_grouping: queryArg.coverageGrouping,
          load_chart_data: queryArg.loadChartData,
          load_table_data: queryArg.loadTableData,
          load_totals: queryArg.loadTotals,
        },
      }),
    }),
    getUsersMeOrganisationsCurrentSavingsPlansMinimumAvailableDate: build.query<
      GetUsersMeOrganisationsCurrentSavingsPlansMinimumAvailableDateApiResponse,
      GetUsersMeOrganisationsCurrentSavingsPlansMinimumAvailableDateApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/savings-plans/minimum-available-date`,
      }),
    }),
    getUsersMeOrganisationsCurrentSavingsPlansData: build.query<
      GetUsersMeOrganisationsCurrentSavingsPlansDataApiResponse,
      GetUsersMeOrganisationsCurrentSavingsPlansDataApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/savings-plans-data`,
        params: {
          provider_organisation_id: queryArg.providerOrganisationId,
          type: queryArg["type"],
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          date_label: queryArg.dateLabel,
          date_type: queryArg.dateType,
          date_value: queryArg.dateValue,
          date_unit: queryArg.dateUnit,
          coverage_grouping: queryArg.coverageGrouping,
          resource_type: queryArg.resourceType,
          instance_family: queryArg.instanceFamily,
          region: queryArg.region,
          account_id: queryArg.accountId,
          savings_plan_id: queryArg.savingsPlanId,
          data_granularity: queryArg.dataGranularity,
        },
      }),
    }),
    postUsersMeSchedulesNextRunDate: build.mutation<
      PostUsersMeSchedulesNextRunDateApiResponse,
      PostUsersMeSchedulesNextRunDateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/schedules/next-run-date`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeOffHoursSchedulesNextRunDate: build.mutation<
      PostUsersMeOffHoursSchedulesNextRunDateApiResponse,
      PostUsersMeOffHoursSchedulesNextRunDateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/off-hours-schedules/next-run-date`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeAccountsByAccountIdAndTypeResourcesMetadata: build.query<
      GetUsersMeAccountsByAccountIdAndTypeResourcesMetadataApiResponse,
      GetUsersMeAccountsByAccountIdAndTypeResourcesMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/${queryArg["type"]}-resources/metadata`,
      }),
    }),
    getUsersMeAccountsByAccountIdMetadata: build.query<
      GetUsersMeAccountsByAccountIdMetadataApiResponse,
      GetUsersMeAccountsByAccountIdMetadataApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/metadata`,
      }),
    }),
    getUsersMeOrganisationsCurrentApiKeys: build.query<
      GetUsersMeOrganisationsCurrentApiKeysApiResponse,
      GetUsersMeOrganisationsCurrentApiKeysApiArg
    >({
      query: () => ({ url: `/users/me/organisations/current/api-keys` }),
    }),
    postUsersMeOrganisationsCurrentApiKeys: build.mutation<
      PostUsersMeOrganisationsCurrentApiKeysApiResponse,
      PostUsersMeOrganisationsCurrentApiKeysApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/api-keys`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeOrganisationsCurrentApiKeysByKeyId: build.mutation<
      DeleteUsersMeOrganisationsCurrentApiKeysByKeyIdApiResponse,
      DeleteUsersMeOrganisationsCurrentApiKeysByKeyIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/api-keys/${queryArg.keyId}`,
        method: "DELETE",
      }),
    }),
    getUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationId:
      build.query<
        GetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdApiResponse,
        GetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/account/${queryArg.accountId}/daily-off-hours-recommendations/${queryArg.recommendationId}`,
        }),
      }),
    getUsersMeAccountByAccountIdWeeklyOffHoursRecommendationsAndRecommendationId:
      build.query<
        GetUsersMeAccountByAccountIdWeeklyOffHoursRecommendationsAndRecommendationIdApiResponse,
        GetUsersMeAccountByAccountIdWeeklyOffHoursRecommendationsAndRecommendationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/account/${queryArg.accountId}/weekly-off-hours-recommendations/${queryArg.recommendationId}`,
        }),
      }),
    patchUsersMeAccountByAccountIdRecommendationsResourcesAndResourceId:
      build.mutation<
        PatchUsersMeAccountByAccountIdRecommendationsResourcesAndResourceIdApiResponse,
        PatchUsersMeAccountByAccountIdRecommendationsResourcesAndResourceIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/account/${queryArg.accountId}/recommendations/resources/${queryArg.resourceId}`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    getUsersMeCategories: build.query<
      GetUsersMeCategoriesApiResponse,
      GetUsersMeCategoriesApiArg
    >({
      query: () => ({ url: `/users/me/categories` }),
    }),
    postUsersMeCategories: build.mutation<
      PostUsersMeCategoriesApiResponse,
      PostUsersMeCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/categories`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    putUsersMeCategories: build.mutation<
      PutUsersMeCategoriesApiResponse,
      PutUsersMeCategoriesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/categories`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    getUsersMeCategoriesByCategoryId: build.query<
      GetUsersMeCategoriesByCategoryIdApiResponse,
      GetUsersMeCategoriesByCategoryIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/categories/${queryArg.categoryId}`,
      }),
    }),
    putUsersMeCategoriesByCategoryId: build.mutation<
      PutUsersMeCategoriesByCategoryIdApiResponse,
      PutUsersMeCategoriesByCategoryIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/categories/${queryArg.categoryId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeCategoriesByCategoryId: build.mutation<
      DeleteUsersMeCategoriesByCategoryIdApiResponse,
      DeleteUsersMeCategoriesByCategoryIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/categories/${queryArg.categoryId}`,
        method: "DELETE",
      }),
    }),
    getUsersMeOrganisationsCurrentBillingAccounts: build.query<
      GetUsersMeOrganisationsCurrentBillingAccountsApiResponse,
      GetUsersMeOrganisationsCurrentBillingAccountsApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/billing-accounts`,
      }),
    }),
    getUsersMeOrganisationsCurrentDimensions: build.query<
      GetUsersMeOrganisationsCurrentDimensionsApiResponse,
      GetUsersMeOrganisationsCurrentDimensionsApiArg
    >({
      query: () => ({ url: `/users/me/organisations/current/dimensions` }),
    }),
    postUsersMeOrganisationsCurrentDimensions: build.mutation<
      PostUsersMeOrganisationsCurrentDimensionsApiResponse,
      PostUsersMeOrganisationsCurrentDimensionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dimensions`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentDimensionsByDimensionId: build.query<
      GetUsersMeOrganisationsCurrentDimensionsByDimensionIdApiResponse,
      GetUsersMeOrganisationsCurrentDimensionsByDimensionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dimensions/${queryArg.dimensionId}`,
      }),
    }),
    putUsersMeOrganisationsCurrentDimensionsByDimensionId: build.mutation<
      PutUsersMeOrganisationsCurrentDimensionsByDimensionIdApiResponse,
      PutUsersMeOrganisationsCurrentDimensionsByDimensionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dimensions/${queryArg.dimensionId}`,
        method: "PUT",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeOrganisationsCurrentDimensionsByDimensionId: build.mutation<
      DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdApiResponse,
      DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dimensions/${queryArg.dimensionId}`,
        method: "DELETE",
      }),
    }),
    getUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories:
      build.query<
        GetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesApiResponse,
        GetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dimensions/${queryArg.dimensionId}/categories`,
        }),
      }),
    postUsersMeOrganisationsCurrentDimensionsByDimensionIdCategories:
      build.mutation<
        PostUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesApiResponse,
        PostUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dimensions/${queryArg.dimensionId}/categories`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
    getUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId:
      build.query<
        GetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiResponse,
        GetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dimensions/${queryArg.dimensionId}/categories/${queryArg.categoryId}`,
        }),
      }),
    putUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId:
      build.mutation<
        PutUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiResponse,
        PutUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dimensions/${queryArg.dimensionId}/categories/${queryArg.categoryId}`,
          method: "PUT",
          body: queryArg.body,
        }),
      }),
    deleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryId:
      build.mutation<
        DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiResponse,
        DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dimensions/${queryArg.dimensionId}/categories/${queryArg.categoryId}`,
          method: "DELETE",
        }),
      }),
    postUsersMeProvidersGcpBillingAccountAttempts: build.mutation<
      PostUsersMeProvidersGcpBillingAccountAttemptsApiResponse,
      PostUsersMeProvidersGcpBillingAccountAttemptsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/gcp/billing-account-attempts`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeProvidersGcpBillingAccountAttemptsByBillingAccountAttemptId:
      build.query<
        GetUsersMeProvidersGcpBillingAccountAttemptsByBillingAccountAttemptIdApiResponse,
        GetUsersMeProvidersGcpBillingAccountAttemptsByBillingAccountAttemptIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/providers/gcp/billing-account-attempts/${queryArg.billingAccountAttemptId}`,
        }),
      }),
    postUsersMeWebhookIntegrationsTests: build.mutation<
      PostUsersMeWebhookIntegrationsTestsApiResponse,
      PostUsersMeWebhookIntegrationsTestsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/webhook-integrations/tests`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getV2UsersMeOrganisationsCurrentUsers: build.query<
      GetV2UsersMeOrganisationsCurrentUsersApiResponse,
      GetV2UsersMeOrganisationsCurrentUsersApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/organisations/current/users`,
        params: { role: queryArg.role, statuses: queryArg.statuses },
      }),
    }),
    getUsersMeOrganisationsCurrentChart: build.query<
      GetUsersMeOrganisationsCurrentChartApiResponse,
      GetUsersMeOrganisationsCurrentChartApiArg
    >({
      query: () => ({ url: `/users/me/organisations/current/chart` }),
    }),
    postUsersMeOrganisationsCurrentChart: build.mutation<
      PostUsersMeOrganisationsCurrentChartApiResponse,
      PostUsersMeOrganisationsCurrentChartApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/chart`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentChartByChartId: build.query<
      GetUsersMeOrganisationsCurrentChartByChartIdApiResponse,
      GetUsersMeOrganisationsCurrentChartByChartIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/chart/${queryArg.chartId}`,
      }),
    }),
    patchUsersMeOrganisationsCurrentChartByChartId: build.mutation<
      PatchUsersMeOrganisationsCurrentChartByChartIdApiResponse,
      PatchUsersMeOrganisationsCurrentChartByChartIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/chart/${queryArg.chartId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeOrganisationsCurrentChartByChartId: build.mutation<
      DeleteUsersMeOrganisationsCurrentChartByChartIdApiResponse,
      DeleteUsersMeOrganisationsCurrentChartByChartIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/chart/${queryArg.chartId}`,
        method: "DELETE",
      }),
    }),
    postUsersMeAccountsByTypeResources: build.mutation<
      PostUsersMeAccountsByTypeResourcesApiResponse,
      PostUsersMeAccountsByTypeResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg["type"]}-resources`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeProvidersByProviderAccountsResourcesVisibilities: build.query<
      GetUsersMeProvidersByProviderAccountsResourcesVisibilitiesApiResponse,
      GetUsersMeProvidersByProviderAccountsResourcesVisibilitiesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/providers/${queryArg.provider}/accounts/resources/visibilities`,
        params: { account_ids: queryArg.accountIds },
      }),
    }),
    postUsersMeChatQuestions: build.mutation<
      PostUsersMeChatQuestionsApiResponse,
      PostUsersMeChatQuestionsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/chat/questions`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentResourcesRightSizingRecommendations:
      build.query<
        GetUsersMeOrganisationsCurrentResourcesRightSizingRecommendationsApiResponse,
        GetUsersMeOrganisationsCurrentResourcesRightSizingRecommendationsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resources/right-sizing-recommendations`,
          params: { is_dismissed: queryArg.isDismissed },
        }),
      }),
    getUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationId:
      build.query<
        GetUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationIdApiResponse,
        GetUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/accounts/${queryArg.accountId}/right-sizing-recommendations/${queryArg.recommendationId}`,
          params: {
            number_of_metrics_data_points: queryArg.numberOfMetricsDataPoints,
          },
        }),
      }),
    getUsersMeAccountsByAccountIdFilterSetsV2: build.query<
      GetUsersMeAccountsByAccountIdFilterSetsV2ApiResponse,
      GetUsersMeAccountsByAccountIdFilterSetsV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/${queryArg.accountId}/filter-sets/v2`,
      }),
    }),
    getUsersMeOrganisationsCurrentFilterSets: build.query<
      GetUsersMeOrganisationsCurrentFilterSetsApiResponse,
      GetUsersMeOrganisationsCurrentFilterSetsApiArg
    >({
      query: () => ({ url: `/users/me/organisations/current/filter-sets` }),
    }),
    postUsersMeOrganisationsCurrentFilterSetsV2: build.mutation<
      PostUsersMeOrganisationsCurrentFilterSetsV2ApiResponse,
      PostUsersMeOrganisationsCurrentFilterSetsV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/filter-sets/v2`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentCostAllocationTags: build.query<
      GetUsersMeOrganisationsCurrentCostAllocationTagsApiResponse,
      GetUsersMeOrganisationsCurrentCostAllocationTagsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/cost-allocation-tags`,
        params: { tag_key: queryArg.tagKey, account_ids: queryArg.accountIds },
      }),
    }),
    getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValues:
      build.query<
        GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValuesApiResponse,
        GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/providers/${queryArg.provider}/tag-values`,
          params: {
            tag_key: queryArg.tagKey,
            account_ids: queryArg.accountIds,
          },
        }),
      }),
    getUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFilters:
      build.query<
        GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFiltersApiResponse,
        GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFiltersApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/providers/${queryArg.provider}/filters`,
        }),
      }),
    postUsersMeOrganisationsCurrentTrackedResources: build.mutation<
      PostUsersMeOrganisationsCurrentTrackedResourcesApiResponse,
      PostUsersMeOrganisationsCurrentTrackedResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/tracked-resources`,
        method: "POST",
        body: queryArg.body,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    getUsersMeOrganisationsCurrentResourceExplorerViewsHierarchy: build.query<
      GetUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyApiResponse,
      GetUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/resource-explorer/views-hierarchy`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    patchUsersMeOrganisationsCurrentResourceExplorerViewsHierarchy:
      build.mutation<
        PatchUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyApiResponse,
        PatchUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/views-hierarchy`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    getUsersMeOrganisationsCurrentResourceExplorerHierarchy: build.query<
      GetUsersMeOrganisationsCurrentResourceExplorerHierarchyApiResponse,
      GetUsersMeOrganisationsCurrentResourceExplorerHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/resource-explorer/hierarchy`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    patchUsersMeOrganisationsCurrentResourceExplorerHierarchy: build.mutation<
      PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyApiResponse,
      PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/resource-explorer/hierarchy`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getV2UsersMeOrganisationsCurrentResourceExplorerHierarchy: build.query<
      GetV2UsersMeOrganisationsCurrentResourceExplorerHierarchyApiResponse,
      GetV2UsersMeOrganisationsCurrentResourceExplorerHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/organisations/current/resource-explorer/hierarchy`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    patchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleOnlyToMe:
      build.mutation<
        PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleOnlyToMeApiResponse,
        PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleOnlyToMeApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/hierarchy/visible-only-to-me`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    patchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleToEveryone:
      build.mutation<
        PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleToEveryoneApiResponse,
        PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleToEveryoneApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/hierarchy/visible-to-everyone`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    getUsersMeOrganisationsCurrentResourceExplorerViews: build.query<
      GetUsersMeOrganisationsCurrentResourceExplorerViewsApiResponse,
      GetUsersMeOrganisationsCurrentResourceExplorerViewsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/resource-explorer/views`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    postUsersMeOrganisationsCurrentResourceExplorerViews: build.mutation<
      PostUsersMeOrganisationsCurrentResourceExplorerViewsApiResponse,
      PostUsersMeOrganisationsCurrentResourceExplorerViewsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/resource-explorer/views`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postV2UsersMeOrganisationsCurrentResourceExplorerViews: build.mutation<
      PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiResponse,
      PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/organisations/current/resource-explorer/views`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeOrganisationsCurrentResourceExplorerFolders: build.mutation<
      PostUsersMeOrganisationsCurrentResourceExplorerFoldersApiResponse,
      PostUsersMeOrganisationsCurrentResourceExplorerFoldersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/resource-explorer/folders`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postV2UsersMeOrganisationsCurrentResourceExplorerFolders: build.mutation<
      PostV2UsersMeOrganisationsCurrentResourceExplorerFoldersApiResponse,
      PostV2UsersMeOrganisationsCurrentResourceExplorerFoldersApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/organisations/current/resource-explorer/folders`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postV3UsersMeOrganisationsCurrentResourceExplorerFolders: build.mutation<
      PostV3UsersMeOrganisationsCurrentResourceExplorerFoldersApiResponse,
      PostV3UsersMeOrganisationsCurrentResourceExplorerFoldersApiArg
    >({
      query: (queryArg) => ({
        url: `/v3/users/me/organisations/current/resource-explorer/folders`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResources:
      build.query<
        GetUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResourcesApiResponse,
        GetUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResourcesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/accounts/${queryArg.accountId}/providers/${queryArg.provider}/resource-explorer/resources`,
          headers: { "Content-Type": queryArg["Content-Type"] },
          params: { resource_id: queryArg.resourceId },
        }),
      }),
    deleteUsersMeOrganisationsCurrentFilterSetsByFilterSetId: build.mutation<
      DeleteUsersMeOrganisationsCurrentFilterSetsByFilterSetIdApiResponse,
      DeleteUsersMeOrganisationsCurrentFilterSetsByFilterSetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/filter-sets/${queryArg.filterSetId}`,
        method: "DELETE",
      }),
    }),
    postUsersMeOrganisationsCurrentWidgetsResourceExplorer: build.mutation<
      PostUsersMeOrganisationsCurrentWidgetsResourceExplorerApiResponse,
      PostUsersMeOrganisationsCurrentWidgetsResourceExplorerApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/widgets/resource-explorer`,
        method: "POST",
        body: queryArg.resourceExplorerFilterSchema,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    postUsersMeOrganisationsCurrentResourceExplorer: build.mutation<
      PostUsersMeOrganisationsCurrentResourceExplorerApiResponse,
      PostUsersMeOrganisationsCurrentResourceExplorerApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/resource-explorer`,
        method: "POST",
        body: queryArg.resourceExplorerFilterSchema,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    postUsersMeOrganisationsCurrentResourceExplorerGranularCosts:
      build.mutation<
        PostUsersMeOrganisationsCurrentResourceExplorerGranularCostsApiResponse,
        PostUsersMeOrganisationsCurrentResourceExplorerGranularCostsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/granular-costs`,
          method: "POST",
          body: queryArg.resourceExplorerFilterSchema,
          headers: { "Content-Type": queryArg["Content-Type"] },
        }),
      }),
    postUsersMeOrganisationsCurrentWidgetsResourceExplorerLiveUsageTotalCost:
      build.mutation<
        PostUsersMeOrganisationsCurrentWidgetsResourceExplorerLiveUsageTotalCostApiResponse,
        PostUsersMeOrganisationsCurrentWidgetsResourceExplorerLiveUsageTotalCostApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/widgets/resource-explorer/live-usage-total-cost`,
          method: "POST",
          body: queryArg.resourceExplorerFilterSchema,
          headers: { "Content-Type": queryArg["Content-Type"] },
        }),
      }),
    getUsersMeResourceExplorerPossibleFilters: build.query<
      GetUsersMeResourceExplorerPossibleFiltersApiResponse,
      GetUsersMeResourceExplorerPossibleFiltersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/resource-explorer/possible-filters`,
        params: { providers_only: queryArg.providersOnly },
      }),
    }),
    getUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValues:
      build.query<
        GetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesApiResponse,
        GetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/filters/${queryArg.filterProvider}/${queryArg.filterType}/values`,
          params: { key: queryArg.key },
        }),
      }),
    postUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv:
      build.mutation<
        PostUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvApiResponse,
        PostUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/widgets/resource-explorer/download/csv`,
          method: "POST",
          body: queryArg.resourceExplorerFilterSchema,
          headers: { "Content-Type": queryArg["Content-Type"] },
        }),
      }),
    getUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsv:
      build.query<
        GetUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvApiResponse,
        GetUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/widgets/resource-explorer/download/csv`,
          params: { hash: queryArg.hash },
        }),
      }),
    postUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsv:
      build.mutation<
        PostUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsvApiResponse,
        PostUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsvApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/granular-costs/download/csv`,
          method: "POST",
          body: queryArg.resourceExplorerFilterSchema,
          headers: { "Content-Type": queryArg["Content-Type"] },
        }),
      }),
    getUsersMeOrganisationsCurrentResourceCreators: build.query<
      GetUsersMeOrganisationsCurrentResourceCreatorsApiResponse,
      GetUsersMeOrganisationsCurrentResourceCreatorsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/resource-creators`,
        params: { account_ids: queryArg.accountIds, type: queryArg["type"] },
      }),
    }),
    postUsersMeJiraIntegrationsAutoCompleteUsersData: build.mutation<
      PostUsersMeJiraIntegrationsAutoCompleteUsersDataApiResponse,
      PostUsersMeJiraIntegrationsAutoCompleteUsersDataApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/jira-integrations/auto-complete-users-data`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeJiraIntegrationsByIntegrationIdIssues: build.mutation<
      PostUsersMeJiraIntegrationsByIntegrationIdIssuesApiResponse,
      PostUsersMeJiraIntegrationsByIntegrationIdIssuesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/jira-integrations/${queryArg.integrationId}/issues`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeJiraIntegrationsProjects: build.mutation<
      PostUsersMeJiraIntegrationsProjectsApiResponse,
      PostUsersMeJiraIntegrationsProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/jira-integrations/projects`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeJiraIntegrationsProjectsByProjectIdIssueTypes: build.mutation<
      PostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesApiResponse,
      PostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/jira-integrations/projects/${queryArg.projectId}/issue-types`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesAndIssueTypeFields:
      build.mutation<
        PostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesAndIssueTypeFieldsApiResponse,
        PostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesAndIssueTypeFieldsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/jira-integrations/projects/${queryArg.projectId}/issue-types/${queryArg.issueType}/fields`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
    postUsersMeJiraIntegrationsByIntegrationIdProjects: build.mutation<
      PostUsersMeJiraIntegrationsByIntegrationIdProjectsApiResponse,
      PostUsersMeJiraIntegrationsByIntegrationIdProjectsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/jira-integrations/${queryArg.integrationId}/projects`,
        method: "POST",
      }),
    }),
    postUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypes:
      build.mutation<
        PostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesApiResponse,
        PostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/jira-integrations/${queryArg.integrationId}/projects/${queryArg.projectId}/issue-types`,
          method: "POST",
        }),
      }),
    postUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFields:
      build.mutation<
        PostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsApiResponse,
        PostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/jira-integrations/${queryArg.integrationId}/projects/${queryArg.projectId}/issue-types/${queryArg.issueType}/fields`,
          method: "POST",
        }),
      }),
    getUsersMeProvidersAzureResourcesTypesByResourceTypeResourceGroups:
      build.query<
        GetUsersMeProvidersAzureResourcesTypesByResourceTypeResourceGroupsApiResponse,
        GetUsersMeProvidersAzureResourcesTypesByResourceTypeResourceGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/providers/azure/resources-types/${queryArg.resourceType}/resource-groups`,
          params: { account_ids: queryArg.accountIds },
        }),
      }),
    postUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersData:
      build.mutation<
        PostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataApiResponse,
        PostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/jira-integrations/${queryArg.integrationId}/auto-complete-users-data`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
    postUsersMeSlackIntegrationsByIntegrationIdMessage: build.mutation<
      PostUsersMeSlackIntegrationsByIntegrationIdMessageApiResponse,
      PostUsersMeSlackIntegrationsByIntegrationIdMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/slack-integrations/${queryArg.integrationId}/message`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeSlackIntegrationsByIntegrationIdConversations: build.query<
      GetUsersMeSlackIntegrationsByIntegrationIdConversationsApiResponse,
      GetUsersMeSlackIntegrationsByIntegrationIdConversationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/slack-integrations/${queryArg.integrationId}/conversations`,
        params: { search: queryArg.search },
      }),
    }),
    postUsersMeSlackInstallationAttempts: build.mutation<
      PostUsersMeSlackInstallationAttemptsApiResponse,
      PostUsersMeSlackInstallationAttemptsApiArg
    >({
      query: () => ({
        url: `/users/me/slack-installation-attempts`,
        method: "POST",
      }),
    }),
    postUsersMeEmailsMessage: build.mutation<
      PostUsersMeEmailsMessageApiResponse,
      PostUsersMeEmailsMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/emails/message`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postV2UsersMeEmailsMessage: build.mutation<
      PostV2UsersMeEmailsMessageApiResponse,
      PostV2UsersMeEmailsMessageApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/emails/message`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeAccountsResourceTypesByResourceTypeInstanceTypes: build.query<
      GetUsersMeAccountsResourceTypesByResourceTypeInstanceTypesApiResponse,
      GetUsersMeAccountsResourceTypesByResourceTypeInstanceTypesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/resource-types/${queryArg.resourceType}/instance-types`,
      }),
    }),
    getUsersMeAccountsResourceTypesByResourceTypeInstanceFamilies: build.query<
      GetUsersMeAccountsResourceTypesByResourceTypeInstanceFamiliesApiResponse,
      GetUsersMeAccountsResourceTypesByResourceTypeInstanceFamiliesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/accounts/resource-types/${queryArg.resourceType}/instance-families`,
      }),
    }),
    postUsersMeCurrentPreference: build.mutation<
      PostUsersMeCurrentPreferenceApiResponse,
      PostUsersMeCurrentPreferenceApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/current/preference`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeCurrentPreferences: build.query<
      GetUsersMeCurrentPreferencesApiResponse,
      GetUsersMeCurrentPreferencesApiArg
    >({
      query: () => ({ url: `/users/me/current/preferences` }),
    }),
    patchUsersMeCurrentPreferenceByKey: build.mutation<
      PatchUsersMeCurrentPreferenceByKeyApiResponse,
      PatchUsersMeCurrentPreferenceByKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/current/preference/${queryArg.key}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getUsersMeCurrentPreferenceByKey: build.query<
      GetUsersMeCurrentPreferenceByKeyApiResponse,
      GetUsersMeCurrentPreferenceByKeyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/current/preference/${queryArg.key}`,
      }),
    }),
    getUsersMeCurrentDigestPreferences: build.query<
      GetUsersMeCurrentDigestPreferencesApiResponse,
      GetUsersMeCurrentDigestPreferencesApiArg
    >({
      query: () => ({ url: `/users/me/current/digest-preferences` }),
    }),
    patchUsersMeCurrentDigestPreferences: build.mutation<
      PatchUsersMeCurrentDigestPreferencesApiResponse,
      PatchUsersMeCurrentDigestPreferencesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/current/digest-preferences`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeOrganisationsCurrentBudgetsByBudgetId: build.mutation<
      DeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdApiResponse,
      DeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/budgets/${queryArg["budget-id"]}`,
        method: "DELETE",
      }),
    }),
    patchUsersMeOrganisationsCurrentBudgetsByBudgetId: build.mutation<
      PatchUsersMeOrganisationsCurrentBudgetsByBudgetIdApiResponse,
      PatchUsersMeOrganisationsCurrentBudgetsByBudgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/budgets/${queryArg["budget-id"]}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentBudgetsByBudgetId: build.query<
      GetUsersMeOrganisationsCurrentBudgetsByBudgetIdApiResponse,
      GetUsersMeOrganisationsCurrentBudgetsByBudgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/budgets/${queryArg["budget-id"]}`,
      }),
    }),
    getUsersMeOrganisationsCurrentBudgets: build.query<
      GetUsersMeOrganisationsCurrentBudgetsApiResponse,
      GetUsersMeOrganisationsCurrentBudgetsApiArg
    >({
      query: () => ({ url: `/users/me/organisations/current/budgets` }),
    }),
    getUsersMeOrganisationsCurrentViewsByViewIdBudgets: build.query<
      GetUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiResponse,
      GetUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/views/${queryArg["view-id"]}/budgets`,
      }),
    }),
    postUsersMeOrganisationsCurrentViewsByViewIdBudgets: build.mutation<
      PostUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiResponse,
      PostUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/views/${queryArg["view-id"]}/budgets`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentProviderOrganisations: build.query<
      GetUsersMeOrganisationsCurrentProviderOrganisationsApiResponse,
      GetUsersMeOrganisationsCurrentProviderOrganisationsApiArg
    >({
      query: () => ({
        url: `/users/me/organisations/current/provider-organisations`,
      }),
    }),
    getUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId:
      build.query<
        GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse,
        GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/views/${queryArg.resourceExplorerViewId}`,
        }),
      }),
    postUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId:
      build.mutation<
        PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse,
        PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/views/${queryArg.resourceExplorerViewId}`,
          method: "POST",
          body: queryArg.resourceExplorerDataOverrideFilters,
        }),
      }),
    patchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId:
      build.mutation<
        PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse,
        PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/views/${queryArg.resourceExplorerViewId}`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    deleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId:
      build.mutation<
        DeleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse,
        DeleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/views/${queryArg.resourceExplorerViewId}`,
          method: "DELETE",
        }),
      }),
    deleteV2UsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId:
      build.mutation<
        DeleteV2UsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse,
        DeleteV2UsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/users/me/organisations/current/resource-explorer/views/${queryArg.resourceExplorerViewId}`,
          method: "DELETE",
        }),
      }),
    patchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId:
      build.mutation<
        PatchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiResponse,
        PatchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/folders/${queryArg.resourceExplorerFolderId}`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    deleteUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId:
      build.mutation<
        DeleteUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiResponse,
        DeleteUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/folders/${queryArg.resourceExplorerFolderId}`,
          method: "DELETE",
        }),
      }),
    patchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId:
      build.mutation<
        PatchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiResponse,
        PatchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/users/me/organisations/current/resource-explorer/folders/${queryArg.resourceExplorerFolderId}`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    deleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderId:
      build.mutation<
        DeleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiResponse,
        DeleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/users/me/organisations/current/resource-explorer/folders/${queryArg.resourceExplorerFolderId}`,
          method: "DELETE",
        }),
      }),
    patchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2: build.mutation<
      PatchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2ApiResponse,
      PatchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2ApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/filter-sets/${queryArg.filterSetId}/v2`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentDashboardsPossibleWidgets: build.query<
      GetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsApiResponse,
      GetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboards/possible-widgets`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    postUsersMeOrganisationsCurrentDashboards: build.mutation<
      PostUsersMeOrganisationsCurrentDashboardsApiResponse,
      PostUsersMeOrganisationsCurrentDashboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboards`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentDashboards: build.query<
      GetUsersMeOrganisationsCurrentDashboardsApiResponse,
      GetUsersMeOrganisationsCurrentDashboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboards`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    postV2UsersMeOrganisationsCurrentDashboards: build.mutation<
      PostV2UsersMeOrganisationsCurrentDashboardsApiResponse,
      PostV2UsersMeOrganisationsCurrentDashboardsApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/organisations/current/dashboards`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postUsersMeOrganisationsCurrentDashboardFolders: build.mutation<
      PostUsersMeOrganisationsCurrentDashboardFoldersApiResponse,
      PostUsersMeOrganisationsCurrentDashboardFoldersApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboard/folders`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    postV2UsersMeOrganisationsCurrentDashboardFolders: build.mutation<
      PostV2UsersMeOrganisationsCurrentDashboardFoldersApiResponse,
      PostV2UsersMeOrganisationsCurrentDashboardFoldersApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/organisations/current/dashboard/folders`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    patchUsersMeOrganisationsCurrentDashboardFoldersByFolderId: build.mutation<
      PatchUsersMeOrganisationsCurrentDashboardFoldersByFolderIdApiResponse,
      PatchUsersMeOrganisationsCurrentDashboardFoldersByFolderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboard/folders/${queryArg.folderId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeOrganisationsCurrentDashboardFoldersByFolderId: build.mutation<
      DeleteUsersMeOrganisationsCurrentDashboardFoldersByFolderIdApiResponse,
      DeleteUsersMeOrganisationsCurrentDashboardFoldersByFolderIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboard/folders/${queryArg.folderId}`,
        method: "DELETE",
      }),
    }),
    deleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderId:
      build.mutation<
        DeleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderIdApiResponse,
        DeleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v2/users/me/organisations/current/dashboards/folders/${queryArg.folderId}`,
          method: "DELETE",
        }),
      }),
    patchUsersMeOrganisationsCurrentDashboardsByDashboardId: build.mutation<
      PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
      PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboards/${queryArg.dashboardId}`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    deleteUsersMeOrganisationsCurrentDashboardsByDashboardId: build.mutation<
      DeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
      DeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboards/${queryArg.dashboardId}`,
        method: "DELETE",
      }),
    }),
    getUsersMeOrganisationsCurrentDashboardsByDashboardId: build.query<
      GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
      GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboards/${queryArg.dashboardId}`,
      }),
    }),
    deleteV2UsersMeOrganisationsCurrentDashboardsByDashboardId: build.mutation<
      DeleteV2UsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse,
      DeleteV2UsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/organisations/current/dashboards/${queryArg.dashboardId}`,
        method: "DELETE",
      }),
    }),
    postUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgets:
      build.mutation<
        PostUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsApiResponse,
        PostUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dashboards/${queryArg.dashboardId}/widgets`,
          method: "POST",
          body: queryArg.body,
        }),
      }),
    patchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId:
      build.mutation<
        PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiResponse,
        PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dashboards/${queryArg.dashboardId}/widgets/${queryArg.widgetId}`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    deleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId:
      build.mutation<
        DeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiResponse,
        DeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dashboards/${queryArg.dashboardId}/widgets/${queryArg.widgetId}`,
          method: "DELETE",
        }),
      }),
    putUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetId:
      build.mutation<
        PutUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiResponse,
        PutUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dashboards/${queryArg.dashboardId}/widgets/${queryArg.widgetId}`,
          method: "PUT",
          body: queryArg.body,
        }),
      }),
    getUsersMeOrganisationsCurrentDashboardHierarchy: build.query<
      GetUsersMeOrganisationsCurrentDashboardHierarchyApiResponse,
      GetUsersMeOrganisationsCurrentDashboardHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboard/hierarchy`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    patchUsersMeOrganisationsCurrentDashboardHierarchy: build.mutation<
      PatchUsersMeOrganisationsCurrentDashboardHierarchyApiResponse,
      PatchUsersMeOrganisationsCurrentDashboardHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dashboard/hierarchy`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getV2UsersMeOrganisationsCurrentDashboardsHierarchy: build.query<
      GetV2UsersMeOrganisationsCurrentDashboardsHierarchyApiResponse,
      GetV2UsersMeOrganisationsCurrentDashboardsHierarchyApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/users/me/organisations/current/dashboards/hierarchy`,
        headers: { "Content-Type": queryArg["Content-Type"] },
      }),
    }),
    patchUsersMeOrganisationsCurrentDashboardHierarchyVisibleOnlyToMe:
      build.mutation<
        PatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleOnlyToMeApiResponse,
        PatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleOnlyToMeApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dashboard/hierarchy/visible-only-to-me`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    patchUsersMeOrganisationsCurrentDashboardHierarchyVisibleToEveryone:
      build.mutation<
        PatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleToEveryoneApiResponse,
        PatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleToEveryoneApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/dashboard/hierarchy/visible-to-everyone`,
          method: "PATCH",
          body: queryArg.body,
        }),
      }),
    getUsersMeOrganisationsCurrentCategoriesWidget: build.query<
      GetUsersMeOrganisationsCurrentCategoriesWidgetApiResponse,
      GetUsersMeOrganisationsCurrentCategoriesWidgetApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/categories/widget`,
        params: { name: queryArg.name, date_label: queryArg.dateLabel },
      }),
    }),
    postUsersMeOrganisationsCurrentWidgetsCostByCategory: build.mutation<
      PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse,
      PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/widgets/cost-by-category`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentWidgetsCostBreakdown: build.query<
      GetUsersMeOrganisationsCurrentWidgetsCostBreakdownApiResponse,
      GetUsersMeOrganisationsCurrentWidgetsCostBreakdownApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/widgets/cost-breakdown`,
        params: {
          view_id: queryArg.viewId,
          date_label: queryArg.dateLabel,
          date_from: queryArg.dateFrom,
          date_to: queryArg.dateTo,
          date_type: queryArg.dateType,
          date_value: queryArg.dateValue,
          date_unit: queryArg.dateUnit,
          data_point: queryArg.dataPoint,
          grouping: queryArg.grouping,
          group_values: queryArg.groupValues,
          forecast_option: queryArg.forecastOption,
          chart_type: queryArg.chartType,
          trend_type: queryArg.trendType,
        },
      }),
    }),
    getUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetId: build.query<
      GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse,
      GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/widgets/cost-breakdown/${queryArg.widgetId}`,
      }),
    }),
    getUsersMeRdsStorageTypes: build.query<
      GetUsersMeRdsStorageTypesApiResponse,
      GetUsersMeRdsStorageTypesApiArg
    >({
      query: () => ({ url: `/users/me/rds-storage-types` }),
    }),
    postUsersMeRdsSnapshotSourceNames: build.mutation<
      PostUsersMeRdsSnapshotSourceNamesApiResponse,
      PostUsersMeRdsSnapshotSourceNamesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/rds-snapshot-source-names`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeRdsSnapshotTypes: build.query<
      GetUsersMeRdsSnapshotTypesApiResponse,
      GetUsersMeRdsSnapshotTypesApiArg
    >({
      query: () => ({ url: `/users/me/rds-snapshot-types` }),
    }),
    getUsersMeEbsSnapshotStatuses: build.query<
      GetUsersMeEbsSnapshotStatusesApiResponse,
      GetUsersMeEbsSnapshotStatusesApiArg
    >({
      query: () => ({ url: `/users/me/ebs-snapshot-statuses` }),
    }),
    postUsersMeEbsSnapshotVolumeIds: build.mutation<
      PostUsersMeEbsSnapshotVolumeIdsApiResponse,
      PostUsersMeEbsSnapshotVolumeIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/ebs-snapshot-volume-ids`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    getUsersMeDynamoDbTableStatuses: build.query<
      GetUsersMeDynamoDbTableStatusesApiResponse,
      GetUsersMeDynamoDbTableStatusesApiArg
    >({
      query: () => ({ url: `/users/me/dynamo_db_table-statuses` }),
    }),
    getUsersMeDynamoDbTableCapacityModes: build.query<
      GetUsersMeDynamoDbTableCapacityModesApiResponse,
      GetUsersMeDynamoDbTableCapacityModesApiArg
    >({
      query: () => ({ url: `/users/me/dynamo_db_table-capacity-modes` }),
    }),
    getUsersMeNatGatewayIpAddresses: build.query<
      GetUsersMeNatGatewayIpAddressesApiResponse,
      GetUsersMeNatGatewayIpAddressesApiArg
    >({
      query: () => ({ url: `/users/me/nat_gateway-ip-addresses` }),
    }),
    getUsersMeNatGatewayVpc: build.query<
      GetUsersMeNatGatewayVpcApiResponse,
      GetUsersMeNatGatewayVpcApiArg
    >({
      query: () => ({ url: `/users/me/nat_gateway-vpc` }),
    }),
    getUsersMeEcsWorkloadCapacityProviders: build.query<
      GetUsersMeEcsWorkloadCapacityProvidersApiResponse,
      GetUsersMeEcsWorkloadCapacityProvidersApiArg
    >({
      query: () => ({ url: `/users/me/ecs_workload-capacity-providers` }),
    }),
    getUsersMeOrganisationsCurrentResourceExplorerViewsByViewIdWidget:
      build.query<
        GetUsersMeOrganisationsCurrentResourceExplorerViewsByViewIdWidgetApiResponse,
        GetUsersMeOrganisationsCurrentResourceExplorerViewsByViewIdWidgetApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/resource-explorer/views/${queryArg.viewId}/widget`,
        }),
      }),
    getUsersMeExecutionLogsSources: build.query<
      GetUsersMeExecutionLogsSourcesApiResponse,
      GetUsersMeExecutionLogsSourcesApiArg
    >({
      query: () => ({ url: `/users/me/execution-logs/sources` }),
    }),
    postUsersMeOrganisationsCurrentMinimumAvailableDate: build.mutation<
      PostUsersMeOrganisationsCurrentMinimumAvailableDateApiResponse,
      PostUsersMeOrganisationsCurrentMinimumAvailableDateApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/minimum-available-date`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    patchUsersMeRightSizingRecommendations: build.mutation<
      PatchUsersMeRightSizingRecommendationsApiResponse,
      PatchUsersMeRightSizingRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/right-sizing-recommendations`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    patchUsersMeDailyOffHoursRecommendations: build.mutation<
      PatchUsersMeDailyOffHoursRecommendationsApiResponse,
      PatchUsersMeDailyOffHoursRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/daily-off-hours-recommendations`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    patchUsersMeWeeklyOffHoursRecommendations: build.mutation<
      PatchUsersMeWeeklyOffHoursRecommendationsApiResponse,
      PatchUsersMeWeeklyOffHoursRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/weekly-off-hours-recommendations`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    patchUsersMeResourcesRightSizingRecommendations: build.mutation<
      PatchUsersMeResourcesRightSizingRecommendationsApiResponse,
      PatchUsersMeResourcesRightSizingRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/resources/right-sizing-recommendations`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    patchUsersMeResourcesDailyOffHoursRecommendations: build.mutation<
      PatchUsersMeResourcesDailyOffHoursRecommendationsApiResponse,
      PatchUsersMeResourcesDailyOffHoursRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/resources/daily-off-hours-recommendations`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    patchUsersMeResourcesWeeklyOffHoursRecommendations: build.mutation<
      PatchUsersMeResourcesWeeklyOffHoursRecommendationsApiResponse,
      PatchUsersMeResourcesWeeklyOffHoursRecommendationsApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/resources/weekly-off-hours-recommendations`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
    getUsersMeOrganisationsCurrentDismissedResources: build.query<
      GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse,
      GetUsersMeOrganisationsCurrentDismissedResourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/dismissed-resources`,
        params: {
          group: queryArg.group,
          recommendation_type: queryArg.recommendationType,
        },
      }),
    }),
    patchUsersMeOrganisationsCurrentRecommendationsDailyOffHours:
      build.mutation<
        PatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursApiResponse,
        PatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/recommendations/daily-off-hours`,
          method: "PATCH",
          body: queryArg.restoreRecommendationRequest,
        }),
      }),
    patchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHours:
      build.mutation<
        PatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursApiResponse,
        PatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/recommendations/weekly-off-hours`,
          method: "PATCH",
          body: queryArg.restoreRecommendationRequest,
        }),
      }),
    patchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursByResource:
      build.mutation<
        PatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursByResourceApiResponse,
        PatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursByResourceApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/recommendations/daily-off-hours-by-resource`,
          method: "PATCH",
          body: queryArg.restoreRecommendationByResourceRequest,
        }),
      }),
    patchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursByResource:
      build.mutation<
        PatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursByResourceApiResponse,
        PatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursByResourceApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/recommendations/weekly-off-hours-by-resource`,
          method: "PATCH",
          body: queryArg.restoreRecommendationByResourceRequest,
        }),
      }),
    patchUsersMeOrganisationsCurrentRecommendationsRightSizing: build.mutation<
      PatchUsersMeOrganisationsCurrentRecommendationsRightSizingApiResponse,
      PatchUsersMeOrganisationsCurrentRecommendationsRightSizingApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/recommendations/right-sizing`,
        method: "PATCH",
        body: queryArg.restoreRecommendationRequest,
      }),
    }),
    patchUsersMeOrganisationsCurrentRecommendationsRightSizingByResource:
      build.mutation<
        PatchUsersMeOrganisationsCurrentRecommendationsRightSizingByResourceApiResponse,
        PatchUsersMeOrganisationsCurrentRecommendationsRightSizingByResourceApiArg
      >({
        query: (queryArg) => ({
          url: `/users/me/organisations/current/recommendations/right-sizing-by-resource`,
          method: "PATCH",
          body: queryArg.restoreRecommendationByResourceRequest,
        }),
      }),
    getUsersMeOrganisationsCurrentProvidersByProviderTagKeys: build.query<
      GetUsersMeOrganisationsCurrentProvidersByProviderTagKeysApiResponse,
      GetUsersMeOrganisationsCurrentProvidersByProviderTagKeysApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/providers/${queryArg.provider}/tag-keys`,
      }),
    }),
    patchUsersMeOrganisationsCurrentProvidersByProviderTagKeys: build.mutation<
      PatchUsersMeOrganisationsCurrentProvidersByProviderTagKeysApiResponse,
      PatchUsersMeOrganisationsCurrentProvidersByProviderTagKeysApiArg
    >({
      query: (queryArg) => ({
        url: `/users/me/organisations/current/providers/${queryArg.provider}/tag-keys`,
        method: "PATCH",
        body: queryArg.body,
      }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as cloudChiprApi };
export type GetUsersMeApiResponse =
  /** status 200 A JSON object of logged in user */ User;
export type GetUsersMeApiArg = {
  /** Content-type */
  "Content-Type"?: string;
};
export type PatchUsersMeApiResponse =
  /** status 200 A JSON object of logged in user */ User;
export type PatchUsersMeApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  body: {
    /** The customer name */
    name?: string | null;
    email?: string | null;
    company_name?: string | null;
    position?: string | null;
    use_mfa?: boolean | null;
  };
};
export type PutUsersMeApiResponse = /** status 200 OK */ User;
export type PutUsersMeApiArg = {
  body: {
    auth_key?: string;
    email?: string;
    name?: string | null;
  };
};
export type DeleteUsersMeApiResponse = /** status 204 No Content */ void;
export type DeleteUsersMeApiArg = void;
export type PostUsersMeOrganisationsCurrentAccountsByAccountIdRecommendationsStatusApiResponse =
  /** status 200 RecommendationState */ AccountRecommendationState;
export type PostUsersMeOrganisationsCurrentAccountsByAccountIdRecommendationsStatusApiArg =
  {
    accountId: string;
  };
export type GetUsersMeOrganisationsCurrentRecommendationsStatusApiResponse =
  /** status 200 Recommendations state */ AccountRecommendationState[];
export type GetUsersMeOrganisationsCurrentRecommendationsStatusApiArg = void;
export type PostUsersMeOrganisationsCurrentUsersApiResponse =
  /** status 201 Created */ User;
export type PostUsersMeOrganisationsCurrentUsersApiArg = {
  body: {
    email: string;
    role: UserRole;
  };
};
export type GetUsersMeOrganisationsCurrentUsersApiResponse =
  /** status 200 Example response */ {
    total_items?: number;
    items?: User[];
    total_pages?: number;
    current_page?: number;
  };
export type GetUsersMeOrganisationsCurrentUsersApiArg = {
  page?: number;
  size?: number;
  /** User role */
  role?: "owner" | "admin";
};
export type PostUsersMeInvitationRequestsApiResponse =
  /** status 201 Created */ User;
export type PostUsersMeInvitationRequestsApiArg = void;
export type GetUsersMeOrganisationsCurrentInvitationRequestsApiResponse =
  /** status 200 ok */ User[];
export type GetUsersMeOrganisationsCurrentInvitationRequestsApiArg = void;
export type DeleteUsersMeOrganisationsCurrentInvitationRequestsByUserIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentInvitationRequestsByUserIdApiArg =
  {
    /** User Id */
    userId: string;
  };
export type PostUsersMeOrganisationsCurrentUsersByUserIdReInvitationsApiResponse =
  /** status 204 No Content */ void;
export type PostUsersMeOrganisationsCurrentUsersByUserIdReInvitationsApiArg = {
  userId: string;
};
export type GetUsersMeOrganisationsCurrentUsersByUserIdApiResponse =
  /** status 200 ok */ User;
export type GetUsersMeOrganisationsCurrentUsersByUserIdApiArg = {
  userId: string;
};
export type PatchUsersMeOrganisationsCurrentUsersByUserIdApiResponse =
  /** status 200 ok */ User;
export type PatchUsersMeOrganisationsCurrentUsersByUserIdApiArg = {
  userId: string;
  body: {
    role?: UserRole;
  };
};
export type DeleteUsersMeOrganisationsCurrentUsersByUserIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentUsersByUserIdApiArg = {
  userId: string;
};
export type PostUsersMeAccountAttemptsApiResponse =
  /** status 201 A JSON object of account attempt */ AccountAttempt;
export type PostUsersMeAccountAttemptsApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  body: {
    type?: ProviderType;
    access_type?: ProviderAccessType;
  };
};
export type GetUsersMeAccountAttemptsByAccountAttemptIdApiResponse =
  /** status 200 A JSON object of Account attempt */ AccountAttempt;
export type GetUsersMeAccountAttemptsByAccountAttemptIdApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  /** Account Attempt Id */
  accountAttemptId: string;
};
export type PatchProvidersAwsAccountAttemptsByAccountAttemptIdApiResponse =
  /** status 200 A JSON object of account */ Account;
export type PatchProvidersAwsAccountAttemptsByAccountAttemptIdApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  /** Account Attempt Id */
  accountAttemptId: string;
  body: {
    /** This is confirmation token that must be checked againt */
    provider_confirmation_token: string;
    /** Cloud provider account id */
    provider_account_id: string;
    /** Cloud provider organisation id */
    provider_organisation_id?: string | null;
    /** Cloud provider account id */
    provider_root_account_id: string;
    provider_account_name?: string;
    provider_region?: string | null;
  };
};
export type PostProvidersAwsCfnTemplatesApiResponse =
  /** status 200 A JSON object of cfn template */ object;
export type PostProvidersAwsCfnTemplatesApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  /** Account provider access type */
  accessType?: ProviderAccessType;
};
export type PatchUsersMeProvidersAzureAccountAttemptsByAccountAttemptIdApiResponse =
  /** status 200 OK */ AccountAttempt;
export type PatchUsersMeProvidersAzureAccountAttemptsByAccountAttemptIdApiArg =
  {
    /** Content-type */
    "Content-Type"?: string;
    /** Account Attempt Id */
    accountAttemptId: string;
    body: {
      tenant_id: string;
    };
  };
export type GetUsersMeAccountsApiResponse =
  /** status 200 A JSON object of user providers */ Accounts;
export type GetUsersMeAccountsApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  /** Account status */
  statuses?: string;
  /** Account provider type */
  type?: ProviderType;
  /** Account provider access type */
  accessType?: ProviderAccessType;
};
export type GetUsersMeAccountsByAccountIdApiResponse =
  /** status 200 A JSON object of logged in user */ Account;
export type GetUsersMeAccountsByAccountIdApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  /** Account Id */
  accountId: string;
};
export type PatchUsersMeAccountsByAccountIdApiResponse =
  /** status 200 A JSON object of account */ Account;
export type PatchUsersMeAccountsByAccountIdApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  /** Account Id */
  accountId: string;
};
export type DeleteUsersMeAccountsByAccountIdApiResponse =
  /** status 200 OK */ Account;
export type DeleteUsersMeAccountsByAccountIdApiArg = {
  /** Account Id */
  accountId: string;
};
export type PatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeApiResponse =
  /** status 200 Account response */ Account;
export type PatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeApiArg =
  {
    /** Content-type */
    "Content-Type"?: string;
    /** Cloud Provider */
    provider: "gcp" | "aws" | "azure";
    /** Account Id */
    accountId: string;
    body: {
      access_type: ProviderAccessType;
    };
  };
export type PostUsersMeAccountsByAccountIdFiltersApiResponse =
  /** status 201 Example response */ {
    id: string;
    account_id: string;
    filters: ResourceFilter[];
    created_at: Date;
    filter_set_id: string | null;
  };
export type PostUsersMeAccountsByAccountIdFiltersApiArg = {
  /** Account Id */
  accountId: string;
  body: ResourceFilter[];
};
export type PostUsersMeAccountsByAccountIdFiltersV2ApiResponse =
  /** status 201 Account filter response */ {
    account_id: string;
    filters: ResourceFilters[];
    filter_set_id: string | null;
  };
export type PostUsersMeAccountsByAccountIdFiltersV2ApiArg = {
  /** Account Id */
  accountId: string;
  body: {
    filters?: ResourceFilter[];
    filter_set_id?: string;
  };
};
export type GetUsersMeAccountsByAccountIdFiltersCurrentApiResponse =
  /** status 200 Example response */ {
    id: string;
    account_id: string;
    filters: ResourceFilter[];
    created_at: Date;
    filter_set_id: string | null;
  };
export type GetUsersMeAccountsByAccountIdFiltersCurrentApiArg = {
  /** Account Id */
  accountId: string;
};
export type GetUsersMeAccountsByAccountIdFiltersCurrentV2ApiResponse =
  /** status 200 Account filter response */ {
    account_id: string;
    filters: ResourceFilters[];
    filter_set_id: string | null;
  };
export type GetUsersMeAccountsByAccountIdFiltersCurrentV2ApiArg = {
  /** Account Id */
  accountId: string;
};
export type GetUsersMeAccountsByAccountIdCostsApiResponse =
  /** status 200 OK */ AccountCost[];
export type GetUsersMeAccountsByAccountIdCostsApiArg = {
  /** Account Id */
  accountId: string;
  dataPoint?: "hourly" | "daily" | "weekly" | "monthly";
  dateFrom?: string;
  dateTo?: string;
};
export type GetUsersMeOrganisationsCurrentProvidersByProviderBillingStatusApiResponse =
  /** status 200 OK */ ProviderBillingStatus;
export type GetUsersMeOrganisationsCurrentProvidersByProviderBillingStatusApiArg =
  {
    /** Cloud Provider */
    provider: "gcp" | "aws" | "azure";
  };
export type GetV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatusApiResponse =
  /** status 200 OK */ OrganisationBillingStatus;
export type GetV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatusApiArg =
  {
    /** Cloud Provider */
    provider: "gcp" | "aws" | "azure";
  };
export type GetUsersMeOrganisationsCurrentProvidersByProviderBillingCurrentApiResponse =
  /** status 200 OK */ OrganisationBillingCurrent[];
export type GetUsersMeOrganisationsCurrentProvidersByProviderBillingCurrentApiArg =
  {
    /** Cloud Provider */
    provider: "gcp" | "aws" | "azure";
  };
export type GetUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendationsApiResponse =
  /** status 200 OK */ OrganisationDailyOffHoursRecommendation[];
export type GetUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendationsApiArg =
  {
    /** Resource group */
    group?: "compute" | "database";
    /** Flag for getting dismissed recommendations */
    isDismissed?: boolean;
  };
export type GetUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendationsApiResponse =
  /** status 200 OK */ OrganisationWeeklyOffHoursRecommendation[];
export type GetUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendationsApiArg =
  {
    /** Resource group */
    group?: "compute" | "database";
    /** Flag for getting dismissed recommendations */
    isDismissed?: boolean;
  };
export type PostV3UsersMeExecutionLogsApiResponse =
  /** status 200 Example response V3 */ {
    total_items?: number;
    items?: ExecutionLogV3[];
    total_pages?: number;
    current_page?: number;
  };
export type PostV3UsersMeExecutionLogsApiArg = {
  body: {
    page?: number | null;
    size?: number | null;
    account_ids: string[] | null;
    date_from?: NullableDate;
    date_to?: NullableDate;
    search?: string | null;
    state?: ("succeed" | "failed" | "skipped") | null;
    source?: ("manual" | "workflow" | "off_hours")[] | null;
    action?: ("terminate" | "stop" | "start" | "snapshot_and_delete")[] | null;
    executed_by?: string[] | null;
    execution_log_ids?: string[] | null;
  };
};
export type PostUsersMeExecutionLogsDetailsApiResponse = /** status 200 OK */ {
  total_price: number;
};
export type PostUsersMeExecutionLogsDetailsApiArg = {
  body: {
    page?: number | null;
    size?: number | null;
    account_ids: string[] | null;
    date_from?: NullableDate;
    date_to?: NullableDate;
    search?: string | null;
    state?: ("succeed" | "failed" | "skipped") | null;
    source?: ("manual" | "workflow" | "off_hours")[] | null;
    action?: ("terminate" | "stop" | "start" | "snapshot_and_delete")[] | null;
    executed_by?: string[] | null;
    execution_log_ids?: string[] | null;
  };
};
export type GetV3UsersMeExecutionLogsByExecutionIdApiResponse =
  /** status 200 OK */ ExecutionLogV3;
export type GetV3UsersMeExecutionLogsByExecutionIdApiArg = {
  executionId: string;
};
export type PostUsersMeOrganisationsCurrentByTypeResourcesTagKeysApiResponse =
  /** status 200 Ok */ string[];
export type PostUsersMeOrganisationsCurrentByTypeResourcesTagKeysApiArg = {
  /** Resource Type */
  type:
    | "ec2"
    | "ebs"
    | "elb"
    | "eip"
    | "rds"
    | "elc"
    | "s3"
    | "vm"
    | "disk"
    | "lb"
    | "ip"
    | "sql"
    | "az_disk"
    | "az_ip"
    | "az_lb"
    | "az_vm"
    | "az_sql"
    | "asg"
    | "mig"
    | "mig_vm"
    | "asg_ec2"
    | "eks"
    | "eks_ec2"
    | "node_group"
    | "az_vmss"
    | "az_vmss_vm"
    | "gke"
    | "node_pool"
    | "gke_vm"
    | "az_aks"
    | "az_node_pool"
    | "az_aks_vm"
    | "rds_snapshot"
    | "ebs_snapshot"
    | "rds_snapshot_source"
    | "dynamo_db_table"
    | "dynamo_db_backup"
    | "dax_cluster"
    | "nat_gateway"
    | "ecs"
    | "ecs_workload";
  body: {
    account_ids: string[];
  };
};
export type PostUsersMeOrganisationsCurrentByTypeResourcesTagValuesApiResponse =
  /** status 200 Ok */ string[];
export type PostUsersMeOrganisationsCurrentByTypeResourcesTagValuesApiArg = {
  /** Resource Type */
  type:
    | "ec2"
    | "ebs"
    | "elb"
    | "eip"
    | "rds"
    | "elc"
    | "s3"
    | "vm"
    | "disk"
    | "lb"
    | "ip"
    | "sql"
    | "az_disk"
    | "az_ip"
    | "az_lb"
    | "az_vm"
    | "az_sql"
    | "asg"
    | "mig"
    | "mig_vm"
    | "asg_ec2"
    | "eks"
    | "eks_ec2"
    | "node_group"
    | "az_vmss"
    | "az_vmss_vm"
    | "gke"
    | "node_pool"
    | "gke_vm"
    | "az_aks"
    | "az_node_pool"
    | "az_aks_vm"
    | "rds_snapshot"
    | "ebs_snapshot"
    | "rds_snapshot_source"
    | "dynamo_db_table"
    | "dynamo_db_backup"
    | "dax_cluster"
    | "nat_gateway"
    | "ecs"
    | "ecs_workload";
  body: {
    account_ids: string[];
    key: string;
  };
};
export type PostUsersMeV2OrganisationsCurrentByTypeResourcesApiResponse =
  /** status 200 Ok */
  | FilteredAsgResources
  | FilteredEbsResources
  | FilteredEbsSnapshotResourcesV2
  | FilteredEc2Resources
  | FilteredEipResources
  | FilteredEksResources
  | FilteredElbResources
  | FilteredElcResources
  | FilteredRdsResources
  | FilteredRdsSnapshotResourcesV2
  | FilteredS3Resources
  | FilteredAzAksResources
  | FilteredAzDiskResources
  | FilteredAzIpResources
  | FilteredAzLbResources
  | FilteredAzSqlResources
  | FilteredAzVmResources
  | FilteredAzVmssResources
  | FilteredDiskResources
  | FilteredGkeResources
  | FilteredIpResources
  | FilteredLbResources
  | FilteredMigResources
  | FilteredSqlResources
  | FilteredVmResources
  | FilteredRdsSnapshotSourceResources
  | FilteredDynamoDbTableResources
  | FilteredDynamoDbBackupResources
  | FilteredDaxClusterResources
  | FilteredNatGatewayResources
  | FilteredEcsResources
  | FilteredEcsWorkloadResources
  | FilteredEcsWorkloadTaskResources;
export type PostUsersMeV2OrganisationsCurrentByTypeResourcesApiArg = {
  /** Resource Type */
  type:
    | "ec2"
    | "ebs"
    | "elb"
    | "eip"
    | "rds"
    | "elc"
    | "s3"
    | "vm"
    | "disk"
    | "lb"
    | "ip"
    | "sql"
    | "az_disk"
    | "az_ip"
    | "az_lb"
    | "az_vm"
    | "az_sql"
    | "asg"
    | "mig"
    | "mig_vm"
    | "asg_ec2"
    | "eks"
    | "eks_ec2"
    | "node_group"
    | "az_vmss"
    | "az_vmss_vm"
    | "gke"
    | "node_pool"
    | "gke_vm"
    | "az_aks"
    | "az_node_pool"
    | "az_aks_vm"
    | "rds_snapshot"
    | "ebs_snapshot"
    | "rds_snapshot_source"
    | "dynamo_db_table"
    | "dynamo_db_backup"
    | "dax_cluster"
    | "nat_gateway"
    | "ecs"
    | "ecs_workload";
  body: {
    account_ids: string[];
    filter: ResourceFilters;
  };
};
export type GetUsersMeDynamoDbBackupTypesApiResponse =
  /** status 200 ok */ DynamoDbBackupType[];
export type GetUsersMeDynamoDbBackupTypesApiArg = void;
export type PostUsersMeDynamoDbBackupTableNamesApiResponse =
  /** status 200 ok */ string[];
export type PostUsersMeDynamoDbBackupTableNamesApiArg = {
  body: {
    account_ids?: string[];
  };
};
export type GetUsersMeByTypeStorageTiersApiResponse =
  /** status 200 ok */ StorageTier[];
export type GetUsersMeByTypeStorageTiersApiArg = {
  /** Resource Type */
  type:
    | "ec2"
    | "ebs"
    | "elb"
    | "eip"
    | "rds"
    | "elc"
    | "s3"
    | "vm"
    | "disk"
    | "lb"
    | "ip"
    | "sql"
    | "az_disk"
    | "az_ip"
    | "az_lb"
    | "az_vm"
    | "az_sql"
    | "asg"
    | "mig"
    | "mig_vm"
    | "asg_ec2"
    | "eks"
    | "eks_ec2"
    | "node_group"
    | "az_vmss"
    | "az_vmss_vm"
    | "gke"
    | "node_pool"
    | "gke_vm"
    | "az_aks"
    | "az_node_pool"
    | "az_aks_vm"
    | "rds_snapshot"
    | "ebs_snapshot"
    | "rds_snapshot_source"
    | "dynamo_db_table"
    | "dynamo_db_backup"
    | "dax_cluster"
    | "nat_gateway"
    | "ecs"
    | "ecs_workload";
};
export type PutUsersMeAccountsByAccountIdResourcesApiResponse =
  /** status 200 Example response */ {
    execution_id?: string;
  };
export type PutUsersMeAccountsByAccountIdResourcesApiArg = {
  accountId: string;
  body: {
    type?: string;
    id?: string;
    action?: "terminate" | "stop" | "start";
    snapshot?: boolean;
  }[];
};
export type PatchUsersMeAccountsByAccountIdResourcesApiResponse =
  /** status 200 OK */ {
    resources: {
      type: string;
      id: string;
      is_protected: boolean;
      protected_by: string | null;
    }[];
  };
export type PatchUsersMeAccountsByAccountIdResourcesApiArg = {
  accountId: string;
  body: {
    type?: string;
    id?: string;
    is_protected?: boolean;
  }[];
};
export type GetProvidersByProviderRegionsApiResponse =
  /** status 200 OK */ Regions;
export type GetProvidersByProviderRegionsApiArg = {
  /** Cloud Provider */
  provider: "gcp" | "aws" | "azure";
};
export type GetProvidersByProviderFiltersApiResponse =
  /** status 200 Provider filters */ Filters;
export type GetProvidersByProviderFiltersApiArg = {
  /** Cloud Provider */
  provider: "gcp" | "aws" | "azure";
};
export type GetV2PlansApiResponse = /** status 200 OK */ {
  free?: Plan[];
  pro?: Plan[];
  advanced?: Plan[];
};
export type GetV2PlansApiArg = void;
export type PostUsersMeOrganisationsCurrentGcpBillingExportDataApiResponse =
  /** status 201 Created */ GcpBillingExportData;
export type PostUsersMeOrganisationsCurrentGcpBillingExportDataApiArg = {
  body: {
    project_id: string;
    dataset: string;
    table: string;
  };
};
export type GetUsersMeOrganisationsCurrentGcpBillingExportDataApiResponse =
  /** status 200 OK */ GcpBillingExportData[];
export type GetUsersMeOrganisationsCurrentGcpBillingExportDataApiArg = void;
export type GetUsersMeSubscriptionsCurrentApiResponse =
  /** status 200 OK */ Subscription;
export type GetUsersMeSubscriptionsCurrentApiArg = void;
export type PostV2UsersMeSchedulesApiResponse =
  /** status 201 Created */ Schedule;
export type PostV2UsersMeSchedulesApiArg = {
  body: {
    name: string;
    start_date_time: NullableDate;
    end_date_time: NullableDate;
    cron: string | null;
    account_ids: string[];
    regions: string[];
    filter: ResourceFiltersWithAction[];
    action: ScheduleActionType;
    integrations?: NestedIntegration[] | null;
    notifications?:
      | (
          | NotificationEmail
          | NotificationSlack
          | NotificationWebhook
          | NotificationJira
        )[]
      | null;
    time_zone: string;
    min_threshold?: number | null;
    is_snoozed?: boolean | null;
    grace_period?: GracePeriod;
    emails?: Emails;
    weekly_schedule_interval?: number | null;
    daily_schedule_interval?: number | null;
    status?: ScheduleStatus;
    description?: string | null;
  };
};
export type GetV2UsersMeSchedulesApiResponse =
  /** status 200 Example response */ Schedule[];
export type GetV2UsersMeSchedulesApiArg = {
  accountId?: string;
};
export type GetV2UsersMeSchedulesByScheduleIdApiResponse =
  /** status 200 OK */ Schedule;
export type GetV2UsersMeSchedulesByScheduleIdApiArg = {
  scheduleId: string;
};
export type PatchV2UsersMeSchedulesByScheduleIdApiResponse =
  /** status 200 OK */ Schedule;
export type PatchV2UsersMeSchedulesByScheduleIdApiArg = {
  scheduleId: string;
  body: {
    name?: string | null;
    start_date_time?: NullableDate;
    end_date_time: NullableDate;
    cron: string | null;
    regions: string[] | null;
    filter?: ResourceFiltersWithAction[] | null;
    action: ScheduleActionType;
    status?: ScheduleStatus;
    integrations?: NestedIntegration[] | null;
    notifications?:
      | (
          | NotificationEmail
          | NotificationSlack
          | NotificationWebhook
          | NotificationJira
        )[]
      | null;
    account_ids: string[];
    time_zone?: string;
    min_threshold?: number | null;
    is_snoozed?: boolean | null;
    grace_period?: GracePeriod;
    emails?: Emails;
    weekly_schedule_interval?: number | null;
    daily_schedule_interval?: number | null;
    description?: string | null;
  };
};
export type GetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdApiResponse =
  /** status 200 OK */ WorkflowLight;
export type GetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdApiArg = {
  workflowId: string;
  batchId: string;
};
export type GetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesApiResponse =
  /** status 200 OK */ AllFilteredResources[];
export type GetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesApiArg = {
  workflowId: string;
  batchId: string;
};
export type PostUsersMeOrganisationsCurrentBillingPortalApiResponse =
  /** status 201 Example response */ {
    link?: string;
  };
export type PostUsersMeOrganisationsCurrentBillingPortalApiArg = void;
export type PostUsersMeOrganisationsCurrentBillingPortalPlansByPlanIdApiResponse =
  /** status 201 Example response */ {
    link?: string;
  };
export type PostUsersMeOrganisationsCurrentBillingPortalPlansByPlanIdApiArg = {
  planId: string;
};
export type GetUsersMeOrganisationsCurrentApiResponse =
  /** status 200 OK */ Organisation;
export type GetUsersMeOrganisationsCurrentApiArg = void;
export type PatchUsersMeOrganisationsCurrentApiResponse =
  /** status 200 OK */ Organisation;
export type PatchUsersMeOrganisationsCurrentApiArg = {
  body: {
    name?: string | null;
    email?: string | null;
  };
};
export type PostV2UsersMeOffHoursSchedulesApiResponse =
  /** status 201 Created */ OffHoursSchedule;
export type PostV2UsersMeOffHoursSchedulesApiArg = {
  body: {
    name: string;
    start_date_time: NullableDate;
    end_date_time: NullableDate;
    start_cron: string | null;
    stop_cron: string | null;
    account_ids: string[];
    regions: string[];
    filter: ResourceFiltersWithAction[];
    integrations?: NestedIntegration[] | null;
    notifications?:
      | (
          | NotificationEmail
          | NotificationSlack
          | NotificationWebhook
          | NotificationJira
        )[]
      | null;
    action: OffHoursScheduleActionType;
    time_zone: string;
    min_threshold?: number | null;
    emails?: Emails;
    status?: ("active" | "inactive") | null;
    description?: string | null;
  };
};
export type GetV2UsersMeOffHoursSchedulesApiResponse =
  /** status 200 Example response */ OffHoursSchedule[];
export type GetV2UsersMeOffHoursSchedulesApiArg = {
  accountId?: string;
};
export type GetV2UsersMeOffHoursSchedulesByScheduleIdApiResponse =
  /** status 200 OK */ OffHoursSchedule;
export type GetV2UsersMeOffHoursSchedulesByScheduleIdApiArg = {
  scheduleId: string;
};
export type PatchV2UsersMeOffHoursSchedulesByScheduleIdApiResponse =
  /** status 200 OK */ OffHoursSchedule;
export type PatchV2UsersMeOffHoursSchedulesByScheduleIdApiArg = {
  scheduleId: string;
  body: {
    name?: string;
    start_date_time?: NullableDate;
    end_date_time: NullableDate;
    start_cron: string | null;
    stop_cron: string | null;
    regions: string[];
    filter?: ResourceFiltersWithAction[];
    status?: string | null;
    action: OffHoursScheduleActionType;
    integrations?: NestedIntegration[] | null;
    notifications?:
      | (
          | NotificationEmail
          | NotificationSlack
          | NotificationWebhook
          | NotificationJira
        )[]
      | null;
    account_ids: string[];
    time_zone?: string;
    min_threshold?: number | null;
    emails?: Emails;
    description?: string | null;
  };
};
export type GetUsersMeFeaturesApiResponse = /** status 200 OK */ Feature[];
export type GetUsersMeFeaturesApiArg = void;
export type PostUsersMeFeaturesApiResponse =
  /** status 201 A JSON object of account attempt */ Feature[];
export type PostUsersMeFeaturesApiArg = {
  body: {
    /** The key of feature user has seen */
    feature_key?: string | null;
    /** List of feature keys */
    feature_keys?: string[] | null;
  };
};
export type GetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsApiResponse =
  /** status 200 A JSON array of missing aws permissions */ AccountMissingPermissions;
export type GetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsApiArg =
  {
    accountId: string;
  };
export type GetUsersMeProvidersGcpAccountsByAccountIdMissingRolesApiResponse =
  /** status 200 A JSON array of missing gcp roles */ AccountMissingPermissions;
export type GetUsersMeProvidersGcpAccountsByAccountIdMissingRolesApiArg = {
  accountId: string;
};
export type GetUsersMeProvidersGcpAccountsByAccountIdMissingRolesOptionsApiResponse =
  /** status 200 Gcp account missing role options */ {
    /** Gcp project id */
    project_id: string;
    /** Gcp service account */
    service_account: string;
  };
export type GetUsersMeProvidersGcpAccountsByAccountIdMissingRolesOptionsApiArg =
  {
    accountId: string;
  };
export type GetUsersMeProvidersGcpAccountsByAccountIdMissingApisApiResponse =
  /** status 200 A JSON array of missing gcp apis */ AccountMissingPermissions;
export type GetUsersMeProvidersGcpAccountsByAccountIdMissingApisApiArg = {
  accountId: string;
};
export type GetUsersMeProvidersAzureAccountsByAccountIdMissingRolesApiResponse =
  /** status 200 Example response */ {
    name?: string;
    title?: string;
  }[];
export type GetUsersMeProvidersAzureAccountsByAccountIdMissingRolesApiArg = {
  accountId: string;
};
export type GetUsersMeProvidersAzureAccountsByAccountIdMissingRolesOptionsApiResponse =
  /** status 200 Azure account missing role options */ {
    /** Azure subscription id */
    subscription_id: string;
    /** Azure subscription name */
    subscription_name: string;
    /** Azure service principal id */
    service_principal_id: string;
    /** Azure service principal name */
    service_principal_name: string;
    /** Azure tenant id */
    tenant_id: string;
  };
export type GetUsersMeProvidersAzureAccountsByAccountIdMissingRolesOptionsApiArg =
  {
    accountId: string;
  };
export type PatchUsersMeAccountsByAccountIdPermissionsApiResponse =
  /** status 200 Updated missing permissions */ object;
export type PatchUsersMeAccountsByAccountIdPermissionsApiArg = {
  accountId: string;
};
export type GetUsersMeIntegrationsApiResponse =
  /** status 200 OK */ Integration[];
export type GetUsersMeIntegrationsApiArg = {
  /** Integration type in query */
  type?: "webhook" | "slack" | "email" | "jira";
};
export type DeleteUsersMeIntegrationsByIntegrationIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeIntegrationsByIntegrationIdApiArg = {
  integrationId: string;
};
export type PostUsersMeByTypeIntegrationsApiResponse =
  /** status 201 Created */ Integration;
export type PostUsersMeByTypeIntegrationsApiArg = {
  type: string;
  body: {
    name: string;
    emails?: string[] | null;
    token?: string | null;
    channel?: string | null;
    url?: string | null;
    content_type?: string | null;
    api_token?: string | null;
    jira_email?: string | null;
    default_project_key?: string | null;
    default_issue_type_id?: string | null;
    conversation?: SlackConversation;
    conversations?: SlackConversation[] | null;
  };
};
export type GetUsersMeByTypeIntegrationsAndIntegrationIdApiResponse =
  /** status 200 OK */ Integration;
export type GetUsersMeByTypeIntegrationsAndIntegrationIdApiArg = {
  type: string;
  integrationId: string;
};
export type PatchUsersMeByTypeIntegrationsAndIntegrationIdApiResponse =
  /** status 200 OK */ void;
export type PatchUsersMeByTypeIntegrationsAndIntegrationIdApiArg = {
  type: string;
  integrationId: string;
  body: {
    name?: string | null;
    emails?: string[] | null;
    channel?: string | null;
    url?: string | null;
    content_type?: string | null;
    default_project_key?: string | null;
    default_issue_type_id?: string | null;
    jira_email?: string | null;
    conversation?: SlackConversation;
    conversations?: SlackConversation[] | null;
  };
};
export type PostUsersMeSlackIntegrationsByIntegrationIdTestMessagesApiResponse =
  /** status 200 OK */ void;
export type PostUsersMeSlackIntegrationsByIntegrationIdTestMessagesApiArg = {
  integrationId: string;
  body: {
    name?: string | null;
    emails?: string[] | null;
    channel?: string | null;
    url?: string | null;
    content_type?: string | null;
    default_project_key?: string | null;
    default_issue_type_id?: string | null;
    jira_email?: string | null;
    conversation?: SlackConversation;
    conversations?: SlackConversation[] | null;
  };
};
export type PostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsApiResponse =
  /** status 201 Example response */ {
    link?: string;
  };
export type PostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsApiArg = {
  accountId: string;
};
export type PostUsersMeProvidersAwsAccountsByAccountIdCfnChangeAccessModeChangeSetsApiResponse =
  /** status 201 Example response */ {
    link?: string;
  };
export type PostUsersMeProvidersAwsAccountsByAccountIdCfnChangeAccessModeChangeSetsApiArg =
  {
    accountId: string;
  };
export type GetUsersMeOrganisationsCurrentProvidersByProviderTotalSavedAmountApiResponse =
  /** status 200 Example response */ {
    amount?: number;
  };
export type GetUsersMeOrganisationsCurrentProvidersByProviderTotalSavedAmountApiArg =
  {
    /** Cloud Provider */
    provider: "gcp" | "aws" | "azure";
  };
export type GetUsersMeOrganisationsCurrentSsoOptionsCurrentApiResponse =
  /** status 200 Example response */ {
    sso_url: string;
    sp_entity_id: string;
  };
export type GetUsersMeOrganisationsCurrentSsoOptionsCurrentApiArg = void;
export type PostUsersMeOrganisationsCurrentSsoApiResponse =
  /** status 201 Created */ void;
export type PostUsersMeOrganisationsCurrentSsoApiArg = {
  body: {
    type?: "okta" | "azure_ad";
    /** IdP mestadata, xml content  */
    metadata?: string;
  };
};
export type DeleteUsersMeOrganisationsCurrentSsoCurrentApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentSsoCurrentApiArg = void;
export type GetUsersMeOrganisationsCurrentSettingsApiResponse =
  /** status 200 Example response */ {
    smart_tag_ttl?: SmartTagTtlSetting;
    quarter_starts_from_month?: QuarterStartsFromMonthSetting;
  };
export type GetUsersMeOrganisationsCurrentSettingsApiArg = void;
export type GetUsersMeOrganisationsCurrentSettingsByKeyApiResponse =
  /** status 200 OK */ SmartTagTtlSetting | QuarterStartsFromMonthSetting;
export type GetUsersMeOrganisationsCurrentSettingsByKeyApiArg = {
  key: string;
};
export type PatchUsersMeOrganisationsCurrentSettingsByKeyApiResponse =
  /** status 200 OK */ SmartTagTtlSetting | QuarterStartsFromMonthSetting;
export type PatchUsersMeOrganisationsCurrentSettingsByKeyApiArg = {
  key: string;
  body: {
    value?: string;
  };
};
export type PostUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummaryApiResponse =
  /** status 200 OK */ SavingsPlanRecommendationSummary;
export type PostUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummaryApiArg =
  {
    body: {
      provider_organisation_id?: string | null;
      account_ids: string[];
      term_in_years: SavingsPlanTermInYears;
      account_scope: SavingsPlanAccountScope;
      savings_plan_type: SavingsPlanType;
      look_back_period: LookbackPeriod;
      payment_option: SavingsPlanPaymentOption;
    };
  };
export type GetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsApiResponse =
  /** status 200 OK */ SavingsPlanRecommendationsPotentialSavings;
export type GetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsApiArg = {
  providerOrganisationId?: string;
};
export type GetUsersMeOrganisationsCurrentSavingsPlansUtilizationRecommendationsApiResponse =
  /** status 200 OK */ SavingsPlanUtilizationRecommendation[];
export type GetUsersMeOrganisationsCurrentSavingsPlansUtilizationRecommendationsApiArg =
  {
    savingsPlanId: string;
  };
export type GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiResponse =
  /** status 200 OK */ SavingsPlanRecommendation[];
export type GetUsersMeOrganisationsCurrentSavingsPlansRecommendationsApiArg = {
  providerOrganisationId?: string;
  accountId?: string;
  resourceType?: SavingsPlanResourceType;
  region?: string;
  instanceFamily?: string;
};
export type GetUsersMeOrganisationsCurrentSavingsPlansApiResponse =
  /** status 200 OK */ SavingsPlanData;
export type GetUsersMeOrganisationsCurrentSavingsPlansApiArg = {
  providerOrganisationId?: string;
  dateFrom?: Date;
  dateTo?: Date;
  dateLabel?: DateLabel;
  dateType?: DateType;
  dateValue?: number | null;
  dateUnit?: DateUnit;
  dataGranularity?: DateDataPoint;
  coverageGrouping?: SavingsPlanCoverageGrouping;
  loadChartData?: boolean;
  loadTableData?: boolean;
  loadTotals?: boolean;
};
export type GetUsersMeOrganisationsCurrentSavingsPlansMinimumAvailableDateApiResponse =
  /** status 200 OK */ MinimumAvailableDate;
export type GetUsersMeOrganisationsCurrentSavingsPlansMinimumAvailableDateApiArg =
  void;
export type GetUsersMeOrganisationsCurrentSavingsPlansDataApiResponse =
  /** status 200 OK */ SavingsPlanFilteredData;
export type GetUsersMeOrganisationsCurrentSavingsPlansDataApiArg = {
  providerOrganisationId?: string;
  type: SavingsPlanFilterType;
  dateFrom?: Date;
  dateTo?: Date;
  dateLabel?: DateLabel;
  dateType?: DateType;
  dateValue?: number | null;
  dateUnit?: DateUnit;
  coverageGrouping?: SavingsPlanCoverageGrouping;
  resourceType?: SavingsPlanResourceType;
  instanceFamily?: string;
  region?: string;
  accountId?: string;
  savingsPlanId?: string;
  dataGranularity?: DateDataPoint;
};
export type PostUsersMeSchedulesNextRunDateApiResponse = /** status 200 OK */ {
  next_run_date_time: Date;
  future_run_dates?: FutureRunDate[] | null;
};
export type PostUsersMeSchedulesNextRunDateApiArg = {
  body: {
    cron: string;
    start_date_time: NullableDate;
    end_date_time?: NullableDate;
    schedule_frequency?: ScheduleFrequency;
    schedule_interval?: number;
    time_zone: string;
  };
};
export type PostUsersMeOffHoursSchedulesNextRunDateApiResponse =
  /** status 200 OK */ {
    next_start_run_date_time: Date;
    next_stop_run_date_time: NullableDate;
  };
export type PostUsersMeOffHoursSchedulesNextRunDateApiArg = {
  body: {
    start_cron: string;
    stop_cron: string;
    start_date_time: NullableDate;
    end_date_time?: NullableDate;
    time_zone: string;
  };
};
export type GetUsersMeAccountsByAccountIdAndTypeResourcesMetadataApiResponse =
  /** status 200 OK */ {
    total_cost?: number;
    most_used_region?: Region;
    average_age?: string;
  };
export type GetUsersMeAccountsByAccountIdAndTypeResourcesMetadataApiArg = {
  accountId: string;
  /** Resource Type */
  type:
    | "ec2"
    | "ebs"
    | "elb"
    | "eip"
    | "rds"
    | "elc"
    | "s3"
    | "vm"
    | "disk"
    | "lb"
    | "ip"
    | "sql"
    | "az_disk"
    | "az_ip"
    | "az_lb"
    | "az_vm"
    | "az_sql"
    | "asg"
    | "mig"
    | "mig_vm"
    | "asg_ec2"
    | "eks"
    | "eks_ec2"
    | "node_group"
    | "az_vmss"
    | "az_vmss_vm"
    | "gke"
    | "node_pool"
    | "gke_vm"
    | "az_aks"
    | "az_node_pool"
    | "az_aks_vm"
    | "rds_snapshot"
    | "ebs_snapshot"
    | "rds_snapshot_source"
    | "dynamo_db_table"
    | "dynamo_db_backup"
    | "dax_cluster"
    | "nat_gateway"
    | "ecs"
    | "ecs_workload";
};
export type GetUsersMeAccountsByAccountIdMetadataApiResponse =
  /** status 200 Example response */ {
    spending: number;
    total_saved: number;
    estimated_waste: number;
    most_expensive_resource_type: ResourceType;
    total_spending: number;
    average_age: string;
    most_used_region?: Region;
  };
export type GetUsersMeAccountsByAccountIdMetadataApiArg = {
  accountId: string;
};
export type GetUsersMeOrganisationsCurrentApiKeysApiResponse =
  /** status 200 Example response */ ApiKey[];
export type GetUsersMeOrganisationsCurrentApiKeysApiArg = void;
export type PostUsersMeOrganisationsCurrentApiKeysApiResponse =
  /** status 201 Example response */ {
    id: string;
    organisation_id: string;
    api_key: string;
    name: string;
    created_at: string;
    expires_at: string;
    last_used_at: string | null;
  };
export type PostUsersMeOrganisationsCurrentApiKeysApiArg = {
  body: {
    name: string;
    expires_in_days: number;
  };
};
export type DeleteUsersMeOrganisationsCurrentApiKeysByKeyIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentApiKeysByKeyIdApiArg = {
  keyId: string;
};
export type GetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdApiResponse =
  /** status 200 OK */ OrganisationDailyOffHoursRecommendation2;
export type GetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdApiArg =
  {
    recommendationId: string;
    accountId: string;
  };
export type GetUsersMeAccountByAccountIdWeeklyOffHoursRecommendationsAndRecommendationIdApiResponse =
  /** status 200 OK */ OrganisationWeeklyOffHoursRecommendation2;
export type GetUsersMeAccountByAccountIdWeeklyOffHoursRecommendationsAndRecommendationIdApiArg =
  {
    recommendationId: string;
    accountId: string;
  };
export type PatchUsersMeAccountByAccountIdRecommendationsResourcesAndResourceIdApiResponse =
  /** status 200 OK */ void;
export type PatchUsersMeAccountByAccountIdRecommendationsResourcesAndResourceIdApiArg =
  {
    accountId: string;
    resourceId: string;
    body: {
      dismissed: boolean;
    };
  };
export type GetUsersMeCategoriesApiResponse =
  /** status 200 OK */ AccountCategoryWithCost[];
export type GetUsersMeCategoriesApiArg = void;
export type PostUsersMeCategoriesApiResponse =
  /** status 201 OK */ AccountCategory;
export type PostUsersMeCategoriesApiArg = {
  body: {
    name?: string;
    value?: string;
    account_ids?: string[];
  };
};
export type PutUsersMeCategoriesApiResponse = /** status 201 OK */ void;
export type PutUsersMeCategoriesApiArg = {
  body: {
    category_group_name?: string;
    duplicate_category_group_name?: string;
  };
};
export type GetUsersMeCategoriesByCategoryIdApiResponse =
  /** status 200 OK */ AccountCategory;
export type GetUsersMeCategoriesByCategoryIdApiArg = {
  categoryId: string;
};
export type PutUsersMeCategoriesByCategoryIdApiResponse =
  /** status 200 OK */ AccountCategory;
export type PutUsersMeCategoriesByCategoryIdApiArg = {
  categoryId: string;
  body: {
    name?: string;
    value?: string;
    account_ids?: string[];
  };
};
export type DeleteUsersMeCategoriesByCategoryIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeCategoriesByCategoryIdApiArg = {
  categoryId: string;
};
export type GetUsersMeOrganisationsCurrentBillingAccountsApiResponse =
  /** status 200 OK */ EmbeddedAccount[];
export type GetUsersMeOrganisationsCurrentBillingAccountsApiArg = void;
export type GetUsersMeOrganisationsCurrentDimensionsApiResponse =
  /** status 200 OK */ DimensionWithCosts[];
export type GetUsersMeOrganisationsCurrentDimensionsApiArg = void;
export type PostUsersMeOrganisationsCurrentDimensionsApiResponse =
  /** status 201 OK */ Dimension;
export type PostUsersMeOrganisationsCurrentDimensionsApiArg = {
  body: {
    name?: string;
  };
};
export type GetUsersMeOrganisationsCurrentDimensionsByDimensionIdApiResponse =
  /** status 200 OK */ Dimension;
export type GetUsersMeOrganisationsCurrentDimensionsByDimensionIdApiArg = {
  dimensionId: string;
};
export type PutUsersMeOrganisationsCurrentDimensionsByDimensionIdApiResponse =
  /** status 200 OK */ Dimension;
export type PutUsersMeOrganisationsCurrentDimensionsByDimensionIdApiArg = {
  dimensionId: string;
  body: {
    name?: string;
  };
};
export type DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdApiArg = {
  dimensionId: string;
};
export type GetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesApiResponse =
  /** status 200 OK */ Category[];
export type GetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesApiArg =
  {
    dimensionId: string;
  };
export type PostUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesApiResponse =
  /** status 201 OK */ Category;
export type PostUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesApiArg =
  {
    dimensionId: string;
    body: {
      name: string;
      filter: CategoryFilter;
    };
  };
export type GetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiResponse =
  /** status 200 OK */ Category;
export type GetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg =
  {
    dimensionId: string;
    categoryId: string;
  };
export type PutUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiResponse =
  /** status 200 OK */ Category;
export type PutUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg =
  {
    dimensionId: string;
    categoryId: string;
    body: {
      name?: string;
      filter?: CategoryFilter;
    };
  };
export type DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdApiArg =
  {
    dimensionId: string;
    categoryId: string;
  };
export type PostUsersMeProvidersGcpBillingAccountAttemptsApiResponse =
  /** status 201 Example response */ {
    id: string;
    user_id: string;
    project_id: string;
    dataset_name: string;
    table_name: string;
    connected_projects: Project[];
    status: "started" | "closed";
    created_at: string;
    status_details: {
      started?: GcpBillingAccountAttemptStatusDetailsStarted;
      closed?: GcpBillingAccountAttemptStatusDetailsClosed;
    } | null;
  };
export type PostUsersMeProvidersGcpBillingAccountAttemptsApiArg = {
  body: {
    project_id: string;
    dataset_name: string;
    table_name: string;
  };
};
export type GetUsersMeProvidersGcpBillingAccountAttemptsByBillingAccountAttemptIdApiResponse =
  /** status 200 Example response */ {
    id: string;
    user_id: string;
    project_id: string;
    dataset_name: string;
    table_name: string;
    connected_projects: Project[];
    status: "started" | "closed";
    created_at: string;
    status_details: {
      started?: GcpBillingAccountAttemptStatusDetailsStarted;
      closed?: GcpBillingAccountAttemptStatusDetailsClosed;
    } | null;
  };
export type GetUsersMeProvidersGcpBillingAccountAttemptsByBillingAccountAttemptIdApiArg =
  {
    billingAccountAttemptId: string;
  };
export type PostUsersMeWebhookIntegrationsTestsApiResponse =
  /** status 200 OK */ void;
export type PostUsersMeWebhookIntegrationsTestsApiArg = {
  body: {
    url?: string;
    content_type?: string;
  };
};
export type GetV2UsersMeOrganisationsCurrentUsersApiResponse =
  /** status 200 OK */ User[];
export type GetV2UsersMeOrganisationsCurrentUsersApiArg = {
  /** User role */
  role?: "owner" | "admin";
  statuses?: string;
};
export type GetUsersMeOrganisationsCurrentChartApiResponse =
  /** status 200 OK */ Chart[];
export type GetUsersMeOrganisationsCurrentChartApiArg = void;
export type PostUsersMeOrganisationsCurrentChartApiResponse =
  /** status 201 Created */ Chart;
export type PostUsersMeOrganisationsCurrentChartApiArg = {
  body: {
    name: string;
    payload: object;
  };
};
export type GetUsersMeOrganisationsCurrentChartByChartIdApiResponse =
  /** status 200 OK */ Chart;
export type GetUsersMeOrganisationsCurrentChartByChartIdApiArg = {
  chartId: string;
};
export type PatchUsersMeOrganisationsCurrentChartByChartIdApiResponse =
  /** status 200 OK */ Chart;
export type PatchUsersMeOrganisationsCurrentChartByChartIdApiArg = {
  chartId: string;
  body: {
    name?: string;
    payload?: object;
  };
};
export type DeleteUsersMeOrganisationsCurrentChartByChartIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentChartByChartIdApiArg = {
  chartId: string;
};
export type PostUsersMeAccountsByTypeResourcesApiResponse =
  /** status 200 Example response */ {
    account_id: string;
    provider_account_id: string;
    provider_account_name: string | null;
    resources: {
      id: string;
      provider_unique_identifier: string;
      name: string | null;
    }[];
  }[];
export type PostUsersMeAccountsByTypeResourcesApiArg = {
  /** Resource Type */
  type:
    | "ec2"
    | "ebs"
    | "elb"
    | "eip"
    | "rds"
    | "elc"
    | "s3"
    | "vm"
    | "disk"
    | "lb"
    | "ip"
    | "sql"
    | "az_disk"
    | "az_ip"
    | "az_lb"
    | "az_vm"
    | "az_sql"
    | "asg"
    | "mig"
    | "mig_vm"
    | "asg_ec2"
    | "eks"
    | "eks_ec2"
    | "node_group"
    | "az_vmss"
    | "az_vmss_vm"
    | "gke"
    | "node_pool"
    | "gke_vm"
    | "az_aks"
    | "az_node_pool"
    | "az_aks_vm"
    | "rds_snapshot"
    | "ebs_snapshot"
    | "rds_snapshot_source"
    | "dynamo_db_table"
    | "dynamo_db_backup"
    | "dax_cluster"
    | "nat_gateway"
    | "ecs"
    | "ecs_workload";
  /** Fetch resources by account id list */
  body: {
    account_ids: string[];
  };
};
export type GetUsersMeProvidersByProviderAccountsResourcesVisibilitiesApiResponse =
  /** status 200 OK */ AccountsVisibilities[];
export type GetUsersMeProvidersByProviderAccountsResourcesVisibilitiesApiArg = {
  /** Cloud Provider */
  provider: "gcp" | "aws" | "azure";
  /** Account ids */
  accountIds: string;
};
export type PostUsersMeChatQuestionsApiResponse =
  /** status 200 OK */ ChatAnswer;
export type PostUsersMeChatQuestionsApiArg = {
  body: {
    question: string;
  };
};
export type GetUsersMeOrganisationsCurrentResourcesRightSizingRecommendationsApiResponse =
  /** status 200 OK */ RightSizingRecommendation[];
export type GetUsersMeOrganisationsCurrentResourcesRightSizingRecommendationsApiArg =
  {
    /** Flag for getting dismissed recommendations */
    isDismissed?: boolean;
  };
export type GetUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationIdApiResponse =
  /** status 200 OK */ RightSizingRecommendationDetails;
export type GetUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationIdApiArg =
  {
    accountId: string;
    recommendationId: string;
    numberOfMetricsDataPoints?: number;
  };
export type GetUsersMeAccountsByAccountIdFilterSetsV2ApiResponse =
  /** status 200 Get list of filter sets with ems */ AccountFilterSetV2[];
export type GetUsersMeAccountsByAccountIdFilterSetsV2ApiArg = {
  /** Account Id */
  accountId: string;
};
export type GetUsersMeOrganisationsCurrentFilterSetsApiResponse =
  /** status 200 OK */ FilterSet[];
export type GetUsersMeOrganisationsCurrentFilterSetsApiArg = void;
export type PostUsersMeOrganisationsCurrentFilterSetsV2ApiResponse =
  /** status 201 Created */ FilterSet;
export type PostUsersMeOrganisationsCurrentFilterSetsV2ApiArg = {
  body: {
    name: string;
    filters: AccountResourceFilter[];
    cloud_provider: ProviderType;
    priority_account_id?: string | null;
  };
};
export type GetUsersMeOrganisationsCurrentCostAllocationTagsApiResponse =
  /** status 200 Ok */ string[];
export type GetUsersMeOrganisationsCurrentCostAllocationTagsApiArg = {
  /** Cost allocation tag key */
  tagKey: string;
  /** Provider Account ids */
  accountIds: string;
};
export type GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValuesApiResponse =
  /** status 200 Ok */ string[];
export type GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValuesApiArg =
  {
    provider: ProviderType;
    /** Cost allocation tag key */
    tagKey: string;
    /** Provider Account ids */
    accountIds: string;
  };
export type GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFiltersApiResponse =
  /** status 200 Ok */ ResourceExplorerPossibleFilter[];
export type GetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFiltersApiArg =
  {
    /** Cloud Provider */
    provider: "gcp" | "aws" | "azure" | "global";
  };
export type PostUsersMeOrganisationsCurrentTrackedResourcesApiResponse =
  /** status 200 Tracked resource */ {
    account_ids: string[];
    resource_type: ResourceType;
    resource_ids: string[];
  }[];
export type PostUsersMeOrganisationsCurrentTrackedResourcesApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  body: LoadTrackedResourcesForView | LoadTrackedResourcesForCategories;
};
export type GetUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyApiResponse =
  /** status 200 Ok */ (ResourceExplorer | ResourceExplorerFolder)[];
export type GetUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyApiArg =
  {
    /** Content-type */
    "Content-Type"?: string;
  };
export type PatchUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyApiResponse =
  /** status 200 Ok */ (ResourceExplorer | ResourceExplorerFolder)[];
export type PatchUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyApiArg =
  {
    body: {
      id: string;
      parent_id?: string;
      index?: number;
    };
  };
export type GetUsersMeOrganisationsCurrentResourceExplorerHierarchyApiResponse =
  /** status 200 Ok */ (ResourceExplorer | ReFolderV2)[];
export type GetUsersMeOrganisationsCurrentResourceExplorerHierarchyApiArg = {
  /** Content-type */
  "Content-Type"?: string;
};
export type PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyApiResponse =
  /** status 200 Ok */ (ResourceExplorer | ReFolderV2)[];
export type PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyApiArg = {
  body: {
    id: string;
    parent_id?: string;
    index?: number;
  };
};
export type GetV2UsersMeOrganisationsCurrentResourceExplorerHierarchyApiResponse =
  /** status 200 Ok */ {
    visible_only_to_me?: (ResourceExplorer | ReFolderV2)[];
    visible_to_everyone?: (ResourceExplorer | ReFolderV2)[];
  };
export type GetV2UsersMeOrganisationsCurrentResourceExplorerHierarchyApiArg = {
  /** Content-type */
  "Content-Type"?: string;
};
export type PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleOnlyToMeApiResponse =
  /** status 200 Ok */ (ResourceExplorer | ReFolderV2)[];
export type PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleOnlyToMeApiArg =
  {
    body: {
      id: string;
      parent_id?: string;
      index?: number;
    };
  };
export type PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleToEveryoneApiResponse =
  /** status 200 Ok */ (ResourceExplorer | ReFolderV2)[];
export type PatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleToEveryoneApiArg =
  {
    body: {
      id: string;
      parent_id?: string;
      index?: number;
    };
  };
export type GetUsersMeOrganisationsCurrentResourceExplorerViewsApiResponse =
  /** status 200 Ok */ ResourceExplorer[];
export type GetUsersMeOrganisationsCurrentResourceExplorerViewsApiArg = {
  /** Content-type */
  "Content-Type"?: string;
};
export type PostUsersMeOrganisationsCurrentResourceExplorerViewsApiResponse =
  /** status 201 Created */ ResourceExplorer;
export type PostUsersMeOrganisationsCurrentResourceExplorerViewsApiArg = {
  body: {
    name: string;
    filters: ResourceExplorerFilterItem[];
    cloud_provider?: ProviderType;
    cloud_providers: ProviderType[];
    data?: ResourceExplorerData;
  };
};
export type PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiResponse =
  /** status 201 Created */ ResourceExplorer;
export type PostV2UsersMeOrganisationsCurrentResourceExplorerViewsApiArg = {
  body: {
    name: string;
    filters: ResourceExplorerFilterItem[];
    cloud_provider?: ProviderType;
    cloud_providers: ProviderType[];
    data?: ResourceExplorerData;
    visibility: ("visible_only_to_me" | "visible_to_everyone") | null;
  };
};
export type PostUsersMeOrganisationsCurrentResourceExplorerFoldersApiResponse =
  /** status 201 Created */ ResourceExplorerFolder;
export type PostUsersMeOrganisationsCurrentResourceExplorerFoldersApiArg = {
  body: {
    name: string;
  };
};
export type PostV2UsersMeOrganisationsCurrentResourceExplorerFoldersApiResponse =
  /** status 201 Created */ ReFolderV2;
export type PostV2UsersMeOrganisationsCurrentResourceExplorerFoldersApiArg = {
  body: {
    name: string;
  };
};
export type PostV3UsersMeOrganisationsCurrentResourceExplorerFoldersApiResponse =
  /** status 201 Created */ ReFolderV2;
export type PostV3UsersMeOrganisationsCurrentResourceExplorerFoldersApiArg = {
  body: {
    name: string;
    visibility: ("visible_only_to_me" | "visible_to_everyone") | null;
  };
};
export type GetUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResourcesApiResponse =
  /** status 200 Ok */ ResourceExplorerResourceDetail;
export type GetUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResourcesApiArg =
  {
    /** Content-type */
    "Content-Type"?: string;
    /** Cloud Provider */
    provider: "gcp" | "aws" | "azure" | "global";
    /** Account Id */
    accountId: string;
    resourceId: string;
  };
export type DeleteUsersMeOrganisationsCurrentFilterSetsByFilterSetIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentFilterSetsByFilterSetIdApiArg = {
  filterSetId: string;
};
export type PostUsersMeOrganisationsCurrentWidgetsResourceExplorerApiResponse =
  /** status 200 Ok */ ResourceExplorerWidget;
export type PostUsersMeOrganisationsCurrentWidgetsResourceExplorerApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  resourceExplorerFilterSchema: ResourceExplorerFilterSchema;
};
export type PostUsersMeOrganisationsCurrentResourceExplorerApiResponse =
  /** status 200 Ok */ ResourceExplorerWidget;
export type PostUsersMeOrganisationsCurrentResourceExplorerApiArg = {
  /** Content-type */
  "Content-Type"?: string;
  resourceExplorerFilterSchema: ResourceExplorerFilterSchema;
};
export type PostUsersMeOrganisationsCurrentResourceExplorerGranularCostsApiResponse =
  /** status 200 Ok */ ResourceExplorerGranularCost;
export type PostUsersMeOrganisationsCurrentResourceExplorerGranularCostsApiArg =
  {
    /** Content-type */
    "Content-Type"?: string;
    resourceExplorerFilterSchema: ResourceExplorerFilterSchema;
  };
export type PostUsersMeOrganisationsCurrentWidgetsResourceExplorerLiveUsageTotalCostApiResponse =
  /** status 200 Ok */ number;
export type PostUsersMeOrganisationsCurrentWidgetsResourceExplorerLiveUsageTotalCostApiArg =
  {
    /** Content-type */
    "Content-Type"?: string;
    resourceExplorerFilterSchema: ResourceExplorerFilterSchema;
  };
export type GetUsersMeResourceExplorerPossibleFiltersApiResponse =
  /** status 200 A JSON object of Resource Explorer Possible Filters */ ResourceExplorerPossibleFiltersV2;
export type GetUsersMeResourceExplorerPossibleFiltersApiArg = {
  /** Providers Only */
  providersOnly?: boolean;
};
export type GetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesApiResponse =
  /** status 200 A JSON object of Resource Explorer Possible Filter Values */ ResourceExplorerFilterValues;
export type GetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesApiArg =
  {
    filterProvider: ResourceExplorerFilterProvider;
    filterType: ResourceExplorerDynamicFilterItemType;
    key?: string;
  };
export type PostUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvApiResponse =
  /** status 200 Ok */ {
    hash?: string;
  };
export type PostUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvApiArg =
  {
    /** Content-type */
    "Content-Type"?: string;
    resourceExplorerFilterSchema: ResourceExplorerFilterSchema;
  };
export type GetUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvApiResponse =
  /** status 200 Ok */ {
    url?: string;
  };
export type GetUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvApiArg =
  {
    hash?: string;
  };
export type PostUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsvApiResponse =
  /** status 200 Ok */ {
    hash?: string;
  };
export type PostUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsvApiArg =
  {
    /** Content-type */
    "Content-Type"?: string;
    resourceExplorerFilterSchema: ResourceExplorerFilterSchema;
  };
export type GetUsersMeOrganisationsCurrentResourceCreatorsApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeOrganisationsCurrentResourceCreatorsApiArg = {
  accountIds: string;
  /** Resource Type */
  type:
    | "ec2"
    | "ebs"
    | "elb"
    | "eip"
    | "rds"
    | "elc"
    | "s3"
    | "vm"
    | "disk"
    | "lb"
    | "ip"
    | "sql"
    | "az_disk"
    | "az_ip"
    | "az_lb"
    | "az_vm"
    | "az_sql"
    | "asg"
    | "mig"
    | "mig_vm"
    | "asg_ec2"
    | "eks"
    | "eks_ec2"
    | "node_group"
    | "az_vmss"
    | "az_vmss_vm"
    | "gke"
    | "node_pool"
    | "gke_vm"
    | "az_aks"
    | "az_node_pool"
    | "az_aks_vm"
    | "rds_snapshot"
    | "ebs_snapshot"
    | "rds_snapshot_source"
    | "dynamo_db_table"
    | "dynamo_db_backup"
    | "dax_cluster"
    | "nat_gateway"
    | "ecs"
    | "ecs_workload";
};
export type PostUsersMeJiraIntegrationsAutoCompleteUsersDataApiResponse =
  /** status 200 Fetch jira user autocomplete data */ {
    name: string;
    id: string;
    email: string;
    avatar: string;
  }[];
export type PostUsersMeJiraIntegrationsAutoCompleteUsersDataApiArg = {
  body: {
    api_token: string;
    url: string;
    email: string;
    auto_complete_url: string;
  };
};
export type PostUsersMeJiraIntegrationsByIntegrationIdIssuesApiResponse =
  /** status 201 Example response */ {
    link: string;
  };
export type PostUsersMeJiraIntegrationsByIntegrationIdIssuesApiArg = {
  integrationId: string;
  body: {
    account_id: string;
    resource_ids: string[];
    project_key: string;
    issue_type_id: string;
    summary: string;
    description: string | null;
    fields: {
      [key: string]: any;
    };
  };
};
export type PostUsersMeJiraIntegrationsProjectsApiResponse =
  /** status 200 Jira projects response */ {
    id: string;
    name: string;
    key: string;
    avatar: string;
  }[];
export type PostUsersMeJiraIntegrationsProjectsApiArg = {
  body: {
    api_token: string;
    url: string;
    email: string;
  };
};
export type PostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesApiResponse =
  /** status 200 Jira issue types response */ {
    /** issue type id */
    id: string;
    /** issue type name */
    name: string;
  }[];
export type PostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesApiArg = {
  /** Jira project id in query */
  projectId: string;
  body: {
    api_token: string;
    url: string;
    email: string;
  };
};
export type PostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesAndIssueTypeFieldsApiResponse =
  /** status 200 Jira issue type fields response */ {
    key: string;
    name: string;
    schema: {
      type: string;
      item_type: string | null;
      custom_type: string | null;
    };
    options:
      | (string[] | null)
      | (
          | {
              id: string;
              value: string | null;
              name: string | null;
              children:
                | {
                    id: string;
                    value: string | null;
                    name: string | null;
                  }[]
                | null;
            }[]
          | null
        );
    auto_complete_url: string | null;
  }[];
export type PostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesAndIssueTypeFieldsApiArg =
  {
    /** Jira project id in query */
    projectId: string;
    /** Jira Issue Type in query */
    issueType: string;
    body: {
      api_token: string;
      url: string;
      email: string;
    };
  };
export type PostUsersMeJiraIntegrationsByIntegrationIdProjectsApiResponse =
  /** status 200 Jira projects response */ {
    id: string;
    name: string;
    key: string;
    avatar: string;
  }[];
export type PostUsersMeJiraIntegrationsByIntegrationIdProjectsApiArg = {
  /** Integration id */
  integrationId: string;
};
export type PostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesApiResponse =
  /** status 200 Jira issue types response */ {
    /** issue type id */
    id: string;
    /** issue type name */
    name: string;
  }[];
export type PostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesApiArg =
  {
    /** Integration id */
    integrationId: string;
    /** Jira project id in query */
    projectId: string;
  };
export type PostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsApiResponse =
  /** status 200 Jira issue type fields response */ {
    key: string;
    name: string;
    schema: {
      type: string;
      item_type: string | null;
      custom_type: string | null;
    };
    options:
      | (string[] | null)
      | (
          | {
              id: string;
              value: string | null;
              name: string | null;
              children:
                | {
                    id: string;
                    value: string | null;
                    name: string | null;
                  }[]
                | null;
            }[]
          | null
        );
    auto_complete_url: string | null;
  }[];
export type PostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsApiArg =
  {
    /** Integration id */
    integrationId: string;
    /** Jira project id in query */
    projectId: string;
    /** Jira Issue Type in query */
    issueType: string;
  };
export type GetUsersMeProvidersAzureResourcesTypesByResourceTypeResourceGroupsApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeProvidersAzureResourcesTypesByResourceTypeResourceGroupsApiArg =
  {
    resourceType: "az_disk" | "az_ip" | "az_vm";
    accountIds: string;
  };
export type PostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataApiResponse =
  /** status 200 Fetch jira user autocomplete data */ {
    name: string;
    id: string;
    email: string;
    avatar: string;
  }[];
export type PostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataApiArg =
  {
    integrationId: string;
    body: {
      auto_complete_url: string;
    };
  };
export type PostUsersMeSlackIntegrationsByIntegrationIdMessageApiResponse =
  /** status 200 OK */ void;
export type PostUsersMeSlackIntegrationsByIntegrationIdMessageApiArg = {
  integrationId: string;
  body: {
    metadata?: string[] | null;
    resources: object[];
    message?: string | null;
    conversations?: SlackConversation[] | null;
  };
};
export type GetUsersMeSlackIntegrationsByIntegrationIdConversationsApiResponse =
  /** status 200 OK */ SlackConversation[];
export type GetUsersMeSlackIntegrationsByIntegrationIdConversationsApiArg = {
  integrationId: string;
  search?: string;
};
export type PostUsersMeSlackInstallationAttemptsApiResponse =
  /** status 201 Created */ SlackAppInstallationAttempt;
export type PostUsersMeSlackInstallationAttemptsApiArg = void;
export type PostUsersMeEmailsMessageApiResponse = /** status 201 Sent */ void;
export type PostUsersMeEmailsMessageApiArg = {
  body: {
    account_id: string;
    emails: Emails;
    resources: object[];
    message?: MessageWithSubject;
  };
};
export type PostV2UsersMeEmailsMessageApiResponse = /** status 201 Sent */ void;
export type PostV2UsersMeEmailsMessageApiArg = {
  body: {
    account_id: string;
    emails: Emails;
    resources: ResourceWithVisibility[][];
    message?: MessageWithSubject;
  };
};
export type GetUsersMeAccountsResourceTypesByResourceTypeInstanceTypesApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeAccountsResourceTypesByResourceTypeInstanceTypesApiArg = {
  resourceType: "ec2" | "vm" | "az_vm" | "dax_cluster";
};
export type GetUsersMeAccountsResourceTypesByResourceTypeInstanceFamiliesApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeAccountsResourceTypesByResourceTypeInstanceFamiliesApiArg =
  {
    resourceType: "ec2" | "vm" | "az_vm";
  };
export type PostUsersMeCurrentPreferenceApiResponse =
  /** status 200 OK */ UserPreference;
export type PostUsersMeCurrentPreferenceApiArg = {
  body: {
    key: string;
    value?: string | null;
  };
};
export type GetUsersMeCurrentPreferencesApiResponse =
  /** status 200 OK */ UserPreference[];
export type GetUsersMeCurrentPreferencesApiArg = void;
export type PatchUsersMeCurrentPreferenceByKeyApiResponse =
  /** status 200 OK */ UserPreference;
export type PatchUsersMeCurrentPreferenceByKeyApiArg = {
  key: string;
  body: {
    value?: string | null;
  };
};
export type GetUsersMeCurrentPreferenceByKeyApiResponse =
  /** status 200 OK */ UserPreference;
export type GetUsersMeCurrentPreferenceByKeyApiArg = {
  key: string;
};
export type GetUsersMeCurrentDigestPreferencesApiResponse =
  /** status 200 OK */ UserDigestPreferences;
export type GetUsersMeCurrentDigestPreferencesApiArg = void;
export type PatchUsersMeCurrentDigestPreferencesApiResponse =
  /** status 200 OK */ UserDigestPreferences;
export type PatchUsersMeCurrentDigestPreferencesApiArg = {
  body: {
    monthly_digest?: boolean | null;
    weekly_digest?: boolean | null;
  };
};
export type DeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdApiArg = {
  "budget-id": string;
};
export type PatchUsersMeOrganisationsCurrentBudgetsByBudgetIdApiResponse =
  /** status 200 updated */ Budget;
export type PatchUsersMeOrganisationsCurrentBudgetsByBudgetIdApiArg = {
  "budget-id": string;
  body: {
    name?: string;
    integrations?: NestedIntegration[] | null;
    notifications?:
      | (
          | NotificationEmail
          | NotificationSlack
          | NotificationWebhook
          | NotificationJira
        )[]
      | null;
    email_to?: Emails | (any | null);
    view_id?: string;
    thresholds?: Threshold[];
    amount?: number;
  };
};
export type GetUsersMeOrganisationsCurrentBudgetsByBudgetIdApiResponse =
  /** status 200 get budget */ Budget;
export type GetUsersMeOrganisationsCurrentBudgetsByBudgetIdApiArg = {
  "budget-id": string;
};
export type GetUsersMeOrganisationsCurrentBudgetsApiResponse =
  /** status 200 OK */ Budget[];
export type GetUsersMeOrganisationsCurrentBudgetsApiArg = void;
export type GetUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiResponse =
  /** status 200 Views budget */ Budget;
export type GetUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiArg = {
  "view-id": string;
};
export type PostUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiResponse =
  /** status 201 Created */ Budget;
export type PostUsersMeOrganisationsCurrentViewsByViewIdBudgetsApiArg = {
  "view-id": string;
  body: {
    name: string;
    start_date: string;
    period: BudgetPeriod;
    integrations?: NestedIntegration[] | null;
    notifications?:
      | (
          | NotificationEmail
          | NotificationSlack
          | NotificationWebhook
          | NotificationJira
        )[]
      | null;
    thresholds: Threshold[];
    amount: number;
    email_to?: Emails | (any | null);
  };
};
export type GetUsersMeOrganisationsCurrentProviderOrganisationsApiResponse =
  /** status 200 Ok */ ProviderOrganisation[];
export type GetUsersMeOrganisationsCurrentProviderOrganisationsApiArg = void;
export type GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse =
  /** status 200 Ok */ ResourceExplorer;
export type GetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg =
  {
    resourceExplorerViewId: string;
  };
export type PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse =
  /** status 200 OK */ ResourceExplorerWidget;
export type PostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg =
  {
    resourceExplorerViewId: string;
    resourceExplorerDataOverrideFilters: ResourceExplorerDataOverrideFilters;
  };
export type PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse =
  /** status 200 OK */ ResourceExplorer;
export type PatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg =
  {
    resourceExplorerViewId: string;
    body: {
      name?: string | null;
      filters?: ResourceExplorerFilterItem[] | null;
      cloud_providers?: ProviderType[] | null;
      data?: ResourceExplorerData;
    };
  };
export type DeleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg =
  {
    resourceExplorerViewId: string;
  };
export type DeleteV2UsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteV2UsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdApiArg =
  {
    resourceExplorerViewId: string;
  };
export type PatchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiResponse =
  /** status 200 OK */ ResourceExplorerFolder;
export type PatchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiArg =
  {
    resourceExplorerFolderId: string;
    body: {
      name?: string;
    };
  };
export type DeleteUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiArg =
  {
    resourceExplorerFolderId: string;
  };
export type PatchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiResponse =
  /** status 200 OK */ ReFolderV2;
export type PatchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiArg =
  {
    resourceExplorerFolderId: string;
    body: {
      name?: string;
    };
  };
export type DeleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdApiArg =
  {
    resourceExplorerFolderId: string;
  };
export type PatchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2ApiResponse =
  /** status 200 OK */ FilterSet;
export type PatchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2ApiArg = {
  filterSetId: string;
  body: {
    name?: string | null;
    filters?: AccountResourceFilter[] | null;
    cloud_provider: ProviderType;
    priority_account_id?: string | null;
    account_ids?: string[];
  };
};
export type GetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsApiResponse =
  /** status 200 Possible widgets */ {
    billing_statuses: {
      provider: ProviderType;
      billing_collect_status: BillingCollectionStatus;
    }[];
    widgets: {
      type: WidgetType;
      cause_of_disabled_state: (any | null) | WidgetStateReason;
    }[];
  };
export type GetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsApiArg = {
  /** Content-type */
  "Content-Type"?: string;
};
export type PostUsersMeOrganisationsCurrentDashboardsApiResponse =
  /** status 201 Dashboard Details */ {
    id: string;
    name: string;
    created_at: Date;
    layout?: LayoutNullable;
    visibility?: "visible_to_everyone" | "visible_only_to_me";
    widgets: (
      | DashboardWidgetCostsByCategory
      | DashboardWidgetResourceExplorer
      | DashboardWidgetAccountsLiveUsage
      | DashboardWidgetCostsByCategoryV2
      | DashboardWidgetCostBreakdown
      | DashboardWidgetCostAndUsageSummary
      | DashboardWidgetLargestCostChanges
    )[];
  };
export type PostUsersMeOrganisationsCurrentDashboardsApiArg = {
  body: {
    name: string;
  };
};
export type GetUsersMeOrganisationsCurrentDashboardsApiResponse =
  /** status 200 OK */ Dashboard[];
export type GetUsersMeOrganisationsCurrentDashboardsApiArg = {
  /** Content-type */
  "Content-Type"?: string;
};
export type PostV2UsersMeOrganisationsCurrentDashboardsApiResponse =
  /** status 201 Dashboard Details */ {
    id: string;
    name: string;
    created_at: Date;
    layout?: LayoutNullable;
    widgets: (
      | DashboardWidgetCostsByCategory
      | DashboardWidgetResourceExplorer
      | DashboardWidgetAccountsLiveUsage
      | DashboardWidgetCostsByCategoryV2
      | DashboardWidgetCostBreakdown
      | DashboardWidgetCostAndUsageSummary
      | DashboardWidgetLargestCostChanges
    )[];
  };
export type PostV2UsersMeOrganisationsCurrentDashboardsApiArg = {
  body: {
    name: string;
    visibility: "visible_only_to_me" | "visible_to_everyone";
  };
};
export type PostUsersMeOrganisationsCurrentDashboardFoldersApiResponse =
  /** status 201 Created */ DashboardFolder;
export type PostUsersMeOrganisationsCurrentDashboardFoldersApiArg = {
  body: {
    name: string;
  };
};
export type PostV2UsersMeOrganisationsCurrentDashboardFoldersApiResponse =
  /** status 201 Created */ DashboardFolder;
export type PostV2UsersMeOrganisationsCurrentDashboardFoldersApiArg = {
  body: {
    name: string;
    visibility: ("visible_only_to_me" | "visible_to_everyone") | null;
  };
};
export type PatchUsersMeOrganisationsCurrentDashboardFoldersByFolderIdApiResponse =
  /** status 200 OK */ DashboardFolder;
export type PatchUsersMeOrganisationsCurrentDashboardFoldersByFolderIdApiArg = {
  folderId: string;
  body: {
    name?: string;
  };
};
export type DeleteUsersMeOrganisationsCurrentDashboardFoldersByFolderIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteUsersMeOrganisationsCurrentDashboardFoldersByFolderIdApiArg =
  {
    folderId: string;
  };
export type DeleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderIdApiResponse =
  /** status 204 No Content */ void;
export type DeleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderIdApiArg =
  {
    folderId: string;
  };
export type PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse =
  /** status 202 Dashboard Details */ {
    id: string;
    name: string;
    created_at: Date;
    layout?: LayoutNullable;
    widgets: (
      | DashboardWidgetCostsByCategory
      | DashboardWidgetResourceExplorer
      | DashboardWidgetAccountsLiveUsage
      | DashboardWidgetCostsByCategoryV2
      | DashboardWidgetCostBreakdown
      | DashboardWidgetCostAndUsageSummary
      | DashboardWidgetLargestCostChanges
    )[];
  };
export type PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg = {
  dashboardId: string;
  body: {
    name: string | null;
    layout?: LayoutNullable;
  };
};
export type DeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type DeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg = {
  dashboardId: string;
};
export type GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse =
  /** status 200 Dashboard Details */ {
    id: string;
    name: string;
    created_at: Date;
    layout?: LayoutNullable;
    widgets: (
      | DashboardWidgetCostsByCategory
      | DashboardWidgetResourceExplorer
      | DashboardWidgetAccountsLiveUsage
      | DashboardWidgetCostsByCategoryV2
      | DashboardWidgetCostBreakdown
      | DashboardWidgetCostAndUsageSummary
      | DashboardWidgetLargestCostChanges
    )[];
    visibility: "visible_to_everyone" | "visible_only_to_me";
  };
export type GetUsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg = {
  dashboardId: string;
};
export type DeleteV2UsersMeOrganisationsCurrentDashboardsByDashboardIdApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type DeleteV2UsersMeOrganisationsCurrentDashboardsByDashboardIdApiArg = {
  dashboardId: string;
};
export type PostUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsApiResponse =
  /** status 201 Created */
  | DashboardWidgetCostsByCategory
  | DashboardWidgetResourceExplorer
  | DashboardWidgetAccountsLiveUsage
  | DashboardWidgetCostsByCategoryV2
  | DashboardWidgetCostBreakdown
  | DashboardWidgetCostAndUsageSummary
  | DashboardWidgetLargestCostChanges;
export type PostUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsApiArg =
  {
    dashboardId: string;
    body: {
      widget:
        | DashboardWidgetCostsByCategory2
        | DashboardWidgetResourceExplorer2
        | DashboardWidgetAccountsLiveUsage2
        | DashboardWidgetCostsByCategoryV2Request
        | DashboardWidgetCostBreakdownRequest
        | DashboardWidgetCostAndUsageSummaryRequest
        | DashboardWidgetLargestCostChangesRequest;
    };
  };
export type PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiResponse =
  /** status 202 Accepted */
  | DashboardWidgetCostsByCategory
  | DashboardWidgetResourceExplorer
  | DashboardWidgetAccountsLiveUsage
  | DashboardWidgetCostsByCategoryV2
  | DashboardWidgetCostBreakdown
  | DashboardWidgetCostAndUsageSummary
  | DashboardWidgetLargestCostChanges;
export type PatchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiArg =
  {
    dashboardId: string;
    widgetId: string;
    body: {
      widget:
        | DashboardWidgetAccountsLiveUsagePatchRequest
        | DashboardWidgetCostsByCategoryV2PatchRequest
        | DashboardWidgetCostBreakdownPatchRequest
        | DashboardWidgetCostAndUsageSummaryPatchRequest
        | DashboardWidgetLargestCostChangesPatchRequest;
    };
  };
export type DeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type DeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiArg =
  {
    dashboardId: string;
    widgetId: string;
  };
export type PutUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiResponse =
  /** status 202 Accepted */
  | DashboardWidgetCostsByCategory
  | DashboardWidgetResourceExplorer
  | DashboardWidgetAccountsLiveUsage
  | DashboardWidgetCostsByCategoryV2
  | DashboardWidgetCostBreakdown
  | DashboardWidgetCostAndUsageSummary
  | DashboardWidgetLargestCostChanges;
export type PutUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdApiArg =
  {
    dashboardId: string;
    widgetId: string;
    body: {
      widget:
        | UpdateDashboardWidgetAccountsLiveUsage
        | UpdateDashboardWidgetCostsByCategoryV2Request
        | UpdateDashboardWidgetCostBreakdownRequest
        | UpdateDashboardWidgetCostAndUsageSummaryRequest
        | UpdateDashboardWidgetLargestCostChangesRequest;
    };
  };
export type GetUsersMeOrganisationsCurrentDashboardHierarchyApiResponse =
  /** status 200 Ok */ (Dashboard | DashboardFolder)[];
export type GetUsersMeOrganisationsCurrentDashboardHierarchyApiArg = {
  /** Content-type */
  "Content-Type"?: string;
};
export type PatchUsersMeOrganisationsCurrentDashboardHierarchyApiResponse =
  /** status 200 Ok */ (Dashboard | DashboardFolder)[];
export type PatchUsersMeOrganisationsCurrentDashboardHierarchyApiArg = {
  body: {
    id: string;
    parent_id?: string;
    index?: number;
  };
};
export type GetV2UsersMeOrganisationsCurrentDashboardsHierarchyApiResponse =
  /** status 200 Ok */ {
    visible_only_to_me?: (Dashboard | DashboardFolder)[];
    visible_to_everyone?: (Dashboard | DashboardFolder)[];
  };
export type GetV2UsersMeOrganisationsCurrentDashboardsHierarchyApiArg = {
  /** Content-type */
  "Content-Type"?: string;
};
export type PatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleOnlyToMeApiResponse =
  /** status 200 Ok */ (Dashboard | DashboardFolder)[];
export type PatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleOnlyToMeApiArg =
  {
    body: {
      id: string;
      parent_id?: string;
      index?: number;
    };
  };
export type PatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleToEveryoneApiResponse =
  /** status 200 Ok */ (Dashboard | DashboardFolder)[];
export type PatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleToEveryoneApiArg =
  {
    body: {
      id: string;
      parent_id?: string;
      index?: number;
    };
  };
export type GetUsersMeOrganisationsCurrentCategoriesWidgetApiResponse =
  /** status 200 Example response */ {
    name: string;
    values: {
      value: string;
      data_points: {
        date: Date;
        total_cost: number | null;
        filtered_resource_costs: number | null;
      }[];
    }[];
  };
export type GetUsersMeOrganisationsCurrentCategoriesWidgetApiArg = {
  name: string;
  dateLabel: DateLabel;
};
export type PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiResponse =
  /** status 200 CostByCategory Widget Response */ {
    total_cost_details: {
      total_cost: number;
      trend: number | null;
    };
    average_daily_cost_details: {
      average_daily_cost: number | null;
      trend: number | null;
    };
    average_monthly_cost_details: {
      average_monthly_cost: number | null;
      trend: number | null;
    };
    forecasted_costs: ForecastedCostRe;
    total_filtered: number;
    live_filtered: number;
    categories_data: {
      category_id: string;
      category: string;
      total_cost: number;
      total_filtered: number;
      live_filtered: number;
      average_daily_cost_details: {
        from_date: Date;
        to_date: Date;
        average_daily_cost: number | null;
        trend: number | null;
      };
      average_monthly_cost_details: {
        from_date: Date;
        to_date: Date;
        average_monthly_cost: number | null;
        trend: number | null;
      };
      accounts: AccountLight[];
      data_points: {
        date: Date;
        cost?: number | null;
        filtered_resources_cost?: number | null;
      }[];
      forecasted_costs: ForecastedCostRe;
    }[];
  };
export type PostUsersMeOrganisationsCurrentWidgetsCostByCategoryApiArg = {
  body: {
    category_ids: string[];
    date_from: Date;
    date_to: Date;
    date_granularity: DateDataPoint;
    dates?: Dates;
    display_filtered_resources?: boolean;
  };
};
export type GetUsersMeOrganisationsCurrentWidgetsCostBreakdownApiResponse =
  /** status 200 Cost Breakdown Widget Response */ {
    data: ResourceExplorerChartData[];
    total: ResourceExplorerGridTotal[];
    total_cost: number;
    previous_period_total_cost: number;
    average_daily_cost_details: AverageDailyCostDetails;
    average_monthly_cost_details: AverageMonthlyCostDetails;
    live_usage_total_cost: number;
    total_cost_date_details: ResourceExplorerCostsDateDetails;
    previous_period_cost_date_details: ResourceExplorerCostsDateDetails;
    forecasted_costs: ForecastedCostRe;
    trend_type?: TrendType;
  };
export type GetUsersMeOrganisationsCurrentWidgetsCostBreakdownApiArg = {
  viewId: string;
  dateLabel?: DateLabelNullable;
  dateFrom?: NullableDate;
  dateTo?: NullableDate;
  dateType?: DateType;
  dateValue?: number | null;
  dateUnit?: DateUnit;
  dataPoint?: DateDataPoint;
  grouping?: ResourceExplorerGrouping;
  groupValues?: string[];
  forecastOption: ForecastOption;
  chartType: ChartType;
  trendType?: TrendType;
};
export type GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiResponse =
  /** status 200 Cost Breakdown Widget Response */ {
    data: ResourceExplorerChartData[];
    total: ResourceExplorerGridTotal[];
    total_cost: number;
    previous_period_total_cost: number;
    average_daily_cost_details: AverageDailyCostDetails;
    average_monthly_cost_details: AverageMonthlyCostDetails;
    live_usage_total_cost: number;
    total_cost_date_details: ResourceExplorerCostsDateDetails;
    previous_period_cost_date_details: ResourceExplorerCostsDateDetails;
    forecasted_costs: ForecastedCostRe;
    trend_type?: TrendType;
  };
export type GetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdApiArg =
  {
    widgetId: string;
  };
export type GetUsersMeRdsStorageTypesApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeRdsStorageTypesApiArg = void;
export type PostUsersMeRdsSnapshotSourceNamesApiResponse =
  /** status 200 OK */ string[];
export type PostUsersMeRdsSnapshotSourceNamesApiArg = {
  body: {
    account_ids: string[];
  };
};
export type GetUsersMeRdsSnapshotTypesApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeRdsSnapshotTypesApiArg = void;
export type GetUsersMeEbsSnapshotStatusesApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeEbsSnapshotStatusesApiArg = void;
export type PostUsersMeEbsSnapshotVolumeIdsApiResponse =
  /** status 200 OK */ string[];
export type PostUsersMeEbsSnapshotVolumeIdsApiArg = {
  body: {
    account_ids: string[];
  };
};
export type GetUsersMeDynamoDbTableStatusesApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeDynamoDbTableStatusesApiArg = void;
export type GetUsersMeDynamoDbTableCapacityModesApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeDynamoDbTableCapacityModesApiArg = void;
export type GetUsersMeNatGatewayIpAddressesApiResponse =
  /** status 200 OK */ IpAddresses[];
export type GetUsersMeNatGatewayIpAddressesApiArg = void;
export type GetUsersMeNatGatewayVpcApiResponse = /** status 200 OK */ string[];
export type GetUsersMeNatGatewayVpcApiArg = void;
export type GetUsersMeEcsWorkloadCapacityProvidersApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeEcsWorkloadCapacityProvidersApiArg = void;
export type GetUsersMeOrganisationsCurrentResourceExplorerViewsByViewIdWidgetApiResponse =
  /** status 200 OK */ ResourceExplorerWidget;
export type GetUsersMeOrganisationsCurrentResourceExplorerViewsByViewIdWidgetApiArg =
  {
    viewId: string;
  };
export type GetUsersMeExecutionLogsSourcesApiResponse =
  /** status 200 OK */ CommandLogSource[];
export type GetUsersMeExecutionLogsSourcesApiArg = void;
export type PostUsersMeOrganisationsCurrentMinimumAvailableDateApiResponse =
  /** status 200 OK */ MinimumAvailableDate;
export type PostUsersMeOrganisationsCurrentMinimumAvailableDateApiArg = {
  body: {
    cloud_providers: ProviderType[];
  };
};
export type PatchUsersMeRightSizingRecommendationsApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type PatchUsersMeRightSizingRecommendationsApiArg = {
  body: {
    recommendation_ids: string[];
    dismiss_period: DismissPeriod;
  };
};
export type PatchUsersMeDailyOffHoursRecommendationsApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type PatchUsersMeDailyOffHoursRecommendationsApiArg = {
  body: {
    recommendation_ids: string[];
    dismiss_period: DismissPeriod;
  };
};
export type PatchUsersMeWeeklyOffHoursRecommendationsApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type PatchUsersMeWeeklyOffHoursRecommendationsApiArg = {
  body: {
    recommendation_ids: string[];
    dismiss_period: DismissPeriod;
  };
};
export type PatchUsersMeResourcesRightSizingRecommendationsApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type PatchUsersMeResourcesRightSizingRecommendationsApiArg = {
  body: {
    resource_identifier: string;
    account_id: string;
  };
};
export type PatchUsersMeResourcesDailyOffHoursRecommendationsApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type PatchUsersMeResourcesDailyOffHoursRecommendationsApiArg = {
  body: {
    resource_identifier: string;
    account_id: string;
  };
};
export type PatchUsersMeResourcesWeeklyOffHoursRecommendationsApiResponse =
  /** status 204 Example response */ {
    message?: string;
  };
export type PatchUsersMeResourcesWeeklyOffHoursRecommendationsApiArg = {
  body: {
    resource_identifier: string;
    account_id: string;
  };
};
export type GetUsersMeOrganisationsCurrentDismissedResourcesApiResponse =
  /** status 200 Response for getting dismissed Resources */ {
    id: string;
    account?: EmbeddedAccount;
    name: string | null;
    provider_unique_identifier: string;
    type: string;
    dismissed_by: string | null;
    resource_identifier: string;
    tags: Tag[];
    resource_type: ResourceType;
  }[];
export type GetUsersMeOrganisationsCurrentDismissedResourcesApiArg = {
  /** Resource group */
  group?: "compute" | "database";
  /** Recommendation type */
  recommendationType?: "off_hours" | "right_sizing";
};
export type PatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursApiResponse =
  /** status 200 Example response */ {
    message?: string;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursApiArg =
  {
    restoreRecommendationRequest: RestoreRecommendationRequest;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursApiResponse =
  /** status 200 Example response */ {
    message?: string;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursApiArg =
  {
    restoreRecommendationRequest: RestoreRecommendationRequest;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursByResourceApiResponse =
  /** status 200 Example response */ {
    message?: string;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursByResourceApiArg =
  {
    restoreRecommendationByResourceRequest: RestoreRecommendationByResourceRequest;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursByResourceApiResponse =
  /** status 200 Example response */ {
    message?: string;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursByResourceApiArg =
  {
    restoreRecommendationByResourceRequest: RestoreRecommendationByResourceRequest;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsRightSizingApiResponse =
  /** status 200 Example response */ {
    message?: string;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsRightSizingApiArg = {
  restoreRecommendationRequest: RestoreRecommendationRequest;
};
export type PatchUsersMeOrganisationsCurrentRecommendationsRightSizingByResourceApiResponse =
  /** status 200 Example response */ {
    message?: string;
  };
export type PatchUsersMeOrganisationsCurrentRecommendationsRightSizingByResourceApiArg =
  {
    restoreRecommendationByResourceRequest: RestoreRecommendationByResourceRequest;
  };
export type GetUsersMeOrganisationsCurrentAvailableEmailsApiResponse =
  /** status 200 OK */ string[];
export type GetUsersMeOrganisationsCurrentAvailableEmailsApiArg = void;
export type GetUsersMeOrganisationsCurrentProvidersByProviderTagKeysApiResponse =
  /** status 200 OK */ TagKey[];
export type GetUsersMeOrganisationsCurrentProvidersByProviderTagKeysApiArg = {
  provider: "aws" | "gcp" | "azure";
};
export type PatchUsersMeOrganisationsCurrentProvidersByProviderTagKeysApiResponse =
  /** status 200 OK */ TagKey[];
export type PatchUsersMeOrganisationsCurrentProvidersByProviderTagKeysApiArg = {
  provider: "aws" | "gcp" | "azure";
  body: {
    key?: string;
  };
};
export type UserRole =
  | "owner"
  | "super_admin"
  | "admin"
  | "viewer"
  | "billing_admin"
  | "workflow_resources_protector"
  | "viewer_protector";
export type Date = string;
export type NullableDate = string | null;
export type UserStatus =
  | "active"
  | "pending"
  | "deleted"
  | "awaiting_invitation"
  | "in_review";
export type User = {
  /** User identifier */
  id: string;
  role: UserRole;
  /** The customer name */
  name: string | null;
  email: string | null;
  company_name: string | null;
  position: string | null;
  invited_by: {
    id: string;
    role: UserRole;
    name?: string | null;
  } | null;
  created_at: Date;
  last_time_logged_in_at?: NullableDate;
  use_mfa?: boolean;
  status?: UserStatus;
};
export type ProviderType = "aws" | "gcp" | "azure";
export type RecommendationState =
  | "enabled"
  | "disabled"
  | "not_detected"
  | "missing_permission";
export type AccountRecommendationState = {
  account_id: string;
  provider: ProviderType;
  status: RecommendationState;
};
export type AccountAttemptId = string;
export type ProviderAccessType = "read" | "read_write";
export type AccountAttemptStatus =
  | "started"
  | "confirmed"
  | "failed"
  | "closed";
export type AccountAttemptStatusDetailsAwsStarted = {
  cli_command: string;
  console_link: string;
  terraform: string;
};
export type AccountAttemptStatusDetailsGcpStarted = {
  service_account: string;
};
export type AccountAttemptStatusDetailsAzureStarted = {
  service_principal: string;
  tenant_id?: string | null;
};
export type AccountAttemptStatusDetailsStarted =
  | (
      | AccountAttemptStatusDetailsAwsStarted
      | AccountAttemptStatusDetailsGcpStarted
      | AccountAttemptStatusDetailsAzureStarted
    )
  | null;
export type AccountAttemptStatusDetailsFailed = {
  /** The fail reason will be null, if status is not failed */
  reason: string;
} | null;
export type AccountAttemptStatusDetailsClosed = {
  /** The fail reason will be null, if status is not failed */
  account_id: string;
} | null;
export type AccountAttempt = {
  id: AccountAttemptId;
  type: ProviderType;
  access_type: ProviderAccessType;
  user_id: string;
  status: AccountAttemptStatus;
  status_details: {
    started?: AccountAttemptStatusDetailsStarted;
    failed?: AccountAttemptStatusDetailsFailed;
    closed?: AccountAttemptStatusDetailsClosed;
  } | null;
  account_id: string | null;
  created_at: Date;
};
export type AccountId = string;
export type AccountStatus =
  | "initial_pulling"
  | "connected"
  | "failed"
  | "deleted"
  | "deleting"
  | "deactivated"
  | "connected_for_billing"
  | "inactive";
export type ProviderAccountId = string | null;
export type AccountAwsProviderDetails = {
  aws?: {
    is_root?: boolean;
    organisation_id?: string | null;
  };
};
export type AccountGcpProviderDetails = {
  gcp?: {
    project_id: string | null;
    service_account: string;
  };
};
export type AccountAzureProviderDetails = {
  azure?: {
    subscription_id: string;
    subscription_name: string;
    service_principal: string;
    tenant_id: string;
  };
};
export type Billing = {
  last_month_total_cost: number;
  last_month_total_cost_start_date: Date;
  last_month_total_cost_end_date: Date;
  last_month_trend: number;
};
export type PermissionType = "api" | "role" | "iam_permission" | "permission";
export type AccountMissingPermissions = {
  /** name of permission */
  name: string;
  /** title of permission */
  title: string | null;
  /** url of permission */
  url: string;
  /** type of permission */
  type: PermissionType;
}[];
export type Account = {
  id: AccountId;
  type: ProviderType;
  access_type: ProviderAccessType;
  status: AccountStatus;
  provider_account_id: ProviderAccountId;
  provider_account_name?: string | null;
  /** Provoder organisation id */
  provider_organisation_account_id: string | null;
  /** Whether the account is root or not */
  is_provider_root_account: boolean;
  is_sub_account: boolean;
  /** Account parent id */
  parent_id: string | null;
  /** Account parent id */
  user_id: string;
  created_at: Date;
  outdated: boolean;
  last_resource_updated_date: NullableDate;
  ems: number;
  total_monthly_saved_costs: number;
  total_costs: number;
  permission_failed_for_all_resources: boolean;
  provider_details:
    | AccountAwsProviderDetails
    | AccountGcpProviderDetails
    | AccountAzureProviderDetails;
  is_billing_collection_in_progress: boolean;
  has_satisfied_permissions: boolean;
  last_month_total_cost: number | null;
  billing: Billing;
  group_id?: string | null;
  /** Applied filter set id */
  filter_set_id: string | null;
  missing_permissions: AccountMissingPermissions;
};
export type Accounts = Account[] | null;
export type ResourceType =
  | "ec2"
  | "ebs"
  | "elb"
  | "eip"
  | "rds"
  | "vm"
  | "disk"
  | "lb"
  | "ip"
  | "sql"
  | "elc"
  | "az_disk"
  | "az_ip"
  | "az_lb"
  | "az_vm"
  | "az_sql"
  | "s3"
  | "asg"
  | "mig"
  | "mig_vm"
  | "asg_ec2"
  | "eks_ec2"
  | "eks"
  | "node_group"
  | "az_vmss"
  | "az_vmss_vm"
  | "gke"
  | "node_pool"
  | "gke_vm"
  | "az_aks"
  | "az_node_pool"
  | "az_aks_vm"
  | "rds_snapshot"
  | "ebs_snapshot"
  | "rds_snapshot_source"
  | "dynamo_db_table"
  | "dynamo_db_backup"
  | "dax_cluster"
  | "nat_gateway"
  | "ecs"
  | "ecs_workload";
export type FilterKeyType =
  | "launch_time"
  | "cpu"
  | "network_in"
  | "network_out"
  | "tag"
  | "attachments"
  | "volume_age"
  | "database_connections"
  | "instances"
  | "instance_ids"
  | "association_ids"
  | "region"
  | "in_use"
  | "total_connections"
  | "cloudchipr_ttl"
  | "provider_unique_identifier"
  | "name"
  | "is_public"
  | "size"
  | "creation_date"
  | "creator"
  | "unused_since"
  | "issues"
  | "resource_group"
  | "instance_type"
  | "storage_type"
  | "state"
  | "instance_family"
  | "type"
  | "snapshot_id"
  | "source_name"
  | "volume_id"
  | "storage_tier"
  | "snapshot_status"
  | "billable"
  | "requests"
  | "label"
  | "read_usage"
  | "write_usage"
  | "rcu_reservation"
  | "wcu_reservation"
  | "status"
  | "capacity_mode"
  | "table"
  | "cpu_reservation"
  | "memory_reservation"
  | "memory"
  | "nodes_count"
  | "node_type"
  | "protected_by"
  | "ip_addresses"
  | "vpc"
  | "nat_gateway_id"
  | "processed_data"
  | "registered_container_instances"
  | "start_date"
  | "storage"
  | "capacity_provider"
  | "task_count"
  | "ecs_workload_pid"
  | "resource_unique_identifier"
  | "memory_available";
export type Tag = {
  key: string;
  value: string;
};
export type FilterOperatorType =
  | "equal"
  | "not_equal"
  | "greater_than"
  | "greater_than_equal_to"
  | "less_than"
  | "less_than_equal_to"
  | "exists"
  | "does_not_exist"
  | "contains"
  | "does_not_contain"
  | "is_empty"
  | "is_not_empty"
  | "is_absent"
  | "is_not_absent"
  | "in"
  | "is_true"
  | "is_false"
  | "is_expired"
  | "is_invalid"
  | "does_never_expire"
  | "not_in"
  | "is_running"
  | "is_stopped"
  | "no_data"
  | "is_available"
  | "is_deleted";
export type FilterStatisticsType = "sum" | "maximum" | "minimum" | "average";
export type Unit = "hours" | "days";
export type Since = {
  value?: number;
  unit?: Unit;
};
export type MetricOfFilterItem = {
  statistics_type?: FilterStatisticsType;
  since?: Since;
};
export type FilterItem = {
  key: FilterKeyType;
  value?: (number | null) | (string | null) | Tag | (string[] | null);
  operator: FilterOperatorType;
  metric?: MetricOfFilterItem;
};
export type ExecutionLogAction = "terminate" | "stop" | "start";
export type ResourceFilter = {
  type: ResourceType;
  filters: FilterItem[];
  or_filters?: FilterItem[][];
  action?: ExecutionLogAction;
  snapshot?: boolean;
};
export type FilterItemWithType = {
  type: ResourceType;
  filter: FilterItem;
};
export type FilterGroup = (number | FilterGroup)[];
export type ResourceFilters = {
  type: ResourceType;
  filter_items: FilterItemWithType[];
  filter_groups: (number | FilterGroup)[];
  operators: ("AND" | "OR")[];
};
export type AccountCost = {
  timestamp: Date;
  type: string;
  cost: number;
  ems: number;
  default_ems: number;
};
export type ProviderBillingStatus = {
  is_initial_billing_collection_in_progress: boolean | null;
  is_billing_collection_in_progress: boolean;
  billing_collection_problems:
    | {
        account_name?: string;
        problems?: string[];
      }[]
    | null;
};
export type BillingCollectionStatus =
  | "new"
  | "missing_setup"
  | "waiting_for_cloud_provider"
  | "collecting"
  | "analysing"
  | "error"
  | "missing_data"
  | "active";
export type BillingAccountStatus = {
  account_id?: string;
  status?: BillingCollectionStatus;
  name?: string | null;
  provider_account_id?: string;
  problem_message?: string | null;
};
export type OrganisationBillingStatus = {
  organisation_id?: string;
  cloud_provider?: ProviderType;
  status?: BillingCollectionStatus;
  billing_account_statuses?: BillingAccountStatus[];
};
export type OrganisationBillingCurrent = {
  compute: number;
  storage: number;
  databases: number;
  networking: number;
  containers: number;
};
export type EmbeddedAccount = {
  id: string;
  provider: ProviderType;
  provider_access_type?: ProviderAccessType;
  provider_account_id: string;
  provider_account_name: string;
  is_provider_root_account?: boolean;
};
export type Tags = Tag[];
export type OrganisationDailyOffHoursRecommendation = {
  id: string;
  resource_id: string;
  account_id: string;
  account: EmbeddedAccount;
  from_hour: number;
  to_hour: number;
  number_of_off_hours: number;
  provider_unique_identifier: string;
  resource_name?: string | null;
  tags?: Tags;
  potential_saving: number;
  region: string;
  resource_type: ResourceType;
  type: string;
  dismiss_until_date: string | null;
  is_forever_dismissed: boolean;
  dismissed_by: string | null;
  is_dismissed_by_resource: boolean;
  resource_identifier: string;
};
export type OrganisationWeeklyOffHoursRecommendation = {
  id: string;
  resource_id: string;
  account_id: string;
  account: EmbeddedAccount;
  provider_unique_identifier: string;
  resource_name?: string | null;
  tags?: Tags;
  potential_saving: number;
  region: string;
  resource_type: ResourceType;
  from_hour: number;
  to_hour: number;
  from_day:
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";
  to_day:
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";
  number_of_off_hours: number;
  type: string;
  dismiss_until_date: string | null;
  is_forever_dismissed: boolean;
  dismissed_by: string | null;
  is_dismissed_by_resource: boolean;
  resource_identifier: string;
};
export type AccountLight = {
  id: string;
  provider: ProviderType;
  provider_account_id: string;
  provider_account_name: string;
  provider_access_type: ProviderAccessType;
  filter_set_id: string | null;
};
export type ResourceAction = "stop" | "start" | "terminate";
export type ExecutionLogV3 = {
  id: string;
  monthly_price: number | null;
  accounts: AccountLight[];
  response: {
    type: ResourceType;
    resources: {
      action: ResourceAction | (any | null);
      price_per_month: number | null;
      provider_unique_identifier: string;
      name: string;
      account: AccountLight;
      region: string;
      snapshot: boolean | null;
      tags: Tags | (any | null);
      log: string | null;
      succeded: boolean | null;
      state: ("succeed" | "failed" | "skipped") | null;
    }[];
  }[];
  created_at: Date;
  source: {
    source: ("manual" | "scheduler" | "workflow" | "off_hours") | null;
    metadata: {
      id: string | null;
      name: string | null;
    };
  };
};
export type ResourceState =
  | "stopping"
  | "running"
  | "stopped"
  | "stopped_allocated";
export type InlineResourceMetric = {
  daily: string;
  hourly: string;
};
export type ComputeInlineMetrics = {
  daily_start_point?: NullableDate;
  hourly_start_point?: NullableDate;
  cpu_max?: InlineResourceMetric;
  mem_used_percent_max?: InlineResourceMetric;
  mem_available_percent_min?: InlineResourceMetric;
};
export type ResourceCreationData = {
  creator?: string;
  timestamp?: Date;
} | null;
export type Recommendations = {
  right_sizing_recommendations: {
    id: string;
    potential_savings: number;
  }[];
  off_hours_recommendations: {
    id: string;
    potential_savings: number;
  }[];
};
export type ResourceIssues = {
  id: string;
  issue_provider_id: string;
  type: "jira";
  link: string;
  key: string;
  status: {
    description: string;
    name: string;
    color_name: string;
  };
}[];
export type UnusedSince = {
  is_used: boolean;
  timestamp?: NullableDate;
};
export type UpcomingAction = {
  schedule_id: string;
  schedule_name: string;
  schedule_type: string;
  action_date: Date;
} | null;
export type Ec2 = {
  id: string;
  resource_state: ResourceState | (any | null);
  image_id: string;
  instance_id: string;
  is_spot_instance: boolean;
  name?: string;
  type: string;
  inline_metrics?: ComputeInlineMetrics;
  cpu_value: number | null;
  network_in_value: number | null;
  network_out_value: number | null;
  created_at: NullableDate;
  price_per_month: number;
  availability_zone: string;
  region: string;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason?: string | null;
  provider_status: string;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data?: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  unused_since: UnusedSince;
  is_stopped: boolean;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type Asg = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  instances_count: number;
  desired_capacity: number;
  max_capacity: number;
  min_capacity: number;
  price_per_month: number;
  region: string;
  instances: Ec2[];
  created_at: NullableDate;
  resource_type: ResourceType;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredAsgEc2ResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Ec2;
  child_resource: any | null;
};
export type FilteredAsgEc2Resources = {
  resources: FilteredAsgEc2ResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredAsgResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Asg;
  child_resource: FilteredAsgEc2Resources | null;
};
export type FilteredAsgResources = {
  resources: FilteredAsgResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Ebs = {
  id: string;
  volume_id: string;
  size: number;
  state: string;
  type: string;
  price_per_month: number;
  availability_zone: string;
  created_at: NullableDate;
  region: string;
  has_attachments: boolean;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  recommendations?: Recommendations;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredEbsResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Ebs;
  child_resource: any | null;
};
export type FilteredEbsResources = {
  resources: FilteredEbsResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type StorageTier = "archive" | "standard" | "warm" | "cold";
export type Billable = "yes" | "no" | "analyzing";
export type EbsSnapshot = {
  id: string;
  provider_unique_identifier: string;
  snapshot_id: string;
  name: string;
  storage_tier: StorageTier;
  volume_size: string;
  status: string;
  volume_id: string;
  is_encrypted: boolean;
  progress: string;
  region: string;
  created_at: NullableDate;
  price_per_month: number;
  marked_for_deletion: boolean;
  cleanup_failure_reason: string | null;
  is_protected: boolean;
  is_successfully_archived: boolean;
  tags: Tags;
  resource_type: ResourceType;
  is_snapshot_used: boolean;
  used_by_image: string | null;
  total_siblings: number;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  billable: Billable;
  protected_by: string | null;
};
export type FilteredEbsSnapshotResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: EbsSnapshot;
  child_resource: any | null;
};
export type FilteredEbsSnapshotResourcesV2 = {
  resources: FilteredEbsSnapshotResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredEc2ResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Ec2;
  child_resource: any | null;
};
export type FilteredEc2Resources = {
  resources: FilteredEc2ResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Eip = {
  id: string;
  ip: string;
  price_per_month: number;
  region: string;
  tags: Tags;
  created_at: NullableDate;
  allocation_id: string | null;
  association_id: string | null;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  instance_id: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredEipResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Eip;
  child_resource: any | null;
};
export type FilteredEipResources = {
  resources: FilteredEipResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Label = {
  label_key: string;
  label_value: string;
};
export type EksEc2 = {
  id: string;
  provider_unique_identifier: string;
  instance_id: string;
  image_id: string;
  is_spot_instance: boolean;
  type: string;
  inline_metrics?: ComputeInlineMetrics;
  cpu_value: number | null;
  network_in_value: number | null;
  network_out_value: number | null;
  availability_zone: string;
  created_at: NullableDate;
  price_per_month: number;
  region: string;
  account_id: string;
  marked_for_deletion: boolean;
  cleanup_failure_reason: string | null;
  is_successfully_deleted: boolean;
  is_protected: boolean;
  creation_data: ResourceCreationData;
  tags: Tags;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  all_time_spent: number | null;
  is_stopped: boolean;
  unused_since: UnusedSince;
  resource_state: ResourceState | (any | null);
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type NodeGroup = {
  id: string;
  name: string;
  price_per_month: number;
  created_at: NullableDate;
  region: string;
  resource_type: ResourceType;
  account_id: string;
  provider_unique_identifier: string;
  cleanup_failure_reason: string | null;
  is_protected: boolean;
  instances_count: number;
  is_successfully_deleted: boolean;
  creation_data: ResourceCreationData;
  tags: Tags;
  labels: Label[];
  instances: EksEc2[];
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  issues?: ResourceIssues;
  protected_by: string | null;
};
export type Eks = {
  id: string;
  provider_unique_identifier: string;
  marked_for_deletion?: boolean;
  name: string;
  cluster_id: string;
  instances_count: number;
  node_count: number;
  price_per_month: number;
  created_at: NullableDate;
  region: string;
  account_id: string;
  cleanup_failure_reason: string | null;
  is_protected: boolean;
  is_successfully_deleted: boolean;
  creation_data: ResourceCreationData;
  tags: Tags;
  instances: NodeGroup[];
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredEksEc2ResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: EksEc2;
  child_resource: any | null;
};
export type FilteredEksEc2Resources = {
  resources: FilteredEksEc2ResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredNodeGroupResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: NodeGroup;
  child_resource: FilteredEksEc2Resources | null;
};
export type FilteredNodeGroupResources = {
  resources: FilteredNodeGroupResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredEksResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Eks;
  child_resource: FilteredNodeGroupResources | null;
};
export type FilteredEksResources = {
  resources: FilteredEksResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Elb = {
  id: string;
  type: string;
  load_balancer_name: string;
  load_balancer_arn: string | null;
  price_per_month: number;
  dns_name: string;
  created_at: NullableDate;
  region: string;
  has_attachments: boolean;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredElbResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Elb;
  child_resource: any | null;
};
export type FilteredElbResources = {
  resources: FilteredElbResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Metric = {
  timestamp: string;
  average: number;
  maximum: number;
  minimum: number;
  sum: number;
  unit: string;
};
export type Elc = {
  id: string;
  cluster_id: string;
  price_per_month: number;
  instance_type: string;
  cache_type: string;
  nodes_count: number;
  connections: Metric[];
  connections_by_hours?: Metric[];
  connections_value?: number | null;
  cpu: Metric[];
  cpu_by_hours?: Metric[];
  cpu_value?: number | null;
  created_at: NullableDate;
  region: string;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  issues?: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredElcResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Elc;
  child_resource: any | null;
};
export type FilteredElcResources = {
  resources: FilteredElcResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type DatabaseInlineMetrics = {
  daily_start_point?: NullableDate;
  hourly_start_point?: NullableDate;
  cpu_max?: InlineResourceMetric;
  total_connection_sum?: InlineResourceMetric;
};
export type Rds = {
  id: string;
  db_id: string;
  price_per_month: number;
  instance_type: string;
  storage_type: string;
  inline_metrics?: DatabaseInlineMetrics;
  database_connections: Metric[];
  connections: Metric[];
  connections_by_hours?: Metric[];
  connections_value?: number | null;
  cpu: Metric[];
  cpu_by_hours?: Metric[];
  cpu_value?: number | null;
  db_type: string;
  created_at: NullableDate;
  availability_zone: string;
  status?: string | null;
  resource_state: ResourceState | (any | null);
  provider_status: string;
  region: string;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  cluster_id?: string | null;
  nodes_count?: number | null;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  unused_since: UnusedSince;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredRdsResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Rds;
  child_resource: any | null;
};
export type FilteredRdsResources = {
  resources: FilteredRdsResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type RdsSnapshot = {
  name: string;
  price_per_month: number;
  created_by: string;
  created_at: NullableDate;
  db_type: "instance" | "cluster";
  storage_type: string;
  storage: string;
  type: ("manual" | "automated" | "awsbackup") | null;
  engine: string;
  source_name: string;
  total_siblings?: number;
  is_encrypted: boolean;
  zone: string;
  tags: Tags;
  resource_type: ResourceType;
  id: string;
  provider_unique_identifier: string;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  billable: Billable;
  is_aurora: boolean;
  protected_by: string | null;
};
export type FilteredRdsSnapshotResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: RdsSnapshot;
  child_resource: any | null;
};
export type FilteredRdsSnapshotResourcesV2 = {
  resources: FilteredRdsSnapshotResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type DataStorageInlineMetrics = {
  daily_start_point?: NullableDate;
  hourly_start_point?: NullableDate;
  all_request_sum?: InlineResourceMetric;
};
export type S3 = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  created_at: NullableDate;
  region: string;
  size: number;
  is_public: boolean;
  inline_metrics?: DataStorageInlineMetrics;
  last_modified?: NullableDate;
  tags: Tags;
  price_per_month: number;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason?: string | null;
  requests_value: number | null;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  issues?: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredS3ResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: S3;
  child_resource: any | null;
};
export type FilteredS3Resources = {
  resources: FilteredS3ResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type AzAksVm = {
  id: string;
  type: string;
  name: string;
  inline_metrics?: ComputeInlineMetrics;
  cpu_value: number | null;
  network_in_value: number | null;
  network_out_value: number | null;
  provider_unique_identifier: string;
  is_stopped: boolean;
  availability_zone: string;
  created_at: NullableDate;
  price_per_month: number;
  region: string | null;
  marked_for_deletion: boolean;
  cleanup_failure_reason: string | null;
  is_successfully_deleted: boolean;
  is_protected: boolean;
  creation_data: ResourceCreationData;
  tags: Tags;
  provider_status: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  all_time_spent: number | null;
  resource_state: ResourceState | (any | null);
  unused_since: UnusedSince;
  mode: string;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  issues?: ResourceIssues;
  protected_by: string | null;
};
export type AzNodePool = {
  id: string;
  name: string;
  mode?: string;
  price_per_month: number;
  created_at: NullableDate;
  region: string;
  account_id?: string;
  provider_unique_identifier: string;
  cleanup_failure_reason: string | null;
  is_protected: boolean;
  is_successfully_deleted: boolean;
  creation_data: ResourceCreationData;
  tags: Tags;
  instances_count: number;
  instances: AzAksVm[];
  marked_for_deletion: boolean;
  resource_type: ResourceType;
  link: string;
  not_allowed_actions: string[];
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  issues?: ResourceIssues;
  protected_by: string | null;
};
export type AzAks = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  node_pool_count: number;
  nodes_count: number;
  marked_for_deletion: boolean;
  price_per_month: number;
  created_at: NullableDate;
  region: string;
  cleanup_failure_reason: string | null;
  is_protected: boolean;
  creation_data: ResourceCreationData;
  tags: Tag[];
  is_successfully_deleted: boolean;
  node_pools: AzNodePool[];
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  all_time_spent: number | null;
  issues: ResourceIssues;
  pricing_tier: string;
  total_available_cpus: number;
  total_available_memory: string;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
  resource_group: string;
};
export type GkeVm = {
  id: string;
  type: string;
  name: string;
  cpu_value: number | null;
  network_in_value: number | null;
  network_out_value: number | null;
  inline_metrics: ComputeInlineMetrics;
  provider_unique_identifier: string;
  is_stopped: boolean;
  availability_zone: string;
  created_at: NullableDate;
  price_per_month: number;
  region: string | null;
  marked_for_deletion: boolean;
  cleanup_failure_reason: string | null;
  is_successfully_deleted: boolean;
  is_protected: boolean;
  creation_data: ResourceCreationData;
  tags: Tags;
  provider_status: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  all_time_spent: number | null;
  unused_since: UnusedSince;
  resource_state: ResourceState | (any | null);
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  issues?: ResourceIssues;
  protected_by: string | null;
};
export type NodePool = {
  id: string;
  name: string;
  price_per_month: number;
  created_at: NullableDate;
  region: string;
  account_id?: string;
  provider_unique_identifier: string;
  cleanup_failure_reason: string | null;
  is_protected: boolean;
  is_successfully_deleted: boolean;
  creation_data: ResourceCreationData;
  tags: Tags;
  instances_count: number;
  instances: GkeVm[];
  marked_for_deletion: boolean;
  resource_type: ResourceType;
  link: string;
  not_allowed_actions: string[];
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  issues?: ResourceIssues;
  protected_by: string | null;
};
export type FilteredAzAksVmResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: AzAksVm;
  child_resource: any | null;
};
export type FilteredAzAksVmResources = {
  resources: FilteredAzAksVmResourceItem;
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredAzNodePoolResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: NodePool;
  child_resource: FilteredAzAksVmResources | null;
};
export type FilteredAzNodePoolResources = {
  resources: FilteredAzNodePoolResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredAzAksResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: AzAks;
  child_resource: FilteredAzNodePoolResources | null;
};
export type FilteredAzAksResources = {
  resources: FilteredAzAksResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type AzDisk = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  size: number;
  sku: string;
  resource_group: string;
  has_attachments: boolean;
  created_at: NullableDate;
  price_per_month: number;
  region: string;
  tags: Tag[];
  marked_for_deletion: boolean;
  cleanup_failure_reason: string;
  is_protected: boolean;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredAzDiskResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: AzDisk;
  child_resource: any | null;
};
export type FilteredAzDiskResources = {
  resources: FilteredAzDiskResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type AzIp = {
  id: string;
  provider_unique_identifier: string;
  ip: string;
  name: string;
  resource_group: string;
  is_in_use: boolean;
  price_per_month: number;
  region: string;
  tags: Tag[];
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredAzIpResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: AzIp;
  child_resource: any | null;
};
export type FilteredAzIpResources = {
  resources: FilteredAzIpResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type AzLb = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  sku: string;
  has_backend: boolean;
  number_of_rules: number;
  created_at: NullableDate;
  price_per_month: number;
  region: string;
  tags: Tag[];
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
  resource_group: string;
};
export type FilteredAzLbResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: AzLb;
  child_resource: any | null;
};
export type FilteredAzLbResources = {
  resources: FilteredAzLbResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type AzSql = {
  id: string;
  type?: string;
  provider_unique_identifier: string;
  pricing_tier: string;
  instance_type: string;
  number_of_replicas: number;
  db_name: string;
  inline_metrics?: DatabaseInlineMetrics;
  cpu_value?: number | null;
  cpu_by_hours: Metric[];
  connections_by_hours: Metric[];
  connections_value?: number | null;
  created_at: NullableDate;
  price_per_month: number;
  region: string;
  tags: Tag[];
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  unused_since: UnusedSince;
  resource_state: ResourceState | (any | null);
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
  resource_group: string;
};
export type FilteredAzSqlResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: AzSql;
  child_resource: any | null;
};
export type FilteredAzSqlResources = {
  resources: FilteredAzSqlResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type AzVm = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  size: string;
  inline_metrics?: ComputeInlineMetrics;
  cpu_value?: number | null;
  network_in_value?: number | null;
  network_out_value?: number | null;
  created_at: NullableDate;
  price_per_month: number;
  resource_group: string;
  region: string;
  tags: Tag[];
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string;
  provider_status: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  unused_since: UnusedSince;
  resource_state: ResourceState | (any | null);
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredAzVmResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: AzVm;
  child_resource: any | null;
};
export type FilteredAzVmResources = {
  resources: FilteredAzVmResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type AzScaleCapacity = {
  default: string;
  maximum: string;
  minimum: string;
};
export type AzAutoscalingProfile = {
  name: string;
  azScaleCapacity: AzScaleCapacity;
  isDefault: string;
};
export type AzVmss = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  resource_group: string;
  instances_count: number;
  desired_capacity: number;
  instances: AzVm[];
  price_per_month: number;
  orchestration_mode: string;
  scaling: string;
  autoscaling_profiles: AzAutoscalingProfile[];
  created_at: NullableDate;
  resource_type: ResourceType;
  region: string;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredAzVmssResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: AzVmss;
  child_resource: any | null;
};
export type FilteredAzVmssResources = {
  resources: FilteredAzVmssResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Disk = {
  id: string;
  name: string;
  provider_unique_identifier: string;
  price_per_month: number;
  type: string;
  size: number;
  state: string;
  availability_zone: string;
  region: string | null;
  tags: Tags;
  has_attachments?: boolean;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  created_at: NullableDate;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredDiskResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Disk;
  child_resource: any | null;
};
export type FilteredDiskResources = {
  resources: FilteredDiskResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Gke = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  node_pool_count: number;
  nodes_count: number;
  marked_for_deletion: boolean;
  price_per_month: number;
  created_at: NullableDate;
  region: string;
  cleanup_failure_reason: string | null;
  is_protected: boolean;
  creation_data: ResourceCreationData;
  tags: Tag[];
  is_successfully_deleted: boolean;
  node_pools: NodePool[];
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  all_time_spent: number | null;
  issues: ResourceIssues;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredGkeVmResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: GkeVm;
  child_resource: any | null;
};
export type FilteredGkeVmResources = {
  resources: FilteredGkeVmResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredNodePoolResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: NodePool;
  child_resource: FilteredGkeVmResources | null;
};
export type FilteredNodePoolResources = {
  resources: FilteredNodePoolResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredGkeResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Gke;
  child_resource: FilteredNodePoolResources | null;
};
export type FilteredGkeResources = {
  resources: FilteredGkeResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Ip = {
  id: string;
  ip: string;
  name?: string;
  is_in_use: boolean;
  price_per_month: number;
  region: string;
  tags: Tags;
  created_at: NullableDate;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredIpResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Ip;
  child_resource: any | null;
};
export type FilteredIpResources = {
  resources: FilteredIpResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Lb = {
  id: string;
  load_balancer_name: string;
  price_per_month: number;
  type?: "TCP";
  global: boolean;
  created_at: NullableDate;
  has_attachments: boolean;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  region?: string | null;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  unused_since: UnusedSince;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  protected_by: string | null;
};
export type FilteredLbResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Lb;
  child_resource: any | null;
};
export type FilteredLbResources = {
  resources: FilteredLbResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type MigVm = {
  id: string;
  image_id: string;
  type: string;
  cpu: Metric[];
  cpu_by_hours: Metric[];
  cpu_value: number | null;
  network_in: Metric[];
  network_in_by_hours: Metric[];
  network_in_value: number | null;
  network_out: Metric[];
  network_out_by_hours: Metric[];
  network_out_value: number | null;
  inline_metrics: ComputeInlineMetrics;
  created_at: NullableDate;
  price_per_month: number;
  availability_zone: string;
  region: string | null;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  all_time_spent: number | null;
  unused_since: UnusedSince;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  recommendations?: Recommendations;
  issues?: ResourceIssues;
  protected_by: string | null;
};
export type Mig = {
  id: string;
  provider_unique_identifier: string;
  name: string;
  instances_count: number;
  recommended_size: number;
  max_num_replicas: number;
  min_num_replicas: number;
  price_per_month: number;
  region: string;
  instances: MigVm[];
  created_at: NullableDate;
  resource_type: ResourceType;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredMigVmResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: MigVm;
  child_resource: any | null;
};
export type FilteredMigVmResources = {
  resources: FilteredMigVmResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredMigResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Mig;
  child_resource: FilteredMigVmResources | null;
};
export type FilteredMigResources = {
  resources: FilteredMigResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Sql = {
  id: string;
  multi_az: boolean;
  price_per_month: number;
  cpu_size: string;
  ram_size: string;
  storage: string;
  database_connections: Metric[];
  inline_metrics: ComputeInlineMetrics;
  connections: Metric[];
  connections_by_hours?: Metric[];
  connections_value?: number | null;
  cpu: Metric[];
  cpu_by_hours?: Metric[];
  cpu_value?: number | null;
  type: string;
  created_at: NullableDate;
  region: string;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_status: string;
  resource_type: ResourceType;
  provider_unique_identifier: string;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  unused_since: UnusedSince;
  resource_state: ResourceState | (any | null);
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredSqlResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Sql;
  child_resource: any | null;
};
export type FilteredSqlResources = {
  resources: FilteredSqlResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Vm = {
  id: string;
  image_id: string;
  type: string;
  cpu: Metric[];
  cpu_by_hours?: Metric[];
  cpu_value?: number | null;
  network_in: Metric[];
  network_in_by_hours?: Metric[];
  network_in_value?: number | null;
  network_out: Metric[];
  network_out_by_hours?: Metric[];
  inline_metrics: ComputeInlineMetrics;
  network_out_value?: number | null;
  created_at: NullableDate;
  price_per_month: number;
  availability_zone: string;
  region?: string | null;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_status: string;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  recommendations: Recommendations;
  issues: ResourceIssues;
  all_time_spent: number | null;
  unused_since: UnusedSince;
  resource_state: ResourceState | (any | null);
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredVmResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Vm;
  child_resource: any | null;
};
export type FilteredVmResources = {
  resources: FilteredVmResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type RdsSnapshotSource = {
  id: string;
  price_per_month: number;
  created_at: NullableDate;
  resource_type: ResourceType;
  region: string;
  db_type: "instance" | "cluster";
  engine: string;
  exists: boolean;
  snapshots_count: number;
  marked_for_deletion: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  is_protected: boolean;
  billable: Billable;
  all_time_spent: number | null;
  tags: Tags;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  name: string;
  account: AccountLight;
  upcoming_actions?: UpcomingAction[];
  is_aurora: boolean;
  protected_by: string | null;
};
export type FilteredRdsSnapshotSourceResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: RdsSnapshotSource;
  child_resource: FilteredRdsSnapshotResourcesV2 | null;
};
export type FilteredRdsSnapshotSourceResources = {
  resources: FilteredRdsSnapshotSourceResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type CapacityMode = {
  min_capacity_units: number | null;
  current_provisioned_units: number | null;
  max_capacity_units: number | null;
  autoscaling: boolean | null;
};
export type DynamoDbTable = {
  id: string;
  name: string;
  size: string;
  provider_status: string;
  table_class: string | null;
  replicas: number;
  capacity_mode: string;
  read_capacity_mode: CapacityMode;
  write_capacity_mode: CapacityMode;
  read_usage_value: number | null;
  write_usage_value: number | null;
  rcu_reservation_value: number | null;
  wcu_reservation_value: number | null;
  created_at: Date;
  price_per_month: number;
  region: string | null;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  issues: ResourceIssues;
  all_time_spent: number;
  account: AccountLight;
  upcoming_actions: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredDynamoDbTableResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: DynamoDbTable;
  child_resource: any | null;
};
export type FilteredDynamoDbTableResources = {
  resources: FilteredDynamoDbTableResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type DynamoDbBackupType = "aws_backup" | "user" | "system";
export type DynamoDbBackup = {
  account: AccountLight;
  id: string;
  name: string;
  type: DynamoDbBackupType;
  storage_tier: StorageTier;
  size: string;
  table_name: string;
  created_at: Date;
  price_per_month: number;
  region: string | null;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  issues: ResourceIssues;
  all_time_spent: number;
  upcoming_actions: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredDynamoDbBackupResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: DynamoDbBackup;
  child_resource: any | null;
};
export type FilteredDynamoDbBackupResources = {
  resources: FilteredDynamoDbBackupResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type DaxCluster = {
  account: AccountLight;
  id: string;
  name?: string;
  node_type: string;
  node_count: number;
  cpu_reservation: number;
  memory_reservation: number;
  cpu_utilization_value: number | null;
  cache_memory_utilization_value: number | null;
  network_bytes_in_value: number | null;
  network_bytes_out_value: number | null;
  total_request_count_value: number | null;
  created_at: Date;
  price_per_month: number;
  region: string | null;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  provider_unique_identifier: string;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  all_time_spent: number;
  upcoming_actions: UpcomingAction[];
  protected_by: string | null;
  recommendations: Recommendations;
  issues: ResourceIssues;
};
export type FilteredDaxClusterResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: DaxCluster;
  child_resource: any | null;
};
export type FilteredDaxClusterResources = {
  resources: FilteredDaxClusterResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type NatGateway = {
  id: string;
  provider_unique_identifier: string;
  nat_gateway_id: string;
  name: string;
  created_at: NullableDate;
  price_per_month: number;
  region: string;
  sub_net: string;
  vpc: string;
  connectivity_type: string;
  public_addresses: string[];
  network_interface_ids: string[];
  private_addresses: string[];
  processed_data_value: number | null;
  processed_data_days: number | null;
  active_connections_value: number | null;
  tags: Tags;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  issues: ResourceIssues;
  all_time_spent: number;
  account: AccountLight;
  upcoming_actions: UpcomingAction[];
  protected_by: string | null;
};
export type FilteredNatGatewayResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: NatGateway;
  child_resource: any | null;
};
export type FilteredNatGatewayResources = {
  resources: FilteredNatGatewayResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Ecs = {
  id: string;
  provider_unique_identifier: string;
  capacity_providers: number;
  instances: number;
  price_per_month: number;
  created_at: NullableDate;
  tags: Tags;
  region: string;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  all_time_spent: number;
  name: string;
  account: AccountLight;
  protected_by: string | null;
  issues: ResourceIssues;
  upcoming_actions: UpcomingAction[];
};
export type EcsWorkload = {
  id: string;
  provider_unique_identifier: string;
  type: string;
  started_at: NullableDate;
  cpu_value: number | null;
  cpu_reservation: number | null;
  memory_value: number | null;
  memory_reservation: number | null;
  storage: number | null;
  status: string | null;
  capacity_provider: string | null;
  task_count: number | null;
  revision: number | null;
  price_per_month: number;
  created_at: NullableDate;
  tags: Tags;
  region: string;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  all_time_spent: number;
  name: string;
  account: AccountLight;
  protected_by: string | null;
  issues: ResourceIssues;
  upcoming_actions: UpcomingAction[];
};
export type FilteredEcsWorkloadResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: EcsWorkload;
  child_resource: any | null;
};
export type FilteredEcsWorkloadResources = {
  resources: FilteredEcsWorkloadResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type FilteredEcsResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: Ecs;
  child_resource: FilteredEcsWorkloadResources | null;
};
export type FilteredEcsResources = {
  resources: FilteredEcsResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type EcsWorkloadTask = {
  id: string;
  provider_unique_identifier: string;
  started_at: NullableDate;
  cpu_reservation: number;
  memory_reservation: number;
  storage: number | null;
  service: string | null;
  group: string | null;
  cluster: string;
  revision: number | null;
  last_status: string;
  desired_status: string | null;
  health_status: string | null;
  started_by: string | null;
  price_per_month: number;
  created_at: NullableDate;
  tags: Tags;
  region: string;
  marked_for_deletion: boolean;
  is_protected: boolean;
  cleanup_failure_reason: string | null;
  resource_type: ResourceType;
  not_allowed_actions: string[];
  link: string;
  creation_data: ResourceCreationData;
  all_time_spent: number;
  name: string;
  account: AccountLight;
  protected_by: string | null;
  issues: ResourceIssues;
  upcoming_actions: UpcomingAction[];
};
export type FilteredEcsWorkloadTaskResourceItem = {
  id: string;
  resource_type: ResourceType;
  resource: EcsWorkloadTask;
  child_resource: any | null;
};
export type FilteredEcsWorkloadTaskResources = {
  resources: FilteredEcsWorkloadTaskResourceItem[];
  estimated_monthly_waste: number;
  total_resources: number;
  total_monthly_spending: number;
};
export type Region = {
  name: string;
  key: string;
};
export type Regions = Region[] | null;
export type Operator = {
  key: FilterOperatorType;
  primary_label: string;
  secondary_label?: string | null;
};
export type FilterItemTemplate = {
  key: FilterKeyType;
  label: string;
  info?: string | null;
  icon?: string | null;
  value_type?:
    | (
        | "number"
        | "string"
        | "array"
        | "string_list"
        | "number_list"
        | "date"
        | "number_optional"
        | "kv_string_list"
      )
    | null;
  min?: string | number | string[] | number[] | (any | null);
  max?: string | number | string[] | number[] | (any | null);
  allowed_operators: FilterOperatorType[];
  operators: Operator[];
};
export type Filters = {
  type: ResourceType;
  label: string;
  filters: FilterItemTemplate[];
  child_filters?: Filters | null;
}[];
export type Plan = {
  id: string;
  name: string;
  name_key?: string;
  price: number;
  yearly: boolean;
  most_popular?: boolean;
  details?: {
    monthly_cloud_spend_range?: string;
    options?: {
      title?: string;
      availability?: boolean;
    }[];
  } | null;
};
export type GcpBillingExportData = {
  id: string;
  service_account: string;
  project_id: string;
  table: string;
  dataset: string;
};
export type Subscription = {
  id: string;
  plan: Plan;
  activation_date: Date;
  expiration_date: Date;
  status: "new" | "active" | "overdue" | "trailing";
  trial: boolean;
  has_payment_method_attached: boolean;
  type: "aws_marketplace" | "stripe";
};
export type NestedIntegration = {
  id: string;
  type: "webhook" | "jira" | "slack" | "email";
  metadata?: string[] | null;
};
export type Emails = {
  to: string[];
  cc?: string[] | null;
  bcc?: string[] | null;
  custom_message?: string | null;
};
export type NotificationEmail = object &
  Emails & {
    type?: "email";
    integration_id?: string | null;
    custom_message?: string | null;
  } & any;
export type SlackConversation = {
  id: string;
  name: string;
  display_name: string;
  type: "public_channel" | "private_channel" | "member";
};
export type NotificationSlack = {
  type: "slack";
  integration_id: string;
  channels: string[] | null;
  conversations?: SlackConversation[] | null;
  custom_message?: string | null;
};
export type NotificationWebhook = {
  type: "webhook";
  integration_id: string;
  custom_message?: string | null;
};
export type NotificationJira = {
  type: "jira";
  integration_id: string;
  custom_message?: string | null;
};
export type ScheduleAccount = {
  id: string;
  provider_account_id: string;
  provider_account_name: string | null;
  provider: ProviderType;
  status: AccountStatus;
};
export type ResourceFiltersWithAction = {
  action?: ("terminate" | "stop" | "start") | null;
  snapshot?: boolean | null;
  filter: ResourceFilters;
};
export type ScheduleActionType = "clean" | "notify" | "clean_and_notify";
export type ScheduleStatus = "active" | "inactive" | "deleted";
export type FutureRunDate = {
  date: Date;
  should_execute: boolean;
};
export type GracePeriod = {
  /** grace period in seconds */
  period?: number;
  notifications?: (
    | NotificationEmail
    | NotificationSlack
    | NotificationWebhook
    | NotificationJira
  )[];
} | null;
export type Schedule = {
  id: string;
  integrations?: NestedIntegration[] | null;
  notifications?:
    | (
        | NotificationEmail
        | NotificationSlack
        | NotificationWebhook
        | NotificationJira
      )[]
    | null;
  name: string;
  start_date_time: Date;
  end_date_time: NullableDate;
  cron: string | null;
  accounts: ScheduleAccount[];
  filter: ResourceFiltersWithAction[];
  regions: string[];
  action: ScheduleActionType;
  status: ScheduleStatus;
  time_zone: string;
  created_at: Date;
  metadata: {
    next_run_at: NullableDate;
    state: "normal" | "paused" | "complete" | "error" | "blocked";
    future_run_dates: FutureRunDate[] | null;
  };
  min_threshold?: number | null;
  grace_period?: GracePeriod;
  emails: Emails | (any | null);
  is_snoozed?: boolean | null;
  weekly_schedule_interval: number | null;
  daily_schedule_interval: number | null;
  description: string | null;
  cloud_provider: ProviderType | null;
};
export type WorkflowLight = {
  id: string;
  name: string;
  start_date_time: Date;
  end_date_time: NullableDate;
  cron: string | null;
  status: ScheduleStatus;
  action: ScheduleActionType;
  metadata?: {
    next_run_at: NullableDate;
  };
  time_zone: string;
  accounts: ScheduleAccount[];
  grace_period?: GracePeriod;
  is_snoozed?: boolean | null;
};
export type AllFilteredResources = {
  resources:
    | FilteredAsgResources
    | FilteredEbsResources
    | FilteredEbsSnapshotResourcesV2
    | FilteredEc2Resources
    | FilteredEipResources
    | FilteredEksResources
    | FilteredElbResources
    | FilteredElcResources
    | FilteredRdsResources
    | FilteredRdsSnapshotResourcesV2
    | FilteredS3Resources
    | FilteredAzAksResources
    | FilteredAzDiskResources
    | FilteredAzIpResources
    | FilteredAzLbResources
    | FilteredAzSqlResources
    | FilteredAzVmResources
    | FilteredAzVmssResources
    | FilteredDiskResources
    | FilteredGkeResources
    | FilteredIpResources
    | FilteredLbResources
    | FilteredMigResources
    | FilteredSqlResources
    | FilteredVmResources
    | FilteredRdsSnapshotSourceResources
    | FilteredDynamoDbTableResources
    | FilteredDynamoDbBackupResources
    | FilteredDaxClusterResources
    | FilteredNatGatewayResources
    | FilteredEcsResources
    | FilteredEcsWorkloadResources
    | FilteredEcsWorkloadTaskResources;
  resource_type: ResourceType;
  action?: ExecutionLogAction | null;
  snapshot?: boolean | null;
};
export type SsoConnectionType = ("okta" | "azure_ad") | null;
export type Organisation = {
  id?: string;
  name?: string;
  email?: string;
  restriction_violation?:
    | ("account_max_spending_exceeded" | "max_number_of_accounts_exceeded")
    | null;
  restriction_violation_apply_date?: NullableDate;
  restriction_violation_effective_from_date?: NullableDate;
  has_sso_setup: boolean;
  sso_connection_type?: SsoConnectionType;
  created_at?: Date;
};
export type OffHoursScheduleActionType = "notify" | "silent";
export type OffHoursSchedule = {
  id: string;
  integrations?: NestedIntegration[] | null;
  notifications?:
    | (
        | NotificationEmail
        | NotificationSlack
        | NotificationWebhook
        | NotificationJira
      )[]
    | null;
  emails: Emails | (any | null);
  name: string;
  start_date_time: Date;
  end_date_time: NullableDate;
  start_cron: string | null;
  stop_cron: string | null;
  accounts: ScheduleAccount[];
  filter?: ResourceFiltersWithAction[];
  regions: string[];
  status: ScheduleStatus;
  action: OffHoursScheduleActionType;
  created_at: Date;
  metadata: {
    next_start_run_at: string;
    next_stop_run_at: string;
    start_state: "normal" | "paused" | "complete" | "error" | "blocked";
    stop_state: "normal" | "paused" | "complete" | "error" | "blocked";
  };
  time_zone: string;
  min_threshold: number | null;
  description: string | null;
  cloud_provider: ProviderType | null;
};
export type Feature = {
  /** The key of feature */
  feature_key: string;
  created_at: Date;
};
export type SlackConversationResponse = {
  id: string;
  name: string;
  display_name: string;
  type: "public_channel" | "private_channel" | "member";
  issue?: string | null;
};
export type Integration = {
  id: string;
  organisation_id: string;
  user_id: string;
  name: string;
  type: "webhook" | "jira" | "slack" | "email";
  emails?: string[] | null;
  token?: string | null;
  channel?: string | null;
  channels?: string[] | null;
  workspace_id?: string | null;
  url?: string | null;
  content_type?: string | null;
  api_token?: string | null;
  default_project_key?: string | null;
  default_issue_type_id?: string | null;
  jira_email?: string | null;
  metadata: {
    emails?: string[];
    channel?: string | null;
    conversation?: SlackConversationResponse;
  } | null;
  conversation?: SlackConversationResponse | null;
  conversations?: SlackConversationResponse[] | null;
};
export type SmartTagTtlSetting = {
  type: string;
  value: string;
  default_value: string;
  options: any[] | null;
};
export type QuarterStartsFromMonthSetting = {
  type: string;
  value: string;
  default_value: string;
  options: any[] | null;
};
export type SavingsPlanTermInYears = "one_year" | "three_years";
export type SavingsPlanPaymentOption =
  | "no_upfront"
  | "partial_upfront"
  | "all_upfront";
export type LookbackPeriod = "seven_days" | "thirty_days" | "sixty_days";
export type SavingsPlanType = "sage_maker" | "compute" | "ec2_instance";
export type SavingsPlanAccountScope = "payer" | "linked";
export type SavingsPlanRecommendation = {
  term_in_years: SavingsPlanTermInYears;
  payment_option: SavingsPlanPaymentOption;
  hourly_commitment: number;
  estimated_coverage: number;
  estimated_monthly_savings: number;
  estimated_savings_percentage: number;
  estimated_on_demand_spend_over_look_back_period: number;
  estimated_covered_usage_over_look_back_period: number;
  region: string | null;
  instance_family: string | null;
  action_url: string;
  account: EmbeddedAccount;
  lookback_period: LookbackPeriod;
  savings_plan_type: SavingsPlanType;
  account_scope: SavingsPlanAccountScope;
};
export type SavingsPlanRecommendationSummary = {
  total_hourly_commitment: number;
  current_monthly_on_demand_spend: number;
  current_hourly_on_demand_spend: number;
  estimated_monthly_on_demand_spend: number;
  estimated_hourly_on_demand_spend: number;
  estimated_hourly_savings: number;
  estimated_monthly_savings: number;
  estimated_savings_percentage: number;
  estimated_hourly_on_demand_spend_over_look_back_period: number;
  recommendations: SavingsPlanRecommendation[];
};
export type SavingsPlanRecommendationsPotentialSavings = {
  savings_amount: number | null;
};
export type SavingsPlanUtilizationRecommendation = {
  estimated_utilization: number;
  current_utilization: number;
  current_hourly_commitment: number;
  recommended_hourly_commitment: number;
  savings_plan_end_date: Date;
  estimated_monthly_savings: number;
  recommended_start_date: Date;
  link: string;
};
export type SavingsPlanResourceType =
  | "ec2"
  | "fargate"
  | "lambda"
  | "sagemaker"
  | "eks";
export type SavingsPlanState = "active" | "expired" | "expires_soon";
export type SavingsPlan = {
  id: string;
  provider_unique_id: string;
  type: SavingsPlanType;
  account: EmbeddedAccount;
  region: string;
  payment_option: SavingsPlanPaymentOption;
  used_commitment_to_date: number | null;
  total_commitment_to_date: number | null;
  net_savings_to_date: number | null;
  total_commitment: number;
  duration_in_years: number;
  tags: Tags;
  start_date: Date;
  end_date: Date;
  state: SavingsPlanState;
  ec2_instance_family: string | null;
  url: string;
  hourly_commitment: number;
  has_recommendation: boolean;
};
export type CommitmentType = "savings_plan";
export type SavingsPlanCoverageData = {
  account: {
    id?: string;
    provider_account_id?: string;
    name?: string | null;
  } | null;
  covered_usage: number;
  uncovered_usage: number;
  coverage: number;
  net_savings: number;
  savings_plan: {
    provider_id: string;
    id: string;
    type: CommitmentType;
  } | null;
  resource_type: {
    resource_type: (any | null) & SavingsPlanResourceType;
    region: string | null;
    instance_family: string | null;
  } | null;
};
export type SavingsPlanUtilizationAndCoverage = {
  savings_plans: SavingsPlan[];
  coverage_data: SavingsPlanCoverageData[];
  utilization_total_percent: number | null;
  coverage_total_percent: number | null;
};
export type SavingsPlanDateGranularDataPointValue = {
  percent: number | null;
  amount: number | null;
};
export type SavingsPlanDateGranularDataPoint = {
  date: Date;
  coverage: SavingsPlanDateGranularDataPointValue;
  utilization: SavingsPlanDateGranularDataPointValue;
};
export type SavingsPlanDateGranularData = {
  data: SavingsPlanDateGranularDataPoint[];
  uncovered_usage: number | null;
  total_commitment: number | null;
  total_coverage: SavingsPlanDateGranularDataPointValue;
  total_utilization: SavingsPlanDateGranularDataPointValue;
};
export type SavingsPlanDataAggregatedTotals = {
  total_spend: number | null;
  average_coverage: number | null;
  total_net_savings: number | null;
};
export type SavingsPlanData = {
  utilization_and_coverage: SavingsPlanUtilizationAndCoverage;
  date_granular_data: SavingsPlanDateGranularData;
  totals: SavingsPlanDataAggregatedTotals;
  date_from: Date;
  date_to: Date;
};
export type DateLabel =
  | "year_to_date"
  | "month_to_date"
  | "last_one_month"
  | "last_three_months"
  | "last_six_months"
  | "past_fourteen_days"
  | "past_seven_days"
  | "past_thirty_days"
  | "past_forty_five_days"
  | "past_ninety_days"
  | "past_one_hundred_eighty_days"
  | "last_twelve_months"
  | "quarter_to_date"
  | "last_quarter";
export type DateType = "past" | "last";
export type DateUnit = "day" | "week" | "month";
export type DateDataPoint = "daily" | "monthly" | "weekly";
export type SavingsPlanCoverageGrouping =
  | "resource_type"
  | "account"
  | "commitment";
export type MinimumAvailableDate = {
  minimum_available_date: NullableDate;
};
export type SavingsPlanDateGranularDataNullablePoint = {
  date: Date;
  coverage: SavingsPlanDateGranularDataPointValue & (any | null);
  utilization: SavingsPlanDateGranularDataPointValue & (any | null);
};
export type SavingsPlanCoverageMetadata = {
  covered_usage: number | null;
  coverage: number | null;
  uncovered_usage: number | null;
};
export type SavingsPlanUtilizationMetadata = {
  used_commitment: number | null;
  utilization: number | null;
  covered_usage: number | null;
  net_savings: number | null;
};
export type SavingsPlanFilteredData = {
  data: SavingsPlanDateGranularDataNullablePoint[];
  metadata: SavingsPlanCoverageMetadata | SavingsPlanUtilizationMetadata;
  coverage_data: SavingsPlanCoverageData[];
};
export type SavingsPlanFilterType =
  | "resource_type"
  | "account"
  | "savings_plan";
export type ScheduleFrequency = "hourly" | "daily" | "weekly" | "monthly";
export type ApiKey = {
  id: string;
  organisation_id: string;
  api_key: string;
  name: string;
  created_at: string;
  expires_at: string;
  last_used_at: string | null;
};
export type MetricType = "cpu" | "database_connections";
export type Metrics = {
  type: MetricType;
  threshold: number;
  metrics: Metric[];
};
export type OrganisationDailyOffHoursRecommendation2 = {
  id: string;
  resource_id: string;
  account_id: string;
  account: EmbeddedAccount;
  from_hour: number;
  to_hour: number;
  number_of_off_hours: number;
  provider_unique_identifier: string;
  resource_name?: string | null;
  tags?: Tags;
  potential_saving: number;
  region: string;
  resource_type: ResourceType;
  metrics: Metrics[];
  type: string;
  dismiss_until_date: string | null;
  is_forever_dismissed: boolean;
  dismissed_by: string | null;
  is_dismissed_by_resource: boolean;
  resource_identifier: string;
};
export type OrganisationWeeklyOffHoursRecommendation2 = {
  id: string;
  resource_id: string;
  account_id: string;
  account: EmbeddedAccount;
  provider_unique_identifier: string;
  resource_name?: string | null;
  tags?: Tags;
  potential_saving: number;
  region: string;
  resource_type: ResourceType;
  from_hour: number;
  to_hour: number;
  from_day:
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";
  to_day:
    | "monday"
    | "tuesday"
    | "wednesday"
    | "thursday"
    | "friday"
    | "saturday"
    | "sunday";
  number_of_off_hours: number;
  metrics: Metrics[];
  type: string;
  dismiss_until_date: string | null;
  is_forever_dismissed: boolean;
  dismissed_by: string | null;
  is_dismissed_by_resource: boolean;
  resource_identifier: string;
};
export type EmbeddedBillingAccount = {
  id: string;
  provider: ProviderType;
  provider_account_id: string;
  provider_account_name: string | null;
  billing_account_id: string | null;
};
export type AccountCategoryWithCost = {
  id: string;
  name: string;
  value: string;
  accounts: EmbeddedBillingAccount[];
  total_cost: number;
  filtered_resource_costs: number;
};
export type AccountCategory = {
  id: string;
  name: string;
  value: string;
  accounts: EmbeddedBillingAccount[];
};
export type ResourceExplorerFilterOperator =
  | "in"
  | "not_in"
  | "contains"
  | "does_not_contain"
  | "exists"
  | "does_not_exist"
  | "starts_with"
  | "ends_with";
export type ResourceExplorerAccount = {
  operator: ResourceExplorerFilterOperator;
  value: string[] | string;
};
export type ResourceExplorerAccounts = {
  items: ResourceExplorerAccount[];
  combination_operator?: string;
};
export type ResourceExplorerTag = {
  tag_key: string;
  operator: ResourceExplorerFilterOperator;
  tag_values?: string[] | string | (any | null);
};
export type ResourceExplorerTags = {
  items: ResourceExplorerTag[];
  combination_operator?: string;
};
export type AwsFilter = {
  accounts?: ResourceExplorerAccounts;
  tags?: ResourceExplorerTags;
  combination_operator?: "and" | "or";
};
export type AzureFilter = {
  accounts?: ResourceExplorerAccounts;
};
export type GcpFilter = {
  accounts?: ResourceExplorerAccounts;
  tags?: ResourceExplorerTags;
  combination_operator?: "and" | "or";
};
export type ResourceExplorerDynamicFilterItemType =
  | "account"
  | "region"
  | "service"
  | "charge_type"
  | "product_family"
  | "instance_family"
  | "instance_type"
  | "tag"
  | "resource_id"
  | "tracked"
  | "credit"
  | "credit_type"
  | "marketplace";
export type ResourceExplorerFilterProvider =
  | "aws"
  | "gcp"
  | "azure"
  | "dimensions"
  | "global";
export type KeyValuePair = {
  key: string;
  value: string | string[];
};
export type ResourceExplorerDynamicFilterItem = {
  type: ResourceExplorerDynamicFilterItemType;
  cloud_provider: ResourceExplorerFilterProvider;
  value?: (number | null) | (string | null) | KeyValuePair | (string[] | null);
  operator: FilterOperatorType;
};

export type ResourceExplorerDynamicFilter = {
  filter_items: ResourceExplorerDynamicFilterItem[];
  filter_groups: (number | FilterGroup)[];
  operators: ("AND" | "OR")[];
};
export type CategoryFilter = {
  filters?: ResourceExplorerDynamicFilter;
};
export type CategoryWithCosts = {
  id: string;
  name: string;
  total_cost: number;
  filtered_resource_costs: number;
  filters: CategoryFilter;
};
export type DimensionWithCosts = {
  id: string;
  name: string;
  categories: CategoryWithCosts[];
  total_cost: number;
  filtered_resource_costs: number;
};
export type Category = {
  id: string;
  name: string;
  filter: CategoryFilter;
};
export type Dimension = {
  id: string;
  name: string;
  categories: Category[];
};
export type Project = {
  id?: string;
  name?: string;
};
export type GcpBillingAccountAttemptStatusDetailsStarted = {
  service_account?: string | null;
};
export type GcpBillingAccountAttemptStatusDetailsClosed = {
  project_id?: string | null;
};
export type Chart = {
  id?: string;
  name?: string;
  organisation_id?: string;
  created_at?: Date;
  updated_at?: Date;
  payload?: object;
};
export type ResourceVisibility = {
  type: ResourceType;
  visibilities: {
    show: boolean;
    actions: {
      delete: boolean | null;
      snapshot_and_delete: boolean | null;
      stop: boolean | null;
      start: boolean | null;
    };
  };
};
export type AccountsVisibilities = {
  account_id: string;
  accounts_visibilities: ResourceVisibility[];
};
export type ChatAnswer = {
  query: string;
  data: object[];
};
export type RecommendationTarget =
  | "same_instance_family"
  | "cross_instance_family";
export type RightSizingRecommendation = {
  id: string;
  organisation_id: string;
  provider_unique_identifier: string;
  current_machine_type: string;
  recommended_machine_type: string;
  potential_saving: number;
  created_date: string;
  account: EmbeddedAccount;
  resource_name: string | null;
  resource_type: ResourceType;
  spending: number;
  cpu_max: number;
  tags: Tag[];
  region: string;
  current_vcpus: number;
  recommended_vcpus: number;
  link: string;
  dismiss_until_date: string | null;
  is_forever_dismissed: boolean;
  dismissed_by: string | null;
  is_dismissed_by_resource: boolean;
  resource_identifier: string;
  is_default: boolean | null;
  recommendation_target: RecommendationTarget | null;
};
export type RightSizingRecommendationDetails = {
  id: string;
  organisation_id: string;
  provider_unique_identifier: string;
  current_machine_type: string;
  recommended_machine_type: string;
  potential_saving: number;
  created_date: string;
  account: EmbeddedAccount;
  resource_name: string | null;
  resource_type: ResourceType;
  spending: number;
  cpu: Metric[];
  cpu_max: number;
  tags: Tag[];
  region: string;
  link: string;
  current_vcpus: number;
  recommended_vcpus: number;
  dismiss_until_date: string | null;
  is_forever_dismissed: boolean;
  dismissed_by: string | null;
  is_dismissed_by_resource: boolean;
  resource_identifier: string;
  is_default: boolean | null;
  recommendation_target: RecommendationTarget | null;
};
export type AccountResourceFilter = {
  type: ResourceType;
  filter_items: FilterItemWithType[];
  filter_groups: (number | FilterGroup)[];
  operators: ("AND" | "OR")[];
};
export type FilterSetSource = "cloudchipr" | "user";
export type FilterSet = {
  id: string;
  name: string;
  organisation_id: string;
  filters: AccountResourceFilter[];
  created_at: string;
  source: FilterSetSource;
  created_by: string | null;
  cloud_provider: ProviderType;
  is_default: boolean;
  accounts: EmbeddedAccount[];
};
export type AccountFilterSet = {
  filter_set: FilterSet;
  ems: number;
};
export type AccountResourceFilter2 = {
  type: ResourceType;
  filter_items: FilterItemWithType[];
  filter_groups: (number | FilterGroup)[];
  operators: ("AND" | "OR")[];
};
export type AccountFilterSetV2 = {
  filter_set: FilterSet;
  ems: number;
};
export type ResourceExplorerFilterKeyType =
  | "service"
  | "cost_allocation_tag"
  | "charge_type"
  | "account"
  | "region"
  | "credit_type"
  | "product_family"
  | "instance_family"
  | "instance_type"
  | "dimension_id"
  | "category_id";
export type ResourceExplorerFilterOperatorType = "in" | "not_in";
export type ResourceExplorerPossibleFilter = {
  key: ResourceExplorerFilterKeyType;
  operators: ResourceExplorerFilterOperatorType[];
  values: {
    value: string;
    title: string | null;
    is_marketplace_service?: boolean;
    provider_account_id?: string;
    dimension_id?: string;
  }[];
  include_values?: boolean;
};
export type LoadTrackedResourcesFor = "view" | "category";
export type DateLabelNullable = DateLabel | null;
export type Dates = {
  from?: NullableDate;
  to?: NullableDate;
  label?: DateLabelNullable;
  type?: DateType;
  value?: number;
  unit?: DateUnit;
};
export type ResourceExplorerGrouping =
  | (
      | "service"
      | "cost_allocation_tag"
      | "charge_type"
      | "account"
      | "region"
      | "resource"
      | "product_family"
      | "instance_family"
      | "instance_type"
      | "credit_type"
      | "cloud_provider"
      | "cost_allocation_tag_value"
      | "category"
      | "none"
    )
  | null;
export type ResourceExplorerAwsFilterRequest = {
  account_ids?: string[];
  regions?: string[];
  charge_types?: string[];
  services?: string[];
  product_families?: string[];
  instance_families?: string[];
  instance_types?: string[];
  tags?: ResourceExplorerTags;
  has_tags?: boolean;
};
export type Credits = {
  include_values: boolean;
  credit_types?: string[];
};
export type ResourceExplorerGcpFilterRequest = {
  account_ids?: string[];
  regions?: string[];
  services?: string[];
  product_families?: string[];
  cost_types?: string[];
  credits?: Credits;
  tags?: ResourceExplorerTags;
  has_tags?: boolean;
};
export type ResourceExplorerAzureFilterRequest = {
  account_ids?: string[];
  regions?: string[];
  charge_types?: string[];
  services?: string[];
  product_families?: string[];
  tags?: ResourceExplorerTags;
  has_tags?: boolean;
};
export type TrendType = "period_over_period" | "month_over_month";
export type ResourceExplorerFilterSchema = {
  cloud_providers: ProviderType[];
  date_label?: DateLabelNullable;
  date_from?: NullableDate;
  date_to?: NullableDate;
  dates?: Dates;
  data_point: DateDataPoint;
  group_by?: ResourceExplorerGrouping;
  is_tracked?: boolean | null;
  group_values?: string[];
  aws_filters?: ResourceExplorerAwsFilterRequest;
  gcp_filters?: ResourceExplorerGcpFilterRequest;
  azure_filters?: ResourceExplorerAzureFilterRequest;
  filters?: ResourceExplorerDynamicFilter;
  dimension_id?: string;
  category_ids?: string[] | null;
  load_quarterly_forecast_for_grouping_items?: boolean;
  load_monthly_forecast_for_grouping_items?: boolean;
  trend_type?: TrendType;
};
export type LoadTrackedResourcesForView = {
  load_for: LoadTrackedResourcesFor;
  filters: ResourceExplorerFilterSchema;
};
export type LoadTrackedResourcesForCategories = {
  load_for: LoadTrackedResourcesFor;
  category_ids: string[];
  date_from?: Date;
  date_to?: Date;
};
export type ResourceExplorerFilterItem = {
  key: ResourceExplorerFilterKeyType;
  value?: ((string | null) | ResourceExplorerTag)[] | null;
  operator: ResourceExplorerFilterOperatorType;
  include_values?: boolean | null;
  cloud_provider: "aws" | "gcp" | "azure" | "global";
  combination_operator?: "and" | "or";
};
export type ForecastOption = "month" | "quarter";
export type ResourceExplorerData = {
  date_label: DateLabelNullable;
  from_date: NullableDate;
  to_date: NullableDate;
  data_point: DateDataPoint;
  dates?: Dates;
  grouping: ResourceExplorerGrouping;
  group_values?: string[];
  forecast_option: ForecastOption;
  show_budget_line?: boolean;
  trend_type?: TrendType;
};
export type ThresholdTrigger = "actual" | "forecasted";
export type BudgetPeriod = "daily" | "monthly" | "quarterly" | "annually";
export type Budget = {
  id: string;
  name: string;
  amount: number;
  view: {
    id: string;
    name: string;
  };
  thresholds: {
    percent: number;
    amount: number;
    trigger: ThresholdTrigger;
  }[];
  period: BudgetPeriod;
  progress: number;
  email_to: Emails | (any | null);
  integrations: NestedIntegration[] | null;
  notifications:
    | (
        | NotificationEmail
        | NotificationSlack
        | NotificationWebhook
        | NotificationJira
      )[]
    | null;
  start_date: string;
};
export type ResourceExplorer = {
  id: string;
  filters: ResourceExplorerFilterItem[];
  cloud_provider: ProviderType;
  cloud_providers?: ProviderType[];
  metadata: object;
  is_default: boolean;
  name: string;
  created_at: Date;
  data: ResourceExplorerData;
  budget: Budget | null;
  visibility: "visible_to_everyone" | "visible_only_to_me";
};
export type ResourceExplorerFolder = {
  id: string;
  name: string;
  organisation_id?: string;
  created_by?: string;
  views?: ResourceExplorer[];
};
export type ReFolderV2 = {
  id: string;
  name: string;
  organisation_id: string;
  created_by?: string;
  items?: ResourceExplorer[];
};
export type ResourceExplorerResourceDetail = {
  id: string;
  account: EmbeddedAccount;
  region: Region;
  tags: Tags;
};
export type ResourceExplorerChartDataItem = {
  field?: string;
  label?: string | null;
  cost?: number;
};
export type ResourceExplorerChartData = {
  date?: string;
  items?: ResourceExplorerChartDataItem[];
};
export type AFlexibleKeyValueMapContainingMetadataAboutTheRow = {
  [key: string]: string;
};
export type ForecastedCost = {
  cost: number;
  forecast_option: ForecastOption;
  trend: number | null;
  date_from: Date;
  date_to: Date;
  previous_period_from: Date;
  previous_period_to: Date;
};
export type NullableForecastedCost = (any | null) & ForecastedCost;
export type ResourceExplorerGridTotal = {
  field: string;
  label?: string | null;
  total_cost: number;
  previous_period_total_cost: number | null;
  period_total_cost_difference: number | null;
  trend: number | null;
  cloud_provider: ProviderType;
  metadata: AFlexibleKeyValueMapContainingMetadataAboutTheRow;
  live_usage_cost?: number | null;
  monthly_forecast: NullableForecastedCost;
  quarterly_forecast: NullableForecastedCost;
};
export type AverageDailyCostDetails = {
  from_date: Date;
  to_date: Date;
  average_daily_cost: number | null;
  trend: number | null;
};
export type AverageMonthlyCostDetails = {
  from_date: Date;
  to_date: Date;
  average_monthly_cost: number | null;
  trend: number | null;
};
export type ResourceExplorerCostsDateDetails = {
  from_date: Date;
  to_date: Date;
};
export type ForecastedCostRe = ForecastedCost[];
export type ResourceExplorerWidget = {
  data: ResourceExplorerChartData[];
  total: ResourceExplorerGridTotal[];
  total_cost: number;
  previous_period_total_cost: number | null;
  average_daily_cost_details: AverageDailyCostDetails;
  average_monthly_cost_details: AverageMonthlyCostDetails;
  live_usage_total_cost: number;
  total_cost_date_details: ResourceExplorerCostsDateDetails;
  previous_period_cost_date_details: ResourceExplorerCostsDateDetails;
  forecasted_costs: ForecastedCostRe;
  trend_type: TrendType;
};
export type ResourceExplorerGridGranularDateData = {
  date: Date;
  total_cost: number | null;
  trend: number | null;
};
export type ResourceExplorerGridGranular = {
  field: string;
  label?: string | null;
  total_cost: number;
  trend: number | null;
  cloud_provider: ProviderType;
  metadata: AFlexibleKeyValueMapContainingMetadataAboutTheRow;
  dates: ResourceExplorerGridGranularDateData[];
};
export type ResourceExplorerGranularCost = {
  columns: string[];
  data: ResourceExplorerGridGranular[];
};
export type ResourceExplorerFilterOperatorV2 = {
  key?: FilterOperatorType;
  label?: string;
};
export type ResourceExplorerPossibleFilterV2 = {
  key: ResourceExplorerDynamicFilterItemType;
  label: string;
  operators: ResourceExplorerFilterOperatorV2[];
  filter_provider: ResourceExplorerFilterProvider;
};
export type ResourceExplorerPossibleFiltersV2 =
  ResourceExplorerPossibleFilterV2[];
export type ResourceExplorerFilterValue = {
  value: string;
  title: string | null;
  provider_account_id?: string;
  dimension_id?: string;
};
export type ResourceExplorerFilterValues = ResourceExplorerFilterValue[];
export type SlackAppInstallationAttempt = {
  /** Slack app installation link */
  link?: string;
};
export type MessageWithSubject = {
  subject?: string | null;
  body?: string | null;
} | null;
export type ResourceWithVisibility = {
  key: string;
  value: object | null;
  is_visible?: boolean;
};
export type UserPreference = {
  id: string;
  key: string;
  value: string | null;
};
export type UserDigestPreferences = {
  monthly_digest: boolean;
  weekly_digest: boolean;
};
export type Threshold = {
  percent: number;
  trigger: ThresholdTrigger;
};
export type ProviderOrganisation = {
  organization_id: string | null;
  name: string | null;
  cloud_provider: ProviderType;
  has_savings_plan: boolean;
  accounts: EmbeddedAccount[];
};
export type DateDataPointNullable = DateDataPoint | null;
export type PriceDirectionNullable = ("increasing" | "decreasing") | null;
export type SortByNullable = ("sort_by_percentage" | "sort_by_amount") | null;
export type MultiCloudFilter = {
  cloud_provider: ProviderType;
  filter: string;
};
export type TrendTypeNullable = TrendType | null;
export type ResourceExplorerDataOverrideFilters = {
  from_date?: NullableDate;
  to_date?: NullableDate;
  date_label?: DateLabelNullable;
  data_point?: DateDataPointNullable;
  dates?: Dates;
  grouping?: ResourceExplorerGrouping;
  price_direction?: PriceDirectionNullable;
  sort_by?: SortByNullable;
  items_count?: number | null;
  group_values?: string[];
  filters?: MultiCloudFilter[];
  min_cost_change?: number | null;
  min_percentage_change?: number | null;
  only_summary?: boolean | null;
  load_detailed_previous_period_data?: boolean | null;
  load_quarterly_forecast_for_grouping_items?: boolean;
  load_monthly_forecast_for_grouping_items?: boolean;
  trend_type?: TrendTypeNullable;
};
export type WidgetType =
  | "resource_explorer"
  | "costs_by_category"
  | "accounts_live_usage"
  | "cost_comparison"
  | "cost_breakdown"
  | "cost_and_usage_summary"
  | "largest_cost_changes"
  | "costs_by_category_v2";
export type WidgetStateReason = "no_category_group" | "billing_error";
export type LayoutNullable = object | null;
export type DashboardWidgetCostsByCategory = {
  id: string;
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  category_key: string;
  date_label: DateLabel;
};
export type DashboardWidgetResourceExplorer = {
  id: string;
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  resource_explorer_view_id: string;
};
export type DashboardWidgetAccountsLiveUsage = {
  id: string;
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  account_ids: string[];
};
export type ChartType = "stack" | "line" | "bar" | "pie" | "table" | "numeral";
export type DashboardWidgetCostsByCategoryV2 = {
  id: string;
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  created_by: string | null;
  date_from: NullableDate;
  date_to: NullableDate;
  date_label: DateLabelNullable;
  category_group_key: string;
  categories: {
    id: string;
    category: string;
  }[];
  chart_type: string;
  display_filtered_resources: boolean;
  date_granularity: DateDataPoint;
  forecast_option: ForecastOption;
};
export type DashboardWidgetCostBreakdown = {
  id: string;
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  created_by: string | null;
  view_id: string;
  date_from: NullableDate;
  date_to: NullableDate;
  date_label: DateLabelNullable;
  date_granularity: DateDataPointNullable;
  grouping: ResourceExplorerGrouping;
  group_values?: string[];
  chart_type: ChartType;
  trend_type?: TrendType;
  filter: string[];
  aws_filter: string[];
  gcp_filter: string[];
  azure_filter: string[];
  forecast_option: ForecastOption;
  dates?: Dates;
};
export type DashboardWidgetCostAndUsageSummary = {
  id: string;
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  created_by: string | null;
  view_id: string;
  trend_type?: TrendType;
  date_label: DateLabelNullable;
  date_from: NullableDate;
  date_to: NullableDate;
  filter: string[];
  date_granularity: DateDataPointNullable;
  grouping: ResourceExplorerGrouping;
  group_values?: string[];
};
export type PriceDirection = "increasing" | "decreasing";
export type DashboardWidgetLargestCostChanges = {
  id: string;
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  created_by: string;
  view_id: string;
  date_label: DateLabelNullable;
  date_from: NullableDate;
  date_to: NullableDate;
  date_granularity: DateDataPointNullable;
  grouping: ResourceExplorerGrouping;
  group_values: string[];
  filter: string[] | null;
  price_direction: PriceDirection;
  sort_by: SortByNullable;
  items_count: number;
  min_cost_change: number | null;
  min_percentage_change: number | null;
};
export type Dashboard = {
  id: string;
  name: string;
  created_at: Date;
  visibility: "visible_only_to_me" | "visible_to_everyone";
};
export type DashboardFolder = {
  id: string;
  name: string;
  organisation_id?: string;
  created_by?: string;
  items?: Dashboard[];
};
export type DashboardWidgetCostsByCategory2 = {
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  category_key: string;
  date_label: DateLabel;
};
export type DashboardWidgetResourceExplorer2 = {
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  resource_explorer_view_id: string;
};
export type DashboardWidgetAccountsLiveUsage2 = {
  name: string;
  type: WidgetType;
  layout: LayoutNullable;
  account_ids: string[];
};
export type DashboardWidgetCostsByCategoryV2Request = {
  name: string;
  layout?: LayoutNullable;
  date_label?: DateLabelNullable;
  date_to?: NullableDate;
  date_from?: NullableDate;
  dates?: Dates;
  category_group_key: string;
  category_ids: string[];
  chart_type: string;
  display_filtered_resources: boolean;
  date_granularity: DateDataPoint;
  type: WidgetType;
  forecast_option?: ForecastOption;
};
export type DashboardWidgetCostBreakdownRequest = {
  name: string;
  layout?: LayoutNullable;
  view_id: string;
  date_label?: DateLabelNullable;
  date_to?: NullableDate;
  date_from?: NullableDate;
  date_granularity?: DateDataPointNullable;
  dates?: Dates;
  grouping?: ResourceExplorerGrouping;
  group_values?: string[];
  chart_type: ChartType;
  filter?: string[];
  aws_filter?: string[];
  gcp_filter?: string[];
  azure_filter?: string[];
  type: WidgetType;
  forecast_option?: ForecastOption;
  trend_type: TrendType;
};
export type DashboardWidgetCostAndUsageSummaryRequest = {
  name: string;
  layout?: LayoutNullable;
  view_id: string;
  date_label?: DateLabelNullable;
  date_from?: NullableDate;
  date_to?: NullableDate;
  date_granularity?: DateDataPointNullable;
  dates?: Dates;
  grouping?: ResourceExplorerGrouping;
  group_values?: string[];
  filter?: string[];
  type: WidgetType;
  trend_type: TrendType;
};
export type SortBy = "sort_by_percentage" | "sort_by_amount";
export type DashboardWidgetLargestCostChangesRequest = {
  name: string;
  view_id: string;
  items_count: number;
  layout?: LayoutNullable;
  date_label?: DateLabelNullable;
  date_from?: NullableDate;
  date_to?: NullableDate;
  date_granularity?: DateDataPointNullable;
  dates?: Dates;
  grouping?: ResourceExplorerGrouping;
  group_values?: string[];
  filter?: string[];
  price_direction?: PriceDirection;
  sort_by?: SortBy;
  min_cost_change?: number;
  min_percentage_change?: number;
  type: WidgetType;
};
export type Layout = {
  id?: string;
};
export type DashboardWidgetAccountsLiveUsagePatchRequest = {
  account_ids?: string[];
  name?: string;
  type?: WidgetType;
  layout?: Layout;
};
export type DashboardWidgetCostsByCategoryV2PatchRequest = {
  name?: string;
  layout?: Layout;
  date_label?: DateLabel;
  date_from?: Date;
  date_to?: Date;
  dates?: Dates;
  category_group_key?: string;
  category_ids?: string[];
  chart_type?: ChartType;
  display_filtered_resources?: boolean;
  date_granularity?: DateDataPoint;
  forecast_option?: ForecastOption;
};
export type DashboardWidgetCostBreakdownPatchRequest = {
  name?: string;
  layout?: Layout;
  view_id?: string;
  date_label?: DateLabel;
  date_from?: Date;
  dateTo?: Date;
  date_granularity?: DateDataPoint;
  dates?: Dates;
  grouping?: ResourceExplorerGrouping;
  trend_type?: TrendTypeNullable;
  group_values?: string[];
  chart_type?: ChartType;
  filter?: string[];
  aws_filter?: string[];
  gcp_filter?: string[];
  azure_filter?: string[];
  forecast_option?: ForecastOption;
  type?: WidgetType;
};
export type DashboardWidgetCostAndUsageSummaryPatchRequest = {
  name?: string;
  layout?: Layout;
  view_id?: string;
  date_label?: DateLabel;
  date_from?: Date;
  date_to?: Date;
  date_granularity?: DateDataPoint;
  dates?: Dates;
  grouping?: ResourceExplorerGrouping;
  trend_type?: TrendTypeNullable;
  group_values?: string[];
  filter?: string[];
  type?: WidgetType;
};
export type DashboardWidgetLargestCostChangesPatchRequest = {
  name?: string;
  layout?: Layout;
  view_id?: string;
  date_label?: DateLabel;
  date_from?: Date;
  date_to?: Date;
  date_granularity?: DateDataPoint;
  dates?: Dates;
  grouping?: ResourceExplorerGrouping;
  group_values?: string[];
  filter?: string[];
  price_direction?: PriceDirection;
  sort_by?: SortBy;
  items_count?: number;
  min_cost_change?: number;
  min_percentage_change?: number;
  type?: WidgetType;
};
export type UpdateDashboardWidgetAccountsLiveUsage = {
  name: string | null;
  type: WidgetType;
  layout: LayoutNullable;
  account_ids: string[];
};
export type UpdateDashboardWidgetCostsByCategoryV2Request = {
  name: string;
  layout: LayoutNullable;
  date_label: DateLabelNullable;
  date_to: NullableDate;
  date_from: NullableDate;
  dates?: Dates;
  category_group_key: string;
  category_ids: string[];
  chart_type: string;
  display_filtered_resources: boolean;
  date_granularity: DateDataPoint;
  forecast_option: ForecastOption;
  type: WidgetType;
};
export type UpdateDashboardWidgetCostBreakdownRequest = {
  name: string;
  view_id: string;
  date_label: DateLabel;
  date_from: NullableDate;
  date_to: NullableDate;
  layout: LayoutNullable;
  date_granularity: DateDataPointNullable;
  dates?: Dates;
  grouping: ResourceExplorerGrouping;
  group_values: string[];
  chart_type: ChartType;
  trend_type?: TrendType;
  filter: string[] | null;
  aws_filter: string[] | null;
  gcp_filter: string[] | null;
  azure_filter: string[] | null;
  type: WidgetType;
  forecast_option: ForecastOption;
};
export type UpdateDashboardWidgetCostAndUsageSummaryRequest = {
  name: string;
  layout: LayoutNullable;
  date_label: DateLabelNullable;
  date_from: NullableDate;
  date_to: NullableDate;
  date_granularity: DateDataPointNullable;
  dates?: Dates;
  grouping: ResourceExplorerGrouping;
  trend_type?: TrendType;
  group_values: string[] | null;
  filter: string[] | null;
  type: WidgetType;
  view_id: string;
};
export type UpdateDashboardWidgetLargestCostChangesRequest = {
  view_id: string;
  name: string;
  date_label: DateLabelNullable;
  date_from: NullableDate;
  date_to: NullableDate;
  date_granularity: DateDataPointNullable;
  dates?: Dates;
  grouping: ResourceExplorerGrouping;
  group_values: string[] | null;
  filter: string[] | null;
  price_direction: PriceDirection;
  sort_by: SortBy;
  items_count: number;
  min_cost_change: number | null;
  min_percentage_change: number | null;
  type: WidgetType;
  layout: LayoutNullable;
};
export type IpAddresses = {
  type: string;
  ip: string;
};
export type CommandLogSource = {
  source: string | null;
  name: string | null;
};
export type DismissPeriod =
  | "for_seven_days"
  | "for_one_month"
  | "for_three_months"
  | "forever";
export type RestoreRecommendationRequest = {
  account_ids: string[];
  recommendation_ids: string[];
};
export type RestoreRecommendationByResourceRequest = {
  account_ids: string[];
  resource_identifiers: string[];
};
export type TagKey = {
  key: string;
  is_active: boolean;
};
export const {
  useGetUsersMeQuery,
  usePatchUsersMeMutation,
  usePutUsersMeMutation,
  useDeleteUsersMeMutation,
  usePostUsersMeOrganisationsCurrentAccountsByAccountIdRecommendationsStatusMutation,
  useGetUsersMeOrganisationsCurrentRecommendationsStatusQuery,
  usePostUsersMeOrganisationsCurrentUsersMutation,
  useGetUsersMeOrganisationsCurrentUsersQuery,
  usePostUsersMeInvitationRequestsMutation,
  useGetUsersMeOrganisationsCurrentInvitationRequestsQuery,
  useDeleteUsersMeOrganisationsCurrentInvitationRequestsByUserIdMutation,
  usePostUsersMeOrganisationsCurrentUsersByUserIdReInvitationsMutation,
  useGetUsersMeOrganisationsCurrentUsersByUserIdQuery,
  usePatchUsersMeOrganisationsCurrentUsersByUserIdMutation,
  useDeleteUsersMeOrganisationsCurrentUsersByUserIdMutation,
  usePostUsersMeAccountAttemptsMutation,
  useGetUsersMeAccountAttemptsByAccountAttemptIdQuery,
  usePatchProvidersAwsAccountAttemptsByAccountAttemptIdMutation,
  usePostProvidersAwsCfnTemplatesMutation,
  usePatchUsersMeProvidersAzureAccountAttemptsByAccountAttemptIdMutation,
  useGetUsersMeAccountsQuery,
  useGetUsersMeAccountsByAccountIdQuery,
  usePatchUsersMeAccountsByAccountIdMutation,
  useDeleteUsersMeAccountsByAccountIdMutation,
  usePatchUsersMeProvidersByProviderAccountsAndAccountIdAccessModeMutation,
  usePostUsersMeAccountsByAccountIdFiltersMutation,
  usePostUsersMeAccountsByAccountIdFiltersV2Mutation,
  useGetUsersMeAccountsByAccountIdFiltersCurrentQuery,
  useGetUsersMeAccountsByAccountIdFiltersCurrentV2Query,
  useGetUsersMeAccountsByAccountIdCostsQuery,
  useGetUsersMeOrganisationsCurrentProvidersByProviderBillingStatusQuery,
  useGetV2UsersMeOrganisationsCurrentProvidersByProviderBillingStatusQuery,
  useGetUsersMeOrganisationsCurrentProvidersByProviderBillingCurrentQuery,
  useGetUsersMeOrganisationsCurrentResourcesDailyOffHoursRecommendationsQuery,
  useGetUsersMeOrganisationsCurrentResourcesWeeklyOffHoursRecommendationsQuery,
  usePostV3UsersMeExecutionLogsMutation,
  usePostUsersMeExecutionLogsDetailsMutation,
  useGetV3UsersMeExecutionLogsByExecutionIdQuery,
  usePostUsersMeOrganisationsCurrentByTypeResourcesTagKeysMutation,
  usePostUsersMeOrganisationsCurrentByTypeResourcesTagValuesMutation,
  usePostUsersMeV2OrganisationsCurrentByTypeResourcesMutation,
  useGetUsersMeDynamoDbBackupTypesQuery,
  usePostUsersMeDynamoDbBackupTableNamesMutation,
  useGetUsersMeByTypeStorageTiersQuery,
  usePutUsersMeAccountsByAccountIdResourcesMutation,
  usePatchUsersMeAccountsByAccountIdResourcesMutation,
  useGetProvidersByProviderRegionsQuery,
  useGetProvidersByProviderFiltersQuery,
  useGetV2PlansQuery,
  usePostUsersMeOrganisationsCurrentGcpBillingExportDataMutation,
  useGetUsersMeOrganisationsCurrentGcpBillingExportDataQuery,
  useGetUsersMeSubscriptionsCurrentQuery,
  usePostV2UsersMeSchedulesMutation,
  useGetV2UsersMeSchedulesQuery,
  useGetV2UsersMeSchedulesByScheduleIdQuery,
  usePatchV2UsersMeSchedulesByScheduleIdMutation,
  useGetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdQuery,
  useGetUsersMeWorkflowsByWorkflowIdBatchesAndBatchIdResourcesQuery,
  usePostUsersMeOrganisationsCurrentBillingPortalMutation,
  usePostUsersMeOrganisationsCurrentBillingPortalPlansByPlanIdMutation,
  useGetUsersMeOrganisationsCurrentQuery,
  usePatchUsersMeOrganisationsCurrentMutation,
  usePostV2UsersMeOffHoursSchedulesMutation,
  useGetV2UsersMeOffHoursSchedulesQuery,
  useGetV2UsersMeOffHoursSchedulesByScheduleIdQuery,
  usePatchV2UsersMeOffHoursSchedulesByScheduleIdMutation,
  useGetUsersMeFeaturesQuery,
  usePostUsersMeFeaturesMutation,
  useGetUsersMeProvidersAwsAccountsByAccountIdMissingPermissionsQuery,
  useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesQuery,
  useGetUsersMeProvidersGcpAccountsByAccountIdMissingRolesOptionsQuery,
  useGetUsersMeProvidersGcpAccountsByAccountIdMissingApisQuery,
  useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesQuery,
  useGetUsersMeProvidersAzureAccountsByAccountIdMissingRolesOptionsQuery,
  usePatchUsersMeAccountsByAccountIdPermissionsMutation,
  useGetUsersMeIntegrationsQuery,
  useDeleteUsersMeIntegrationsByIntegrationIdMutation,
  usePostUsersMeByTypeIntegrationsMutation,
  useGetUsersMeByTypeIntegrationsAndIntegrationIdQuery,
  usePatchUsersMeByTypeIntegrationsAndIntegrationIdMutation,
  usePostUsersMeSlackIntegrationsByIntegrationIdTestMessagesMutation,
  usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeSetsMutation,
  usePostUsersMeProvidersAwsAccountsByAccountIdCfnChangeAccessModeChangeSetsMutation,
  useGetUsersMeOrganisationsCurrentProvidersByProviderTotalSavedAmountQuery,
  useGetUsersMeOrganisationsCurrentSsoOptionsCurrentQuery,
  usePostUsersMeOrganisationsCurrentSsoMutation,
  useDeleteUsersMeOrganisationsCurrentSsoCurrentMutation,
  useGetUsersMeOrganisationsCurrentSettingsQuery,
  useGetUsersMeOrganisationsCurrentSettingsByKeyQuery,
  usePatchUsersMeOrganisationsCurrentSettingsByKeyMutation,
  usePostUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummaryMutation,
  useGetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsQuery,
  useGetUsersMeOrganisationsCurrentSavingsPlansUtilizationRecommendationsQuery,
  useGetUsersMeOrganisationsCurrentSavingsPlansRecommendationsQuery,
  useGetUsersMeOrganisationsCurrentSavingsPlansQuery,
  useGetUsersMeOrganisationsCurrentSavingsPlansMinimumAvailableDateQuery,
  useGetUsersMeOrganisationsCurrentSavingsPlansDataQuery,
  usePostUsersMeSchedulesNextRunDateMutation,
  usePostUsersMeOffHoursSchedulesNextRunDateMutation,
  useGetUsersMeAccountsByAccountIdAndTypeResourcesMetadataQuery,
  useGetUsersMeAccountsByAccountIdMetadataQuery,
  useGetUsersMeOrganisationsCurrentApiKeysQuery,
  usePostUsersMeOrganisationsCurrentApiKeysMutation,
  useDeleteUsersMeOrganisationsCurrentApiKeysByKeyIdMutation,
  useGetUsersMeAccountByAccountIdDailyOffHoursRecommendationsAndRecommendationIdQuery,
  useGetUsersMeAccountByAccountIdWeeklyOffHoursRecommendationsAndRecommendationIdQuery,
  usePatchUsersMeAccountByAccountIdRecommendationsResourcesAndResourceIdMutation,
  useGetUsersMeCategoriesQuery,
  usePostUsersMeCategoriesMutation,
  usePutUsersMeCategoriesMutation,
  useGetUsersMeCategoriesByCategoryIdQuery,
  usePutUsersMeCategoriesByCategoryIdMutation,
  useDeleteUsersMeCategoriesByCategoryIdMutation,
  useGetUsersMeOrganisationsCurrentBillingAccountsQuery,
  useGetUsersMeOrganisationsCurrentDimensionsQuery,
  usePostUsersMeOrganisationsCurrentDimensionsMutation,
  useGetUsersMeOrganisationsCurrentDimensionsByDimensionIdQuery,
  usePutUsersMeOrganisationsCurrentDimensionsByDimensionIdMutation,
  useDeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdMutation,
  useGetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesQuery,
  usePostUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesMutation,
  useGetUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdQuery,
  usePutUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdMutation,
  useDeleteUsersMeOrganisationsCurrentDimensionsByDimensionIdCategoriesAndCategoryIdMutation,
  usePostUsersMeProvidersGcpBillingAccountAttemptsMutation,
  useGetUsersMeProvidersGcpBillingAccountAttemptsByBillingAccountAttemptIdQuery,
  usePostUsersMeWebhookIntegrationsTestsMutation,
  useGetV2UsersMeOrganisationsCurrentUsersQuery,
  useGetUsersMeOrganisationsCurrentChartQuery,
  usePostUsersMeOrganisationsCurrentChartMutation,
  useGetUsersMeOrganisationsCurrentChartByChartIdQuery,
  usePatchUsersMeOrganisationsCurrentChartByChartIdMutation,
  useDeleteUsersMeOrganisationsCurrentChartByChartIdMutation,
  usePostUsersMeAccountsByTypeResourcesMutation,
  useGetUsersMeProvidersByProviderAccountsResourcesVisibilitiesQuery,
  usePostUsersMeChatQuestionsMutation,
  useGetUsersMeOrganisationsCurrentResourcesRightSizingRecommendationsQuery,
  useGetUsersMeAccountsByAccountIdRightSizingRecommendationsAndRecommendationIdQuery,
  useGetUsersMeAccountsByAccountIdFilterSetsV2Query,
  useGetUsersMeOrganisationsCurrentFilterSetsQuery,
  usePostUsersMeOrganisationsCurrentFilterSetsV2Mutation,
  useGetUsersMeOrganisationsCurrentCostAllocationTagsQuery,
  useGetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderTagValuesQuery,
  useGetUsersMeOrganisationsCurrentResourceExplorerProvidersByProviderFiltersQuery,
  usePostUsersMeOrganisationsCurrentTrackedResourcesMutation,
  useGetUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyQuery,
  usePatchUsersMeOrganisationsCurrentResourceExplorerViewsHierarchyMutation,
  useGetUsersMeOrganisationsCurrentResourceExplorerHierarchyQuery,
  usePatchUsersMeOrganisationsCurrentResourceExplorerHierarchyMutation,
  useGetV2UsersMeOrganisationsCurrentResourceExplorerHierarchyQuery,
  usePatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleOnlyToMeMutation,
  usePatchUsersMeOrganisationsCurrentResourceExplorerHierarchyVisibleToEveryoneMutation,
  useGetUsersMeOrganisationsCurrentResourceExplorerViewsQuery,
  usePostUsersMeOrganisationsCurrentResourceExplorerViewsMutation,
  usePostV2UsersMeOrganisationsCurrentResourceExplorerViewsMutation,
  usePostUsersMeOrganisationsCurrentResourceExplorerFoldersMutation,
  usePostV2UsersMeOrganisationsCurrentResourceExplorerFoldersMutation,
  usePostV3UsersMeOrganisationsCurrentResourceExplorerFoldersMutation,
  useGetUsersMeOrganisationsCurrentAccountsByAccountIdProvidersAndProviderResourceExplorerResourcesQuery,
  useDeleteUsersMeOrganisationsCurrentFilterSetsByFilterSetIdMutation,
  usePostUsersMeOrganisationsCurrentWidgetsResourceExplorerMutation,
  usePostUsersMeOrganisationsCurrentResourceExplorerMutation,
  usePostUsersMeOrganisationsCurrentResourceExplorerGranularCostsMutation,
  usePostUsersMeOrganisationsCurrentWidgetsResourceExplorerLiveUsageTotalCostMutation,
  useGetUsersMeResourceExplorerPossibleFiltersQuery,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery,
  usePostUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvMutation,
  useGetUsersMeOrganisationsCurrentWidgetsResourceExplorerDownloadCsvQuery,
  usePostUsersMeOrganisationsCurrentResourceExplorerGranularCostsDownloadCsvMutation,
  useGetUsersMeOrganisationsCurrentResourceCreatorsQuery,
  usePostUsersMeJiraIntegrationsAutoCompleteUsersDataMutation,
  usePostUsersMeJiraIntegrationsByIntegrationIdIssuesMutation,
  usePostUsersMeJiraIntegrationsProjectsMutation,
  usePostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesMutation,
  usePostUsersMeJiraIntegrationsProjectsByProjectIdIssueTypesAndIssueTypeFieldsMutation,
  usePostUsersMeJiraIntegrationsByIntegrationIdProjectsMutation,
  usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesMutation,
  usePostUsersMeJiraIntegrationsByIntegrationIdProjectsAndProjectIdIssueTypesIssueTypeFieldsMutation,
  useGetUsersMeProvidersAzureResourcesTypesByResourceTypeResourceGroupsQuery,
  usePostUsersMeJiraIntegrationsByIntegrationIdAutoCompleteUsersDataMutation,
  usePostUsersMeSlackIntegrationsByIntegrationIdMessageMutation,
  useGetUsersMeSlackIntegrationsByIntegrationIdConversationsQuery,
  usePostUsersMeSlackInstallationAttemptsMutation,
  usePostUsersMeEmailsMessageMutation,
  usePostV2UsersMeEmailsMessageMutation,
  useGetUsersMeAccountsResourceTypesByResourceTypeInstanceTypesQuery,
  useGetUsersMeAccountsResourceTypesByResourceTypeInstanceFamiliesQuery,
  usePostUsersMeCurrentPreferenceMutation,
  useGetUsersMeCurrentPreferencesQuery,
  usePatchUsersMeCurrentPreferenceByKeyMutation,
  useGetUsersMeCurrentPreferenceByKeyQuery,
  useGetUsersMeCurrentDigestPreferencesQuery,
  usePatchUsersMeCurrentDigestPreferencesMutation,
  useDeleteUsersMeOrganisationsCurrentBudgetsByBudgetIdMutation,
  usePatchUsersMeOrganisationsCurrentBudgetsByBudgetIdMutation,
  useGetUsersMeOrganisationsCurrentBudgetsByBudgetIdQuery,
  useGetUsersMeOrganisationsCurrentBudgetsQuery,
  useGetUsersMeOrganisationsCurrentViewsByViewIdBudgetsQuery,
  usePostUsersMeOrganisationsCurrentViewsByViewIdBudgetsMutation,
  useGetUsersMeOrganisationsCurrentProviderOrganisationsQuery,
  useGetUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdQuery,
  usePostUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdMutation,
  usePatchUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdMutation,
  useDeleteUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdMutation,
  useDeleteV2UsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewIdMutation,
  usePatchUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdMutation,
  useDeleteUsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdMutation,
  usePatchV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdMutation,
  useDeleteV2UsersMeOrganisationsCurrentResourceExplorerFoldersByResourceExplorerFolderIdMutation,
  usePatchUsersMeOrganisationsCurrentFilterSetsByFilterSetIdV2Mutation,
  useGetUsersMeOrganisationsCurrentDashboardsPossibleWidgetsQuery,
  usePostUsersMeOrganisationsCurrentDashboardsMutation,
  useGetUsersMeOrganisationsCurrentDashboardsQuery,
  usePostV2UsersMeOrganisationsCurrentDashboardsMutation,
  usePostUsersMeOrganisationsCurrentDashboardFoldersMutation,
  usePostV2UsersMeOrganisationsCurrentDashboardFoldersMutation,
  usePatchUsersMeOrganisationsCurrentDashboardFoldersByFolderIdMutation,
  useDeleteUsersMeOrganisationsCurrentDashboardFoldersByFolderIdMutation,
  useDeleteV2UsersMeOrganisationsCurrentDashboardsFoldersByFolderIdMutation,
  usePatchUsersMeOrganisationsCurrentDashboardsByDashboardIdMutation,
  useDeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdMutation,
  useGetUsersMeOrganisationsCurrentDashboardsByDashboardIdQuery,
  useDeleteV2UsersMeOrganisationsCurrentDashboardsByDashboardIdMutation,
  usePostUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsMutation,
  usePatchUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdMutation,
  useDeleteUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdMutation,
  usePutUsersMeOrganisationsCurrentDashboardsByDashboardIdWidgetsAndWidgetIdMutation,
  useGetUsersMeOrganisationsCurrentDashboardHierarchyQuery,
  usePatchUsersMeOrganisationsCurrentDashboardHierarchyMutation,
  useGetV2UsersMeOrganisationsCurrentDashboardsHierarchyQuery,
  usePatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleOnlyToMeMutation,
  usePatchUsersMeOrganisationsCurrentDashboardHierarchyVisibleToEveryoneMutation,
  useGetUsersMeOrganisationsCurrentCategoriesWidgetQuery,
  usePostUsersMeOrganisationsCurrentWidgetsCostByCategoryMutation,
  useGetUsersMeOrganisationsCurrentWidgetsCostBreakdownQuery,
  useGetUsersMeOrganisationsCurrentWidgetsCostBreakdownByWidgetIdQuery,
  useGetUsersMeRdsStorageTypesQuery,
  usePostUsersMeRdsSnapshotSourceNamesMutation,
  useGetUsersMeRdsSnapshotTypesQuery,
  useGetUsersMeEbsSnapshotStatusesQuery,
  usePostUsersMeEbsSnapshotVolumeIdsMutation,
  useGetUsersMeDynamoDbTableStatusesQuery,
  useGetUsersMeDynamoDbTableCapacityModesQuery,
  useGetUsersMeNatGatewayIpAddressesQuery,
  useGetUsersMeNatGatewayVpcQuery,
  useGetUsersMeEcsWorkloadCapacityProvidersQuery,
  useGetUsersMeOrganisationsCurrentResourceExplorerViewsByViewIdWidgetQuery,
  useGetUsersMeExecutionLogsSourcesQuery,
  usePostUsersMeOrganisationsCurrentMinimumAvailableDateMutation,
  usePatchUsersMeRightSizingRecommendationsMutation,
  usePatchUsersMeDailyOffHoursRecommendationsMutation,
  usePatchUsersMeWeeklyOffHoursRecommendationsMutation,
  usePatchUsersMeResourcesRightSizingRecommendationsMutation,
  usePatchUsersMeResourcesDailyOffHoursRecommendationsMutation,
  usePatchUsersMeResourcesWeeklyOffHoursRecommendationsMutation,
  useGetUsersMeOrganisationsCurrentDismissedResourcesQuery,
  usePatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursMutation,
  usePatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursMutation,
  usePatchUsersMeOrganisationsCurrentRecommendationsDailyOffHoursByResourceMutation,
  usePatchUsersMeOrganisationsCurrentRecommendationsWeeklyOffHoursByResourceMutation,
  usePatchUsersMeOrganisationsCurrentRecommendationsRightSizingMutation,
  usePatchUsersMeOrganisationsCurrentRecommendationsRightSizingByResourceMutation,
  useGetUsersMeOrganisationsCurrentProvidersByProviderTagKeysQuery,
  usePatchUsersMeOrganisationsCurrentProvidersByProviderTagKeysMutation,
} = injectedRtkApi;
