import { FC, useCallback } from "react";
import { Typography } from "@mui/material";
import Chip from "@mui/material/Chip";
import { RecommendationLevelFilterAccountsSelect } from "./RecommendationLevelFilterAccountsSelect";
import { RecommendationLevelFilterPayer } from "./RecommendationLevelFilterPayer";
import { FilterSelect, FilterSelectOptions } from "../FilterSelect";
import { setCommitmentsRecommendationsAccountScope } from "../../../../../../../../../store/commitments/commitmentsSlice";
import { SavingsPlanAccountScope } from "../../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../../store/hooks";
import { commitmentsRecommendationsAccountScopeSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountScopeSelector";
import { commitmentsRecommendationsAccountIdsSelector } from "../../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsAccountIdsSelector";
import { getCommitmentsRecommendationsThunk } from "../../../../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import { organizationAccountsByCurrentOrgIdSelector } from "../../../../../../../../../store/commitments/selectors/organisations/organizationAccountsByCurrentOrgIdSelector";

export const RecommendationLevelFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(commitmentsRecommendationsAccountScopeSelector);
  const accountIds = useAppSelector(
    commitmentsRecommendationsAccountIdsSelector,
  );
  const accounts = useAppSelector(organizationAccountsByCurrentOrgIdSelector);
  const allSelected = accounts?.length === accountIds?.length;

  const changeHandler = useCallback(
    (scope: string) => {
      dispatch(
        setCommitmentsRecommendationsAccountScope(
          scope as SavingsPlanAccountScope,
        ),
      );
      dispatch(getCommitmentsRecommendationsThunk());
    },
    [dispatch],
  );

  const renderValue = useCallback(
    (value: unknown) => {
      if (value === "payer") {
        return levelOptions?.at(0)?.label;
      }

      return (
        <Typography component="div" variant="caption">
          Linked Account{" "}
          <Chip
            size="small"
            sx={{ ml: 1 }}
            variant="filled"
            label={
              <Typography
                fontWeight="bold"
                color="text.secondary"
                variant="caption"
              >
                {allSelected ? "All" : accountIds?.length}
              </Typography>
            }
          />
        </Typography>
      );
    },
    [accountIds, allSelected],
  );

  if (!value) {
    return null;
  }

  return (
    <FilterSelect
      value={value}
      label="Recommendation Level"
      renderValue={renderValue}
      options={levelOptions}
      onChange={changeHandler}
    />
  );
};

const levelOptions: FilterSelectOptions = [
  {
    value: "payer",
    label: "Payer",
    ListItem: RecommendationLevelFilterPayer,
  },
  {
    value: "linked",
    label: "Linked",
    ListItem: RecommendationLevelFilterAccountsSelect,
  },
];
