import { FC, useCallback } from "react";
import { Box, Stack } from "@mui/material";
import { FilterRow } from "./FilterRow";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { ProviderLogo } from "../../../../../common/provider-logo/ProviderLogo";
import { AddFilterInProvider } from "../new-filter-creator/add-buttons/AddFilterInProvider";
import {
  DynamicProviderFilterGroup,
  FiltersCombinationOperatorType,
  DynamicProviderFilter,
} from "../../utils/types/common";
import { generateFilterKey } from "../../utils/helpers/generateFilterKey";

interface ProviderFilterCardProps {
  provider: ProviderType;
  filters: DynamicProviderFilterGroup;
  onFiltersChange(
    newFilters: DynamicProviderFilterGroup | null,
    provider: ProviderType,
  ): void;
  onProviderFiltersRemove(provider: ProviderType): void;
}

export const ProviderFilterCard: FC<ProviderFilterCardProps> = ({
  provider,
  filters,
  onFiltersChange,
  onProviderFiltersRemove,
}) => {
  const operatorChangeHandler = useCallback(
    (operator: FiltersCombinationOperatorType) => {
      onFiltersChange({ ...filters, operator }, provider);
    },
    [onFiltersChange, filters, provider],
  );

  const filterChangeHandler = useCallback(
    (newFilter: DynamicProviderFilter, index: number) => {
      onFiltersChange(
        {
          ...filters,
          filters: filters.filters.map((filter, i) => {
            if (i === index) {
              return newFilter;
            }

            return filter;
          }),
        },
        provider,
      );
    },
    [onFiltersChange, filters, provider],
  );

  const filterRemoveHandler = useCallback(
    (index: number) => {
      if (filters.filters.length === 1) {
        onProviderFiltersRemove(provider);
        return;
      }

      onFiltersChange(
        {
          ...filters,
          filters: filters.filters.filter((_, i) => i !== index),
        },
        provider,
      );
    },
    [onFiltersChange, onProviderFiltersRemove, filters, provider],
  );

  return (
    <Stack direction="row" borderRadius={2} bgcolor="grey.50" overflow="hidden">
      <Box p={1} bgcolor="grey.100">
        <ProviderLogo provider={provider} />
      </Box>

      <Stack p={1} spacing={2} flex={1}>
        {filters.filters.map((filter, index) => {
          return (
            <FilterRow
              index={index}
              filter={filter}
              provider={provider}
              onFilterRemove={filterRemoveHandler}
              key={generateFilterKey(filter)}
              parentOperator={filters.operator}
              onFiltersChange={filterChangeHandler}
              onOperatorChange={operatorChangeHandler}
            />
          );
        })}

        <AddFilterInProvider
          provider={provider}
          filters={filters}
          onFiltersChange={onFiltersChange}
        />
      </Stack>
    </Stack>
  );
};
