import { Dispatch, FC, SetStateAction, useCallback } from "react";
import { Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { useAuth0 } from "@auth0/auth0-react";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";

import { PageHeaderWithLogo } from "../PageHeaderWithLogo";
import {
  usePostUsersMeInvitationRequestsMutation,
  UserStatus,
} from "../../../services/cloudchipr.api";
import PendingReviewIcon from "../../../assets/images/pending-review.png";

interface UserAwaitingInvitationProps {
  onStatusChange: Dispatch<SetStateAction<UserStatus>>;
}

export const UserAwaitingInvitation: FC<UserAwaitingInvitationProps> = ({
  onStatusChange,
}) => {
  const { logout } = useAuth0();
  const { enqueueSnackbar } = useSnackbar();

  const [trigger, { isLoading }] = usePostUsersMeInvitationRequestsMutation();

  const handleLogoutClickHandler = useCallback(() => {
    logout({ returnTo: window.location.origin });
  }, [logout]);

  const requestInvitationClickHandler = useCallback(async () => {
    trigger()
      .unwrap()
      .then((response) => {
        if (response.status === "in_review") {
          onStatusChange("in_review");
        }
      })
      .catch((error) => {
        enqueueSnackbar(error?.data?.message ?? "Something went wrong", {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
          },
          autoHideDuration: 3000,
        });
      });
  }, [trigger, enqueueSnackbar, onStatusChange]);

  return (
    <Stack height="100vh" bgcolor="grey.50">
      <PageHeaderWithLogo />
      <Stack alignItems="center" mt={20} spacing={3.5} px={1}>
        <Typography variant="h4" color="primary.dark" fontWeight="medium">
          Invitation Required
        </Typography>
        <img width={140} src={PendingReviewIcon} alt="Invitation Required" />
        <Typography
          variant="h6"
          textAlign="center"
          color="text.secondary"
          fontWeight="normal"
        >
          There is already an existing organization with this domain, but looks
          like you haven't been invited yet. <br />
          Request an invitation to proceed.
        </Typography>
        <Stack direction="row" spacing={3.5}>
          <Button
            onClick={handleLogoutClickHandler}
            sx={{ textTransform: "none" }}
            variant="outlined"
          >
            Back to Login
          </Button>
          <LoadingButton
            variant="contained"
            onClick={requestInvitationClickHandler}
            sx={{ textTransform: "none" }}
            loading={isLoading}
          >
            Request invitation
          </LoadingButton>
        </Stack>
      </Stack>
    </Stack>
  );
};
