import { FC, useCallback } from "react";
import { KeyValuePair } from "../../../../../../../../../services/cloudchipr.api";
import { DynamicProviderFilterItem } from "../../../../../utils/types/common";
import { FilterValueAutocomplete } from "../filter-value/FilterValueAutocomplete";

export type FilterWithKey = { value: KeyValuePair } & DynamicProviderFilterItem;

interface FilterKeySelectProps {
  index: number;
  filter: FilterWithKey;
  onFilterChange(filter: DynamicProviderFilterItem, index: number): void;
}

export const FilterKeySelect: FC<FilterKeySelectProps> = ({
  onFilterChange,
  filter,
  index,
}) => {
  const filterTypeChangeHandler = useCallback(
    (values: string[]) => {
      const key = values?.at(0);

      if (filter?.value?.key === key || !key) {
        return;
      }

      onFilterChange({ ...filter, value: { key, value: [] } }, index);
    },
    [index, onFilterChange, filter],
  );

  const value = filter.value?.key;

  return (
    <FilterValueAutocomplete
      singleSelect
      filterType={filter.type}
      onChange={filterTypeChangeHandler}
      provider={filter.cloud_provider}
      filterValue={value ? [value] : []}
    />
  );
};
