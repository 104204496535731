import { FC, useCallback } from "react";
import { AutomationResourceSelect } from "../resource-select/AutomationResourceSelect";
import {
  ResourceFilters,
  ResourceFiltersWithAction,
  ResourceType,
} from "../../../../../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { automationResourcesSelector } from "../../../../../../../../store/automations/selectros/common/fields/automationResourcesSelector";
import { allDefaultFiltersForAutomationSelector } from "../../../../../../../../store/automations/selectros/common/helpers-data/allDefaultFiltersForAutomationSelector";
import { useFilterActionsAdder } from "../../../../utils/hooks/useCurrentAutomationFilterActionsAdder.hook";

interface AutomationResourceItemProps {
  filters: ResourceFilters;
  index: number;
  onChange(filter: ResourceFiltersWithAction[]): void;
}

export const AutomationResourceItem: FC<AutomationResourceItemProps> = ({
  filters,
  index,
  onChange,
}) => {
  const addActionToFilter = useFilterActionsAdder();

  const resources = useAppSelector(automationResourcesSelector);
  const allDefaultFilters = useAppSelector(
    allDefaultFiltersForAutomationSelector,
  );
  const resourceType = filters.type;

  const updateResource = useCallback(
    (type: ResourceType) => {
      const newFilters = resources.map((resource, i) => {
        const defaultFilters = allDefaultFilters?.[type];

        return i === index && defaultFilters
          ? addActionToFilter(defaultFilters)
          : resource;
      });

      onChange(newFilters);
    },
    [index, resources, allDefaultFilters, onChange, addActionToFilter],
  );

  return (
    <AutomationResourceSelect
      value={resourceType || ""}
      onChange={updateResource}
    />
  );
};
