import { FC, useCallback } from "react";
import { FilterSelect } from "./FilterSelect";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { setCommitmentsRecommendationsSavingsPlanType } from "../../../../../../../../store/commitments/commitmentsSlice";
import { SavingsPlanType } from "../../../../../../../../services/cloudchipr.api";
import { getCommitmentsRecommendationsThunk } from "../../../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";
import { savingsPlansTypeLabels } from "../../../../utils/constants/labels";
import { commitmentsRecommendationsSavingsPlanTypeSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsSavingsPlanTypeSelector";

export const SavingsPlanTypeFilter: FC = () => {
  const dispatch = useAppDispatch();

  const value = useAppSelector(
    commitmentsRecommendationsSavingsPlanTypeSelector,
  );

  const changeHandler = useCallback(
    (type: string) => {
      dispatch(
        setCommitmentsRecommendationsSavingsPlanType(type as SavingsPlanType),
      );
      dispatch(getCommitmentsRecommendationsThunk());
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <FilterSelect
      value={value}
      label="Type"
      options={options}
      onChange={changeHandler}
    />
  );
};

const options = [
  {
    value: "compute",
    label: savingsPlansTypeLabels.compute,
  },
  {
    value: "ec2_instance",
    label: savingsPlansTypeLabels.ec2_instance,
  },
  {
    value: "sage_maker",
    label: savingsPlansTypeLabels.sage_maker,
  },
];
