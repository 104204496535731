import { FC } from "react";
import { useLocation } from "react-router-dom";
import { NavItem } from "../nav-item/NavItem";
import CommitmentsSvgIcon from "../../../../assets/images/icons/commitments/CommitmentsSvgIcon";
import { useGetUsersMeOrganisationsCurrentProviderOrganisationsQuery } from "../../../../services/cloudchipr.api";
import { useAppSelector } from "../../../../store/hooks";
import { navigationCollapsedSelector } from "../../../../store/common/selectors/navigation/navigationCollapsedSelector";
import AWSSvgIcon from "../../../../assets/images/logos/providers/AWSSvgIcon";

export const CommitmentsNavigationV2: FC = () => {
  const { data } = useGetUsersMeOrganisationsCurrentProviderOrganisationsQuery(
    undefined,
    {
      selectFromResult: ({ data }) => {
        return {
          data: data?.filter((org) => org.cloud_provider === "aws"),
        };
      },
    },
  );
  const navigationCollapsed = useAppSelector(navigationCollapsedSelector);

  const location = useLocation();
  const selected = location.pathname.startsWith("/commitments");
  const disabled = !data?.length;

  if (!data) {
    return null;
  }

  if ((data?.length ?? 0) < 2) {
    const firstItem = data.at(0);
    const orgId = firstItem?.organization_id ?? "";

    return (
      <NavItem
        primary="Commitments"
        icon={CommitmentsSvgIcon}
        selected={selected}
        navCollapsed={navigationCollapsed}
        disabled={disabled}
        to={`/commitments/utilization-and-coverage/${orgId}`}
        tooltipTitle={
          disabled
            ? "Currently, commitments are available only for AWS."
            : undefined
        }
      />
    );
  }

  return (
    <NavItem
      primary="Commitments"
      icon={CommitmentsSvgIcon}
      navCollapsed={navigationCollapsed}
      selected={selected}
    >
      {data?.map((org) => (
        <NavItem
          nested
          key={org.organization_id}
          primary={org.organization_id}
          icon={AWSSvgIcon}
          to={`/commitments/utilization-and-coverage/${org.organization_id}`}
        />
      ))}
    </NavItem>
  );
};
