import { FC, useMemo } from "react";
import { AccountOptionType } from "./utils/types/types";
import { AccountOptionLabel } from "./components/AccountOptionLabel";
import { accountOptionFilterFn } from "./utils/helpers/accountOptionFilterFn";
import { AccountSelectTriggerComponent } from "./components/AccountSelectTriggerComponent";
import {
  DropdownSelect,
  DropdownSelectProps,
} from "../select/dropdown-select/DropdownSelect";

type DropdownSelectPropsExtendedProps = Pick<
  DropdownSelectProps,
  "wrapperVariant" | "PopoverProps" | "error"
>;

interface AccountSelectProps extends DropdownSelectPropsExtendedProps {
  selectedValue: string | string[];
  disabled?: boolean;
  onChange: (value: string[]) => void;
  options: AccountOptionType[];
  singleSelect?: boolean;
  listWidth?: number;
}

export const AccountSelect: FC<AccountSelectProps> = ({
  selectedValue,
  disabled,
  onChange,
  options,
  singleSelect,
  listWidth = 300,
  wrapperVariant,
  PopoverProps,
  error,
}) => {
  const renderOptions = useMemo(() => {
    return options.map((option) => ({
      value: option.value,
      listItemHeight: option?.groupName?.includes(option.orgId ?? "")
        ? 80
        : undefined,
      disabled: option.disabled,
      rawValue: option,
      label: (
        <AccountOptionLabel
          option={option}
          showStatus={option.status === "deactivated"}
        />
      ),
    }));
  }, [options]);

  return (
    <DropdownSelect
      error={error}
      listWidth={listWidth}
      disabled={disabled}
      submitHandlerOnClose={onChange}
      options={renderOptions}
      singleSelect={singleSelect}
      filterFn={accountOptionFilterFn}
      label="Select the Cloud Account/s*"
      values={selectedValue as string[]}
      wrapperVariant={wrapperVariant ?? "dropdown"}
      PopoverProps={PopoverProps}
      TriggerComponent={AccountSelectTriggerComponent}
    />
  );
};
