import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { utilizationAndCoverageCoverageGroupingSelector } from "../../../../../../../../store/commitments/selectors/coverage-and-utilization/view-options/utilizationAndCoverageCoverageGroupingSelector";
import { setCoverageGrouping } from "../../../../../../../../store/commitments/commitmentsSlice";
import { getSavingsPlansForDataGridThunk } from "../../../../../../../../store/commitments/thunks/savings-plans/getSavingsPlansForDataGridThunk";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../services/cloudchipr.api";

export const CoverageDataGridGrouping: FC = () => {
  const dispatch = useAppDispatch();
  const grouping = useAppSelector(
    utilizationAndCoverageCoverageGroupingSelector,
  );

  const changeHandler = useCallback(
    (event: SelectChangeEvent) => {
      const grouping = event.target.value as SavingsPlanCoverageGrouping;

      if (!grouping) {
        return;
      }

      dispatch(setCoverageGrouping(grouping));
      dispatch(getSavingsPlansForDataGridThunk());
    },
    [dispatch],
  );

  return (
    <FormControl size="xsmall" sx={{ width: 135 }}>
      <InputLabel>Group By</InputLabel>

      <Select
        sx={selectSx}
        label="Group By"
        value={grouping}
        onChange={changeHandler}
      >
        <MenuItem value="resource_type">Resource Type</MenuItem>
        <MenuItem value="account">Account </MenuItem>
      </Select>
    </FormControl>
  );
};

const selectSx = {
  bgcolor: "white",
  "& .MuiOutlinedInput-notchedOutline": { borderColor: "grey.300" },
};
