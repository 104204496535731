import { GracePeriod } from "../../../../services/cloudchipr.api";
import { formatWorkflowNotificationsFromHtmlToSlack } from "../../../../components/pages/schedule/classic/utils/helpers/formatWorkflowNotificationsFromHtmlToSlack";

export const editGracePeriodForPayload = (gracePeriod?: GracePeriod) => {
  const updatedGracePeriod = formatWorkflowNotificationsFromHtmlToSlack(
    gracePeriod?.notifications,
  );

  return gracePeriod
    ? {
        ...gracePeriod,
        notifications: updatedGracePeriod,
      }
    : undefined;
};
