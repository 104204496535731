import { FC } from "react";
import { Stack, Typography, TypographyProps } from "@mui/material";
import { formatDate } from "../../../../../../../../../utils/helpers/date-time/datetime-format";
import { TooltipText } from "../../../../../../../../common/TooltipText";
import { SavingsPlanState } from "../../../../../../../../../services/cloudchipr.api";
import { savingsPlansStateLabels } from "../../../../../utils/constants/labels";

interface DatesCellProps {
  date: string;
  state?: SavingsPlanState;
  TypographyProps?: TypographyProps;
}

export const DatesCell: FC<DatesCellProps> = ({
  date,
  state,
  TypographyProps,
}) => {
  const title = formatDate(date, { type: "date" });
  const tooltip = `${formatDate(date, { type: "dateTimeWithComma" })} (UTC)`;

  return (
    <Stack>
      <TooltipText
        width="fit-content"
        tooltipTitle={tooltip}
        color={state === "expired" ? "text.disabled" : undefined}
        {...TypographyProps}
      >
        {title}
      </TooltipText>

      {state && (
        <Typography variant="caption" color={stateColors[state]}>
          {savingsPlansStateLabels[state]}
        </Typography>
      )}
    </Stack>
  );
};

const stateColors = {
  active: "success.light",
  expired: "text.disabled",
  expires_soon: "warning.main",
};
