import { FC } from "react";
import { Box, Stack, Typography, TypographyProps } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { green, red } from "@mui/material/colors";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { ProviderLogo } from "../../../../../../../../../common/provider-logo/ProviderLogo";
import { money } from "../../../../../../../../../../utils/numeral/money";
import { TrendChip } from "../../../../../../../../../common/chips/TrendChip";
import { ProviderType } from "../../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../../common/TypographyWithTooltip";
import { getNumeralViewItemsPaddings } from "../../../common/view-type/utils/helpers/getNumeralViewItemsPaddings";
interface LargestCostChangesWidgetSetupResourceItemProps {
  provider: ProviderType;
  label: string;
  totalCost: number;
  trend: number | null;
  difference: number | null;
  itemsCount: number;
}
export const LargestCostChangesWidgetSetupResourceItem: FC<
  LargestCostChangesWidgetSetupResourceItemProps
> = ({ provider, label, totalCost, trend, difference, itemsCount }) => {
  const style = styles.get(itemsCount < 5 ? "big" : "small");
  return (
    <Box
      p={getNumeralViewItemsPaddings(itemsCount)}
      width={itemsCount > 6 ? "50%" : "100%"}
      flexGrow="1"
      boxSizing="border-box"
    >
      <Stack
        spacing={1}
        bgcolor="grey.50"
        borderRadius={1}
        alignItems="center"
        justifyContent="space-between"
        height="100%"
        direction="row"
        px={style?.padding}
      >
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          flex="1 1 0"
          overflow="hidden"
        >
          <ProviderLogo provider={provider} />
          <TypographyWithTooltip title={label} {...style?.resourceName} />
        </Stack>

        <Stack flex="1 1 0">
          <Typography color="text.secondary" {...style?.totalCost}>
            Total Cost
          </Typography>
          <Typography {...style?.price}>{money(totalCost)}</Typography>
        </Stack>

        <Stack
          direction="row"
          spacing={2}
          justifyContent="flex-end"
          flex="1 1 0"
        >
          <Stack spacing={0.2} direction="row" alignItems="center">
            {!!trend &&
              (trend > 0 ? (
                <ArrowDropUpIcon
                  fontSize="small"
                  sx={{
                    color: red[700],
                  }}
                />
              ) : (
                <ArrowDropDownIcon
                  fontSize="small"
                  sx={{
                    color: green[700],
                  }}
                />
              ))}

            {difference !== null && (
              <Typography fontWeight="medium" {...style?.price}>
                {money(difference)}
              </Typography>
            )}
          </Stack>

          <TrendChip value={trend} />
        </Stack>
      </Stack>
    </Box>
  );
};

const styles = new Map<
  "big" | "small",
  {
    resourceName: TypographyProps;
    price: TypographyProps;
    totalCost: TypographyProps;
    padding: number;
  }
>([
  [
    "big",
    {
      resourceName: { variant: "h6", fontWeight: 400 },
      totalCost: { variant: "body1" },
      price: { variant: "h5" },
      padding: 3,
    },
  ],
  [
    "small",
    {
      resourceName: { variant: "body1" },
      totalCost: { variant: "caption" },
      price: { variant: "body2" },
      padding: 1,
    },
  ],
]);
