import { FC, Fragment, useCallback } from "react";
import { Button } from "@mui/material";
import { useDidMount } from "rooks";
import { ProviderType } from "../../../../../../services/cloudchipr.api";
import { useDialog } from "../../../../../../utils/hooks/useDialog.hook";
import { FilterTemplatesDialog } from "../../../../account/components/filter-templates/templates-table/FilterTemplatesDialog";
import { getOrganisationFilterSetsThunk } from "../../../../../../store/filters/thunks/filter-set/getOrganisationFilterSetsThunk";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { filterTemplatesSelector } from "../../../../../../store/filters/selectors/filter-set/filterTemplatesSelector";
import filterTemplateIcon from "../../../../../../assets/images/icons/filter_template.svg";

interface AccountsFilterTemplateDropdownProps {
  provider: ProviderType;
}

export const AccountsFilterTemplateDropdown: FC<
  AccountsFilterTemplateDropdownProps
> = ({ provider }) => {
  const dispatch = useAppDispatch();
  const {
    open: openFilterTemplate,
    openDialog: openFilterTemplateDialog,
    closeDialog: closeFilterTemplateDialog,
  } = useDialog();
  const filterSets = useAppSelector((state) =>
    filterTemplatesSelector(state, provider),
  );

  const filterTemplateClickHandler = useCallback(() => {
    openFilterTemplateDialog();
  }, [openFilterTemplateDialog]);

  useDidMount(() => {
    dispatch(getOrganisationFilterSetsThunk());
  });

  return (
    <Fragment>
      <Button
        variant="outlined"
        sx={{ textTransform: "none", px: 2.5 }}
        startIcon={<img src={filterTemplateIcon} alt="filter-template" />}
        size="small"
        onClick={filterTemplateClickHandler}
      >
        Manage Filter Templates
      </Button>
      <FilterTemplatesDialog
        filterSets={filterSets}
        provider={provider}
        onClose={closeFilterTemplateDialog}
        open={openFilterTemplate}
      />
    </Fragment>
  );
};
