import { coverageGroupedByCommitmentColumns } from "./coverage/coverageGroupedByCommitmentColumns";
import { coverageGroupedByResourceTypeColumns } from "./coverage/coverageGroupedByResourceTypeColumns";
import { savingsPlanGroupedByResourceTypeColumns } from "./savings-plan/savingsPlanGroupedByResourceTypeColumns";
import { coverageGroupedByAccountColumns } from "./coverage/coverageGroupedByAccountColumns";
import { savingsPlanGroupedByAccountColumns } from "./savings-plan/savingsPlanGroupedByAccountColumns";
import { ColumnSetupType } from "../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../utils/types";
import { SavingsPlanCoverageGrouping } from "../../../../../../../../../services/cloudchipr.api";
import { DataViewTab } from "../../../../../../../../../store/commitments/utils/types/types";

export const commitmentsDrawerDataGridColumns: Partial<
  Record<
    DataViewTab,
    Partial<
      Record<
        SavingsPlanCoverageGrouping,
        ColumnSetupType<SavingsPlanCoverageDataWithId>[]
      >
    >
  >
> = {
  coverage: {
    account: coverageGroupedByAccountColumns,
    commitment: coverageGroupedByCommitmentColumns,
    resource_type: coverageGroupedByResourceTypeColumns,
  },
  savingsPlans: {
    account: savingsPlanGroupedByAccountColumns,
    resource_type: savingsPlanGroupedByResourceTypeColumns,
  },
};
