import { createAsyncThunk } from "@reduxjs/toolkit";
import { TrendType } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { costBreakdownV2WidgetSetupSelector } from "../../../../selectors/setups/cost-breakdown-v2/costBreakdownV2WidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { getCostBreakdownV2WidgetSetupDataThunk } from "../getCostBreakdownV2WidgetSetupDataThunk";

export const costBreakdownV2TrendTypeChangeThunk = createAsyncThunk(
  "dashboards/costBreakdownTrendTypeChange",
  async (trendType: TrendType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const existingSetup = costBreakdownV2WidgetSetupSelector(state);

    if (!existingSetup) {
      return;
    }
    dispatch(
      setWidgetSetup({
        ...existingSetup,
        trendType,
      }),
    );

    dispatch(getCostBreakdownV2WidgetSetupDataThunk());
  },
);
