import { FC, Fragment, useEffect } from "react";
import { Stack } from "@mui/material";
import { useParams } from "react-router-dom";
import { useDidMount } from "rooks";
import { UtilizationAndCoverageMetadata } from "./components/metadata/UtilizationAndCoverageMetadata";
import { UtilizationAndCoverageContent } from "./components/content/UtilizationAndCoverageContent";
import { UtilizationAndCoverageDrawers } from "./components/drawers/UtilizationAndCoverageDrawers";
import { UtilizationAndCoveragePageHeader } from "./components/UtilizationAndCoveragePageHeader";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { ProviderBillingDataAccessController } from "../../common/billing/billing-data-access-controller/ProviderBillingDataAccessController";
import { missingSetupSelector } from "../../../../store/commitments/selectors/missingSetupSelector";
import { getAllProvidersBillingStatusThunk } from "../../../../store/common/thunks/billing-status/getAllProvidersBillingStatusThunk";
import { getSavingsPlansThunk } from "../../../../store/commitments/thunks/savings-plans/getSavingsPlansThunk";
import { setCommitmentsOrgId } from "../../../../store/commitments/commitmentsSlice";

export const UtilizationAndCoverage: FC = () => {
  const params = useParams();
  const orgId = params?.orgId ?? "";
  const dispatch = useAppDispatch();

  const missingSetup = useAppSelector(missingSetupSelector);

  useEffect(() => {
    dispatch(setCommitmentsOrgId(orgId));
    dispatch(getSavingsPlansThunk());
  }, [dispatch, orgId]);

  useDidMount(() => {
    dispatch(getAllProvidersBillingStatusThunk());
  });

  return (
    <Fragment>
      <UtilizationAndCoveragePageHeader />

      {missingSetup ? (
        <ProviderBillingDataAccessController provider="aws" />
      ) : (
        <Stack p={1} spacing={1}>
          <UtilizationAndCoverageMetadata />
          <UtilizationAndCoverageContent />
        </Stack>
      )}

      <UtilizationAndCoverageDrawers />
    </Fragment>
  );
};
