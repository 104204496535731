import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AutomationsData,
  AutomationsSlice,
  AutomationSteps,
  OffHoursSpecificData,
  WorkflowSpecificData,
} from "./utils/types/common";
import {
  automationsInitialData,
  offHoursInitialData,
  workflowInitialData,
} from "./utils/constants/common";

const initialState: AutomationsSlice = {
  config: {
    step: "setup",
    inDrawerMode: false,
    automationInitialized: false,
    automationType: null,
  },

  data: automationsInitialData,
  offHoursSpecific: null,
  workflowSpecific: null,
  errors: {},
};

export const automationsSlice = createSlice({
  name: "automations",
  initialState,
  reducers: {
    setAutomationData: (
      state,
      action: PayloadAction<Partial<AutomationsData>>,
    ) => {
      /* eslint-disable */
      // todo: typescript throws error because of `FilterGroup` in `ResourceFiltersWithAction=>ResourceFilters=>filter_groups` it has recursive type, typescript doesn't support that kind of recursive types
      // @ts-ignore
      state.data = { ...state.data, ...action.payload };
      /* eslint-enable */
    },

    setWorkflowSpecificData: (
      state,
      action: PayloadAction<Partial<WorkflowSpecificData>>,
    ) => {
      state.config.automationType = "workflow";
      state.workflowSpecific = {
        ...workflowInitialData,
        ...state.workflowSpecific,
        ...action.payload,
      };
    },

    setOffHoursSpecificData: (
      state,
      action: PayloadAction<Partial<OffHoursSpecificData>>,
    ) => {
      state.config.automationType = "offHours";
      state.offHoursSpecific = {
        ...offHoursInitialData,
        ...state.offHoursSpecific,
        ...action.payload,
      };
    },

    setAutomationStep: (state, action: PayloadAction<AutomationSteps>) => {
      state.config.step = action.payload;
    },

    setAutomationInitialized: (state, action: PayloadAction<boolean>) => {
      state.config.automationInitialized = action.payload;
    },

    setAutomationErrors: (
      state,
      action: PayloadAction<Record<string, string> | null>,
    ) => {
      if (action.payload === null) {
        state.errors = {};
      }

      state.errors = {
        ...state.errors,
        ...action.payload,
      };
    },

    setAutomationInDrawerMode: (state, action: PayloadAction<boolean>) => {
      state.config.inDrawerMode = action.payload;
    },

    resetAutomationsState: () => initialState,
  },
});

export const {
  setAutomationData,
  setWorkflowSpecificData,
  resetAutomationsState,
  setAutomationStep,
  setOffHoursSpecificData,
  setAutomationErrors,
  setAutomationInitialized,
  setAutomationInDrawerMode,
} = automationsSlice.actions;

export default automationsSlice.reducer;
