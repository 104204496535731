import { FC, useCallback } from "react";
import {
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { TrendType } from "../../../../../../../../../services/cloudchipr.api";
import { trendSettingsTitlesByType } from "../../../../../../../resource-explorer/components/app-bar/components/resource-explorer-toolbar/components/view-settings/utils/constants";
import { TrendSettingsExampleDates } from "../../../../../../../resource-explorer/components/app-bar/components/resource-explorer-toolbar/components/view-settings/components/TrendSettingsExampleDates";

interface WidgetTrendSettingsSelectProps {
  trendType: TrendType;
  onChange(trendType: TrendType): void;
}

export const WidgetTrendTypeSelect: FC<WidgetTrendSettingsSelectProps> = ({
  trendType,
  onChange,
}) => {
  const handleChange = useCallback(
    (event: SelectChangeEvent) => {
      onChange(event.target.value as TrendType);
    },
    [onChange],
  );

  const renderValue = useCallback((value: TrendType) => {
    if (!value) {
      return;
    }
    return trendSettingsTitlesByType.get(value);
  }, []);

  return (
    <FormControl size="small" fullWidth>
      <InputLabel>Trend</InputLabel>

      <Select
        size="small"
        fullWidth
        label="Trend"
        value={trendType}
        onChange={handleChange}
        renderValue={renderValue}
      >
        <MenuItem value="month_over_month">
          <ListItemText
            primary={trendSettingsTitlesByType.get("month_over_month")}
            secondary={
              <TrendSettingsExampleDates trendType="month_over_month" />
            }
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <MenuItem value="period_over_period">
          <ListItemText
            primary={trendSettingsTitlesByType.get("period_over_period")}
            secondary={
              <TrendSettingsExampleDates trendType="period_over_period" />
            }
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
      </Select>
    </FormControl>
  );
};
