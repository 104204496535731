import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { checkForOrganizationExistenceThunk } from "./checkForOrganizationExistenceThunk";
import { RootState } from "../../../store";
import { cloudChiprApi } from "../../../../services/cloudchipr.api";
import { commitmentsRecommendationsPayloadDataSelector } from "../../selectors/recommendations/commitmentsRecommendationsPayloadDataSelector";
import { getCommitmentsRecommendationsPostFixedCacheKey } from "../../utils/helpers/fixedCacheKeys";

export const getCommitmentsRecommendationsThunk = createAsyncThunk(
  "commitments/getCommitmentsRecommendations",
  async (_: void, { dispatch, getState }) => {
    dispatch(checkForOrganizationExistenceThunk());

    const state = getState() as RootState;

    const {
      postUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummary,
    } = cloudChiprApi.endpoints;

    const payload = commitmentsRecommendationsPayloadDataSelector(state);

    try {
      await dispatch(
        postUsersMeOrganisationsCurrentSavingsPlansRecommendationsSummary.initiate(
          { body: payload },
          {
            fixedCacheKey: getCommitmentsRecommendationsPostFixedCacheKey(
              payload.provider_organisation_id,
            ),
          },
        ),
      ).unwrap();
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
