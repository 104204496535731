import { FC } from "react";
import { Stack } from "@mui/material";
import { UtilizationAndCoverageMetadataCard } from "./UtilizationAndCoverageMetadataCard";
import { useAppSelector } from "../../../../../../store/hooks";
import { savingsPlansNetSavingsSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/totals/savingsPlansNetSavingsSelector";
import { savingsPlansCoverageAverageSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/totals/savingsPlansCoverageAverageSelector";
import { money } from "../../../../../../utils/numeral/money";
import { commitmentsForGranularLoadingSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/loading/commitmentsForGranularLoadingSelector";
import { useGetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsQuery } from "../../../../../../services/cloudchipr.api";
import { commitmentsCurrentOrgIdSelector } from "../../../../../../store/commitments/selectors/commitmentsCurrentOrgIdSelector";
import { savingsPlansGranularDataUncoveredUsageSelector } from "../../../../../../store/commitments/selectors/coverage-and-utilization/data/granular/totals/savingsPlansGranularDataUncoveredUsageSelector";

export const UtilizationAndCoverageMetadata: FC = () => {
  const orgId = useAppSelector(commitmentsCurrentOrgIdSelector);
  const netSavings = useAppSelector(savingsPlansNetSavingsSelector);
  const coverage = useAppSelector(savingsPlansCoverageAverageSelector);
  const loading = useAppSelector(commitmentsForGranularLoadingSelector);
  const uncoveredUsage = useAppSelector(
    savingsPlansGranularDataUncoveredUsageSelector,
  );

  const { data, isLoading } =
    useGetUsersMeOrganisationsCurrentSavingsPlansPotentialSavingsQuery({
      providerOrganisationId: orgId,
    });

  return (
    <Stack
      gap={2}
      direction="row"
      flexWrap="wrap"
      justifyContent="space-between"
    >
      <UtilizationAndCoverageMetadataCard
        primary="Not Covered(On-Demand)"
        loading={loading}
        value={money(uncoveredUsage)}
        tooltip="Eligible On-Demand spend over the selected time period that was not covered by a Savings Plan."
      />

      <UtilizationAndCoverageMetadataCard
        primary="Coverage AVG"
        loading={loading}
        value={coverage !== null ? `${coverage}%` : "N/A"}
        tooltip="Average percentage of AWS usage covered by Savings Plans during the selected date range."
      />

      <UtilizationAndCoverageMetadataCard
        primary="Net Savings"
        loading={loading}
        value={money(netSavings)}
        tooltip="Net Savings from commitments for the selected date range. Calculated by comparing the usage cost covered by Savings Plans to the public On-Demand cost."
      />

      <UtilizationAndCoverageMetadataCard
        primary="Potential Monthly Savings"
        loading={isLoading}
        valueColor="success.light"
        value={
          data?.savings_amount !== null
            ? `~${money(data?.savings_amount)}`
            : "N/A"
        }
        tooltip="Total amount of potential savings based on Savings Plan Recommendations."
      />
    </Stack>
  );
};
