import Typography from "@mui/material/Typography";
import { FC } from "react";
import { TrendType } from "../../../../../../../../../../services/cloudchipr.api";

interface TrendSettingsExampleDatesProps {
  trendType: TrendType;
}

export const TrendSettingsExampleDates: FC<TrendSettingsExampleDatesProps> = ({
  trendType,
}) => {
  return (
    <Typography variant="caption" color="text.secondary" lineHeight={1.25}>
      E.g.{" "}
      <Typography variant="inherit" fontWeight="bold" component="span">
        06 Aug 2024 - 20 Aug 2024
      </Typography>{" "}
      compared with{" "}
      <Typography variant="inherit" fontWeight="bold" component="span">
        {trendType === "month_over_month"
          ? "06 Jul 2024 - 20 Jul 2024"
          : "23 Jul 2024 - 5 Aug 2024"}
      </Typography>
    </Typography>
  );
};
