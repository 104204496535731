import { FC, useCallback } from "react";
import { useToggle } from "rooks";
import { Card, Collapse, Stack } from "@mui/material";
import { CategoryCollapseHeader } from "./CategoryCollapseHeader";
import { DynamicFilters } from "../../../../../../common/dynamic-filters/components/DynamicFilters";
import { DimensionCategory } from "../../../../../../../../store/dimensions/utils/types/common";
import { DynamicFiltersType } from "../../../../../../common/dynamic-filters/utils/types/common";
import { useAppDispatch } from "../../../../../../../../store/hooks";
import { setCategoryFilters } from "../../../../../../../../store/dimensions/dimensionsSlice";
import { useGetUsersMeResourceExplorerPossibleFiltersQuery } from "../../../../../../../../services/cloudchipr.api";

interface CategoryItemProps {
  category: DimensionCategory;
  deleteDisabled?: boolean;
}

export const CategoryItem: FC<CategoryItemProps> = ({
  category,
  deleteDisabled,
}) => {
  const { data } = useGetUsersMeResourceExplorerPossibleFiltersQuery({
    providersOnly: true,
  });

  const dispatch = useAppDispatch();
  const [collapsed, toggleCollapsed] = useToggle();

  const filtersChangeHandler = useCallback(
    (filters: DynamicFiltersType) => {
      dispatch(setCategoryFilters({ id: category.id, filters }));
    },
    [dispatch, category.id],
  );

  if (!data) {
    return null;
  }

  return (
    <Card variant="outlined">
      <CategoryCollapseHeader
        title="Filters"
        collapsed={collapsed}
        category={category}
        deleteDisabled={!!deleteDisabled}
        toggleCollapsed={toggleCollapsed}
      />

      <Collapse in={!collapsed}>
        <Stack p={1} pt={0}>
          <DynamicFilters
            possibleFilters={data}
            filters={category.filters}
            setFilters={filtersChangeHandler}
          />
        </Stack>
      </Collapse>
    </Card>
  );
};
