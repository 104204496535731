import { FC, Fragment } from "react";
import { CategoryItem } from "./CategoryItem";
import { useAppSelector } from "../../../../../../../../store/hooks";
import { dimensionCategoriesEntriesSelector } from "../../../../../../../../store/dimensions/selectors/slice-data/dimensionCategoriesEntriesSelector";

export const DimensionsCategoriesList: FC = () => {
  const categoriesEntries = useAppSelector(dimensionCategoriesEntriesSelector);

  return (
    <Fragment>
      {categoriesEntries.map(([id, category]) => {
        return (
          <CategoryItem
            key={id}
            category={category}
            deleteDisabled={categoriesEntries.length === 1}
          />
        );
      })}
    </Fragment>
  );
};
