import {
  SavingsPlanCoverageGrouping,
  SavingsPlanFilterType,
} from "../../../../../../services/cloudchipr.api";

type GroupingType = { value: SavingsPlanCoverageGrouping; label: string };

const resourceType: GroupingType = {
  value: "resource_type",
  label: "Covered Resource Types",
};
const account: GroupingType = {
  value: "account",
  label: "Accounts",
};
const commitment: GroupingType = {
  value: "commitment",
  label: "Savings Plans",
};

export const drawerDataGridPossibleGroupings: Record<
  SavingsPlanFilterType,
  { value: SavingsPlanCoverageGrouping; label: string }[]
> = {
  savings_plan: [resourceType, account],
  account: [resourceType, commitment],
  resource_type: [commitment, account],
};
