import { FC, useCallback } from "react";
import { CellContext } from "@tanstack/react-table";
import { DeleteDimensionConfirmationDialog } from "./DeleteDimensionConfirmationDialog";
import {
  ActionsMenu,
  ActionsMenuConfirmationDialogProps,
} from "../../common/ActionsMenu";
import { generateDimensionCategoriesFromCategoriesWithCosts } from "../../../../utils/helpers/generateDimensionCategoriesFromCategoriesWithCosts";
import { DimensionWithCosts } from "../../../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../../../store/hooks";
import { setDimensionsState } from "../../../../../../../store/dimensions/dimensionsSlice";

export const DimensionActions: FC<CellContext<DimensionWithCosts, any>> = ({
  row,
}) => {
  const dimension = row.original;
  const dispatch = useAppDispatch();

  const editDialogOpenHandler = useCallback(() => {
    dispatch(
      setDimensionsState({
        drawerMode: "dimensionEdit",
        dimensionId: dimension.id,
        dimensionName: dimension.name,
        categories: generateDimensionCategoriesFromCategoriesWithCosts(
          dimension.categories,
        ),
      }),
    );
  }, [dispatch, dimension]);

  const ConfirmationDialog = useCallback(
    ({ open, onClose }: ActionsMenuConfirmationDialogProps) => {
      return (
        <DeleteDimensionConfirmationDialog
          name={dimension.name}
          open={open}
          dimensionId={dimension.id}
          categories={dimension.categories}
          onClose={onClose}
        />
      );
    },
    [dimension],
  );

  return (
    <ActionsMenu
      onEditOpen={editDialogOpenHandler}
      ConfirmationDialog={ConfirmationDialog}
    />
  );
};
