import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../../../store";
import { getWidgetDataByViewIdThunk } from "../common/getWidgetDataByViewIdThunk";
import { costAndUsageSumWidgetByIdSelector } from "../../../selectors/widgets/cost-and-usage/costAndUsageSumWidgetByIdSelector";

export const fetchCostAndUsageSumWidgetDataByWidgetIdThunk = createAsyncThunk(
  "dashboards/fetchCostAndUsageWidgetDataByWidgetId",
  async (widgetId: string, { dispatch, getState }) => {
    const state = getState() as RootState;

    const widget = costAndUsageSumWidgetByIdSelector(state, widgetId);

    if (!widget) {
      return;
    }

    await dispatch(
      getWidgetDataByViewIdThunk({
        widgetType: widget.type,
        widgetId: widgetId,
        resourceExplorerViewId: widget.view_id,
        dateTo: widget.date_to,
        dateFrom: widget.date_from,
        dateLabel: widget.date_label ?? null,
        onlySummary: true,
        trendType: widget.trend_type,
      }),
    );
  },
);
