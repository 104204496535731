import { createAsyncThunk } from "@reduxjs/toolkit";
import { enqueueSnackbar } from "notistack";
import { fetchCostBreakdownWidgetDataByWidgetIdThunk } from "./fetchCostBreakdownWidgetDataByWidgetIdThunk";
import { DashboardWidgetCostBreakdownRequest } from "../../../../../services/cloudchipr.api";
import { currentDashboardIdSelector } from "../../../selectors/dashboard/currentDashboardIdSelector";
import { RootState } from "../../../../store";
import { costBreakdownWidgetSetupSelector } from "../../../selectors/setups/cost-breakdown/costBreakdownWidgetSetupSelector";
import { buildWidgetThunk } from "../common/buildWidgetThunk";

export const buildCostBreakdownWidgetThunk = createAsyncThunk(
  "dashboards/buildCostBreakdownWidget",
  async (_, { dispatch, getState }) => {
    const state = getState() as RootState;

    const dashboardId = currentDashboardIdSelector(state);
    const setup = costBreakdownWidgetSetupSelector(state);

    if (!dashboardId || !setup?.viewId) {
      return;
    }

    const widget: DashboardWidgetCostBreakdownRequest = {
      name: setup.name,
      date_to: setup.date.to,
      view_id: setup.viewId,
      grouping: setup.grouping,
      group_values: setup.group_values ?? undefined,
      date_from: setup.date.from,
      date_label: setup.date.label,
      type: "cost_breakdown",
      date_granularity: setup.frequency,
      chart_type: setup.viewType,
      layout: null,
      forecast_option: setup.forecastOption,
      trend_type: setup.trendType,
    };

    try {
      const response = await dispatch(
        buildWidgetThunk({
          widgetId: setup?.id,
          widget,
        }),
      ).unwrap();

      if (response && setup?.id) {
        await dispatch(
          fetchCostBreakdownWidgetDataByWidgetIdThunk(response.id),
        );
      }
      return response?.id;
    } catch (e) {
      // @ts-expect-error todo: api fix
      const errMessage = e?.data?.message || "Something went wrong";
      enqueueSnackbar(errMessage, {
        variant: "snackbarAlert",
        AlertSnackBarProps: { severity: "error" },
      });

      return null;
    }
  },
);
