import { Stack, Typography } from "@mui/material";
import { getCoverageColumn } from "./common/getCoverageColumn";
import { uncoveredUsageColumn } from "./common/uncoveredUsageColumn";
import { coveredUsageColumn } from "./common/coveredUsageColumn";
import { netSavingsColumn } from "./common/netSavingsColumn";
import { SavingsPlanCoverageDataWithId } from "../../../../../../utils/types";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";

export const coverageGroupedByAccountColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      accessorKey: "account",
      header: "Account",
      cell: ({ row }) => {
        return (
          <Stack>
            <Typography variant="body2">
              {row.original.account?.name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {row.original.account?.provider_account_id}
            </Typography>
          </Stack>
        );
      },
    },
    coveredUsageColumn,
    uncoveredUsageColumn,
    netSavingsColumn,
    getCoverageColumn("account"),
  ];
