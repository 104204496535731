import { useCallback } from "react";
import {
  ResourceFilters,
  ResourceFiltersWithAction,
} from "../../../../../../services/cloudchipr.api";
import { useAllResourcesVisibility } from "../../../../schedule/common/utils/hooks/useAllResourcesVisibility.hook";
import { CleanActionTypes } from "../../../../../../utils/clean-options";
import { getActionableResourceTypeByParentResourceType } from "../../../../../../utils/helpers/resources/getActionableResourceTypeByParentResourceType";
import { useAppSelector } from "../../../../../../store/hooks";
import { automationProviderSelector } from "../../../../../../store/automations/selectros/common/fields/automationProviderSelector";
import { automationAccountIdsSelector } from "../../../../../../store/automations/selectros/common/fields/automationAccountIdsSelector";
import { automationTypeSelector } from "../../../../../../store/automations/selectros/common/automationTypeSelector";

export function useFilterActionsAdder() {
  const provider = useAppSelector(automationProviderSelector);
  const accountIds = useAppSelector(automationAccountIdsSelector);
  const automationType = useAppSelector(automationTypeSelector);

  const resourcesVisibility = useAllResourcesVisibility({
    accountIds: accountIds.join(", "),
    provider,
  });

  return useCallback(
    (filter: ResourceFilters): ResourceFiltersWithAction => {
      const actionName = (
        automationType === "offHours" ? "stop" : "delete"
      ) as CleanActionTypes;

      const actionsAvailability =
        Object.values(resourcesVisibility)?.at(0)?.[
          getActionableResourceTypeByParentResourceType(filter.type)
        ]?.actions;

      if (actionsAvailability?.[actionName] !== null) {
        return {
          filter,
          action: actionName === "delete" ? "terminate" : "stop",
        };
      }

      return { filter };
    },
    [automationType, resourcesVisibility],
  );
}
