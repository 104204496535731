import { RootState } from "../../../../store";
import { automationSliceSelector } from "../../common/automationSliceSelector";
import { PostV2UsersMeSchedulesApiArg } from "../../../../../services/cloudchipr.api";
import { formatWorkflowNotificationsFromHtmlToSlack } from "../../../../../components/pages/schedule/classic/utils/helpers/formatWorkflowNotificationsFromHtmlToSlack";
import { generateCronForPayload } from "../../../utils/helpers/cron/generateCronForPayload";
import { editGracePeriodForPayload } from "../../../utils/helpers/editGracePeriodForPayload";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";

export const workflowPayloadDataSelector = (
  state: RootState,
): PostV2UsersMeSchedulesApiArg["body"] => {
  const slice = automationSliceSelector(state);

  const automationData = slice.data;
  const workflowData = slice.workflowSpecific;
  const frequency = automationData.frequency;
  const repeatValue = workflowData?.scheduler?.repeatValue;
  const runTime = workflowData?.scheduler?.runTime ?? null;
  const weekDays = workflowData?.scheduler?.weekDays;

  const startDate = automationData.startDate
    ? formatDate(automationData.startDate)
    : null;
  const formatedRunTime = runTime ? formatDate(runTime) : null;

  const startDateTime = frequency === "once" ? formatedRunTime : startDate;

  const cron = generateCronForPayload(
    frequency,
    runTime,
    repeatValue,
    weekDays,
  );

  const updatedNotifications = formatWorkflowNotificationsFromHtmlToSlack(
    automationData.notifications,
  );

  const gracePeriod = editGracePeriodForPayload(workflowData?.gracePeriod);

  return {
    name: automationData.name,
    description: automationData.description || null,

    cron,
    start_date_time: startDateTime,
    end_date_time: automationData.endDate ?? null,
    time_zone: automationData.timeZone,
    weekly_schedule_interval:
      frequency === "weekly" && repeatValue ? +repeatValue : null,
    daily_schedule_interval:
      frequency === "daily" && repeatValue ? +repeatValue : null,

    account_ids: automationData.accountIds,
    regions: automationData.regions,

    action: workflowData?.action ?? "notify",
    is_snoozed: workflowData?.snoozed,
    min_threshold: automationData?.threshold,
    grace_period: gracePeriod,
    notifications: updatedNotifications,
    emails: automationData.emails,

    filter: automationData.filter,
  };
};
