import { Typography } from "@mui/material";
import { ColumnSetupType } from "../../../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../../utils/types";
import { money } from "../../../../../../../../../../../utils/numeral/money";

export const coveredUsageColumn: ColumnSetupType<SavingsPlanCoverageDataWithId> =
  {
    accessorKey: "covered_usage",
    header: () => (
      <Typography
        align="right"
        width="100%"
        variant="body2"
        fontWeight="medium"
      >
        Spend Covered by Savings Plans
      </Typography>
    ),
    meta: { headerTitle: "Spend Covered by Savings Plans" },
    cell: ({ row }) => (
      <Typography align="right" pr={4} variant="body2">
        {money(row.original.covered_usage)}
      </Typography>
    ),
  };
