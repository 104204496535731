import { createAsyncThunk } from "@reduxjs/toolkit";
import { closeSnackbar, enqueueSnackbar, SnackbarKey } from "notistack";

import { getResourceExplorerVisibilityHierarchyThunk } from "./getResourceExplorerVisibilityHierarchyThunk";
import {
  cloudChiprApi,
  PostV2UsersMeOrganisationsCurrentDashboardsApiArg,
} from "../../../../services/cloudchipr.api";
import { enabledProvidersSelector } from "../../../common/selectors/billing-analytics/enabled-providers/enabledProvidersSelector";
import { RootState } from "../../../store";
import { createResourceExplorerThunkFixedCacheKey } from "../resource-explorer/createResourceExplorerThunk";
import { resourceExplorerAppliedFiltersToSaveOnViewSelector } from "../../selectors/filters/resourceExplorerAppliedFiltersToSaveOnViewSelector";
import { resourceExplorerProvidersSelector } from "../../selectors/current-resource-explorer/resourceExplorerProvidersSelector";

interface CreateResourceExplorerVisibilityHierarchyItemThunkArgs {
  name: string;
  visibility: PostV2UsersMeOrganisationsCurrentDashboardsApiArg["body"]["visibility"];
  refetch?: boolean;
  viewId?: string;
}
export const createResourceExplorerVisibilityHierarchyItemThunk =
  createAsyncThunk(
    "resourceExplorer/createResourceExplorerVisibilityHierarchyItem",
    async (
      {
        name,
        visibility,
        refetch,
        viewId,
      }: CreateResourceExplorerVisibilityHierarchyItemThunkArgs,
      { dispatch, getState },
    ) => {
      const { postV2UsersMeOrganisationsCurrentResourceExplorerViews } =
        cloudChiprApi.endpoints;

      const state = getState() as RootState;
      const enableProviders = enabledProvidersSelector(state);
      const resourceExplorerProviders =
        resourceExplorerProvidersSelector(state);
      const mutatedFilters =
        resourceExplorerAppliedFiltersToSaveOnViewSelector(state);
      let response;

      try {
        response = await dispatch(
          postV2UsersMeOrganisationsCurrentResourceExplorerViews.initiate(
            {
              body: {
                name: name,
                cloud_providers: viewId
                  ? resourceExplorerProviders
                  : Array.from(enableProviders),
                filters: !viewId ? [] : mutatedFilters,
                visibility,
              },
            },
            { fixedCacheKey: createResourceExplorerThunkFixedCacheKey },
          ),
        ).unwrap();

        if (response) {
          if (refetch) {
            dispatch(getResourceExplorerVisibilityHierarchyThunk());
          }

          const snackbarId: SnackbarKey = enqueueSnackbar(
            "View successfully created.",
            {
              variant: "snackbarAlert",
              AlertSnackBarProps: {
                severity: "success",
                onClose: () => closeSnackbar(snackbarId),
              },
            },
          );
        }
        return response;
      } catch (e) {
        // @ts-expect-error TODO: return to this after adding error type
        const errMessage = e?.data?.message || "Something went wrong";
        const snackbarId: SnackbarKey = enqueueSnackbar(errMessage, {
          variant: "snackbarAlert",
          AlertSnackBarProps: {
            severity: "error",
            onClose: () => closeSnackbar(snackbarId),
          },
        });
      }
    },
  );
