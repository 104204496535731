import { FC, useCallback } from "react";
import { FilterSelect } from "./FilterSelect";
import { setCommitmentsRecommendationsPaymentOption } from "../../../../../../../../store/commitments/commitmentsSlice";
import { SavingsPlanPaymentOption } from "../../../../../../../../services/cloudchipr.api";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../../../../store/hooks";
import { savingsPlansPaymentOptionLabels } from "../../../../utils/constants/labels";
import { commitmentsRecommendationsPaymentOptionSelector } from "../../../../../../../../store/commitments/selectors/recommendations/filters/commitmentsRecommendationsPaymentOptionSelector";
import { getCommitmentsRecommendationsThunk } from "../../../../../../../../store/commitments/thunks/recommendations/getCommitmentsRecommendationsThunk";

export const PaymentOptionsFilter: FC = () => {
  const dispatch = useAppDispatch();
  const value = useAppSelector(commitmentsRecommendationsPaymentOptionSelector);

  const changeHandler = useCallback(
    (option: string) => {
      dispatch(
        setCommitmentsRecommendationsPaymentOption(
          option as SavingsPlanPaymentOption,
        ),
      );
      dispatch(getCommitmentsRecommendationsThunk());
    },
    [dispatch],
  );

  if (!value) {
    return null;
  }

  return (
    <FilterSelect
      value={value}
      label="Payment Options"
      options={options}
      onChange={changeHandler}
    />
  );
};

const options = [
  {
    value: "all_upfront",
    label: savingsPlansPaymentOptionLabels.all_upfront,
  },
  {
    value: "partial_upfront",
    label: savingsPlansPaymentOptionLabels.partial_upfront,
  },
  {
    value: "no_upfront",
    label: savingsPlansPaymentOptionLabels.no_upfront,
  },
];
