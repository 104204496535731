import { FC } from "react";
import { Row } from "@tanstack/react-table";
import { Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { CommitmentsProgressBar } from "../../../../common/CommitmentsProgressBar";
import { SavingsPlan } from "../../../../../../../../../services/cloudchipr.api";
import { getPercentage } from "../../../../../../../../../utils/numeral/getPercentage";
import { money } from "../../../../../../../../../utils/numeral/money";
import { getColorByValue } from "../../utils/helpers";

interface UtilizationCellProps {
  row: Row<SavingsPlan>;
}

export const UtilizationCell: FC<UtilizationCellProps> = ({ row }) => {
  const used = row.original.used_commitment_to_date;
  const total = row.original.total_commitment_to_date;
  const state = row.original.state;
  const expired = state === "expired";
  const percent = +getPercentage(used ?? 0, total ?? 0).toFixed(2);
  const color = getColorByValue(percent);

  return (
    <Stack>
      <Stack direction="row" spacing={0.5}>
        <Typography
          variant="body2"
          fontWeight="medium"
          color={expired ? "text.secondary" : color}
        >
          {money(used)}
        </Typography>
        <Typography variant="body2" fontWeight="medium">
          / {money(total)}
        </Typography>
      </Stack>

      <CommitmentsProgressBar
        value={percent}
        overrideColor={expired ? grey[500] : undefined}
      />
    </Stack>
  );
};
