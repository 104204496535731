import { FC, useCallback, useMemo } from "react";
import {
  ResourceExplorerDynamicFilterItem,
  ResourceExplorerDynamicFilterItemType,
  ResourceExplorerFilterProvider,
  useGetUsersMeOrganisationsCurrentResourceExplorerFiltersByFilterProviderAndFilterTypeValuesQuery as useValuesQuery,
} from "../../../../../../../../../services/cloudchipr.api";
import { TypographyWithTooltip } from "../../../../../../../../common/TypographyWithTooltip";
import { AutocompleteSelect } from "../../../../../../../../common/select/autocomplete-select/AutocompleteSelect";
import { DropdownSelectOption } from "../../../../../../../../common/select/dropdown-select/utils/types/types";

interface FilterValueAutocompleteProps {
  singleSelect?: boolean;
  filterType: ResourceExplorerDynamicFilterItemType;
  filterValue: string[];
  provider: ResourceExplorerFilterProvider;
  onChange(value: ResourceExplorerDynamicFilterItem["value"]): void;
}

export const FilterValueAutocomplete: FC<FilterValueAutocompleteProps> = ({
  provider,
  filterType,
  onChange,
  filterValue,
  singleSelect,
}) => {
  const { data, isLoading } = useValuesQuery({
    filterProvider: provider,
    filterType: filterType,
  });

  const renderOptions = useMemo(() => {
    return data?.map((option) => ({
      rawValue: option,
      value: option.value,
      label: <TypographyWithTooltip title={option.title ?? option.value} />,
    }));
  }, [data]);

  const formatSelectedLabel = useCallback(
    (value: string) =>
      data?.find((option) => option.value === value)?.title ?? value,
    [data],
  );

  return (
    <AutocompleteSelect
      wrapperVariant="popover"
      singleSelect={singleSelect}
      size="xsmall"
      label="Value"
      placeholder="Value"
      onChange={onChange}
      filterFn={filterFn}
      values={filterValue}
      optionsLoading={isLoading}
      options={renderOptions ?? []}
      formatSelectedLabel={formatSelectedLabel}
      PopoverProps={{ extendAnchorWidth: true }}
    />
  );
};

const filterFn = (option: DropdownSelectOption, keyword: string) => {
  const aim = `${option.rawValue.value} ${option.rawValue.title}`.toLowerCase();

  return aim.includes(keyword.toLowerCase().trim());
};
