import { FC, useMemo } from "react";
import { TextField } from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { FilterTriggerComponentProps } from "../../../../../../../../common/select/dropdown-select/utils/types/filterTriggerComponentProps";
import { useDynamicFiltersContext } from "../../../../DynamicFiltersProvider";
import { ResourceExplorerFilterProvider } from "../../../../../../../../../services/cloudchipr.api";
import { findOptionByProviderAndKey } from "../../../../../utils/helpers/findOptionByProviderAndKey";

interface FilterTypeSelectTriggerComponentProps
  extends FilterTriggerComponentProps {
  provider: ResourceExplorerFilterProvider;
}

export const FilterTypeSelectTriggerComponent: FC<
  FilterTypeSelectTriggerComponentProps
> = ({ selectedValues, onClick, error, disabled, open, provider }) => {
  const { possibleFilters } = useDynamicFiltersContext();

  const value = useMemo(() => {
    return findOptionByProviderAndKey(
      possibleFilters,
      provider,
      selectedValues.at(0),
    )?.label;
  }, [provider, possibleFilters, selectedValues]);

  if (!value) {
    return null;
  }

  return (
    <TextField
      fullWidth
      label="Filter"
      size="xsmall"
      focused={false}
      error={!!error}
      disabled={disabled}
      onClick={onClick}
      value={value}
      InputProps={{
        endAdornment: open ? (
          <ArrowDropUpIcon fontSize="small" sx={{ color: "action" }} />
        ) : (
          <ArrowDropDownIcon fontSize="small" sx={{ color: "action" }} />
        ),
      }}
    />
  );
};
