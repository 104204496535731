import { createAsyncThunk } from "@reduxjs/toolkit";
import { TrendType } from "../../../../../../services/cloudchipr.api";
import { RootState } from "../../../../../store";
import { costAndUsageSumWidgetSetupSelector } from "../../../../selectors/setups/cost-and-usage/costAndUsageSumWidgetSetupSelector";
import { setWidgetSetup } from "../../../../dashboardsSlice";
import { fetchCostAndUsageSumWidgetDataBySetupThunk } from "../fetchCostAndUsageSumWidgetDataBySetupThunk";

export const costAndUsageSumTrendTypeChangeThunk = createAsyncThunk(
  "dashboards/costAndUsageTrendTypeChange",
  async (trendType: TrendType, { dispatch, getState }) => {
    const state = getState() as RootState;
    const setup = costAndUsageSumWidgetSetupSelector(state);

    if (!setup) {
      return;
    }
    dispatch(setWidgetSetup({ ...setup, trendType }));
    dispatch(fetchCostAndUsageSumWidgetDataBySetupThunk());
  },
);
