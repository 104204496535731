import { Typography } from "@mui/material";
import { getCoverageColumn } from "./common/getCoverageColumn";
import { netSavingsColumn } from "./common/netSavingsColumn";
import { coveredUsageColumn } from "./common/coveredUsageColumn";
import { ColumnSetupType } from "../../../../../../../../../../storybook/data-grid/utils/types/types";
import { SavingsPlanCoverageDataWithId } from "../../../../../../utils/types";

export const coverageGroupedByCommitmentColumns: ColumnSetupType<SavingsPlanCoverageDataWithId>[] =
  [
    {
      id: "savings_plan_id",
      header: "Savings Plan ID",
      accessorKey:
        "savings_plan.provider_id" as keyof SavingsPlanCoverageDataWithId,
      cell: ({ row }) => (
        <Typography variant="body2">
          {row.original.savings_plan?.provider_id}
        </Typography>
      ),
    },
    coveredUsageColumn,
    netSavingsColumn,
    getCoverageColumn("commitment"),
  ];
