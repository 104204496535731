import { Fragment, useCallback, useState } from "react";
import { Switch } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { useAppAbility } from "../../../../../services/permissions";
import {
  Schedule,
  OffHoursSchedule,
} from "../../../../../services/cloudchipr.api";
import { useAppDispatch } from "../../../../../store/hooks";
import { useDialog } from "../../../../../utils/hooks/useDialog.hook";
import { toggleScheduledJobsStatusThunk } from "../../../../../store/schedules/thunks/toggleScheduledJobsStatusThunk";
import { toggleOffHoursStatusThunk } from "../../../../../store/schedules/thunks/toggleOffHoursStatusThunk";
import { WithoutAccountsWorkflowDialog } from "../../classic/components/WithoutAccountsWorkflowDialog";
import { WithoutAccountsOffHoursDialog } from "../../off-hours/components/WithoutAccountsOffHoursDialog";
import { ScheduleDisablingDialog } from "../../classic/components/scheduled-jobs-list-grid/ScheduleDisablingDialog";
import { getScheduleSelectedResourcesThunk } from "../../../../../store/schedules/thunks/getScheduleSelectedResourcesThunk";

type ScheduleType<T> = T extends "schedule" ? Schedule : OffHoursSchedule;

interface ScheduledJobsListGridActivationSwitchCellProps<T> {
  schedule: ScheduleType<T>;
  type: T;
}

export const SchedulesDataGridActivationSwitchCell = <
  T extends "schedule" | "off-hours",
>({
  schedule,
  type,
}: ScheduledJobsListGridActivationSwitchCellProps<T>) => {
  const { cannot } = useAppAbility();

  const dispatch = useAppDispatch();
  const canNotEditSchedule = cannot("edit", "schedule");

  const [checked, setChecked] = useState(schedule.status === "active");
  const { open, openDialog, closeDialog } = useDialog();
  const {
    open: disablingDialogOpen,
    openDialog: openDisablingDialog,
    closeDialog: closeDisablingDialog,
  } = useDialog();

  const resourcesFetchHandler = useCallback(() => {
    const accountIds = schedule.accounts.map((account) => account.id);

    schedule.filter?.forEach(({ filter }) => {
      dispatch(
        getScheduleSelectedResourcesThunk({
          scheduleId: schedule.id,
          accountIds,
          resourceFilters: filter,
          regions: schedule.regions,
        }),
      );
    });
  }, [dispatch, schedule]);

  const toggleScheduleStatus = useCallback(async () => {
    try {
      setChecked(!checked);
      const status = checked ? "inactive" : "active";

      if (type === "schedule") {
        await dispatch(
          toggleScheduledJobsStatusThunk({
            schedule: schedule as Schedule,
            status,
          }),
        ).unwrap();

        closeDisablingDialog();
      } else {
        await dispatch(
          toggleOffHoursStatusThunk({
            schedule: schedule as OffHoursSchedule,
            status,
          }),
        ).unwrap();
      }

      resourcesFetchHandler();
    } catch (e) {
      setChecked(schedule.status === "active");

      enqueueSnackbar("Something went wrong", {
        variant: "snackbarAlert",
        AlertSnackBarProps: {
          severity: "error",
        },
      });
    }
  }, [
    checked,
    resourcesFetchHandler,
    schedule,
    type,
    dispatch,
    closeDisablingDialog,
  ]);

  const toggleHandler = useCallback(() => {
    const isWithoutAccounts =
      schedule.status !== "active" &&
      schedule.accounts.every(({ status }) => status !== "connected");

    if (isWithoutAccounts) {
      openDialog();
    } else if (
      checked &&
      type === "schedule" &&
      "grace_period" in schedule &&
      !!schedule.grace_period
    ) {
      openDisablingDialog();
    } else {
      toggleScheduleStatus();
    }
  }, [
    toggleScheduleStatus,
    openDisablingDialog,
    checked,
    schedule,
    openDialog,
    type,
  ]);

  return (
    <Fragment>
      <Switch
        size="small"
        checked={checked}
        onChange={toggleHandler}
        disabled={canNotEditSchedule}
      />

      {type === "schedule" && open && (
        <WithoutAccountsWorkflowDialog
          onClose={closeDialog}
          workflow={schedule as Schedule}
        />
      )}

      {type === "schedule" && disablingDialogOpen && (
        <ScheduleDisablingDialog
          scheduleId={schedule.id}
          onClose={closeDisablingDialog}
          accountIds={schedule.accounts.map((account) => account.id)}
          resourceFilters={schedule.filter}
          open={disablingDialogOpen}
          resourcesFetchHandler={resourcesFetchHandler}
          onSubmit={toggleScheduleStatus}
        />
      )}

      {type === "off-hours" && open && (
        <WithoutAccountsOffHoursDialog
          onClose={closeDialog}
          offHours={schedule as OffHoursSchedule}
        />
      )}
    </Fragment>
  );
};
