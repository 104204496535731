import { FC, useCallback, useState } from "react";
import {
  Dialog,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { FilterTemplatesRow } from "./FilterTemplatesRow";
import { DialogTitleClosable } from "../../../../../common/dialog-utils/DialogTitleClosable";
import { useAppDispatch } from "../../../../../../store/hooks";
import { getCurrentAccountFilterTemplatesThunk } from "../../../../../../store/filters/thunks/filter-set/getCurrentAccountFilterTemplatesThunk";
import {
  AccountFilterSetV2,
  FilterSet,
  ProviderType,
} from "../../../../../../services/cloudchipr.api";

interface FilterTemplatesDialogProps {
  open: boolean;
  onClose(): void;
  filterSets?: FilterSet[];
  accountFilterSets?: AccountFilterSetV2[];
  provider?: ProviderType;
  onApplyCallback?(): void;
}

export const FilterTemplatesDialog: FC<FilterTemplatesDialogProps> = ({
  onClose,
  open,
  onApplyCallback,
  filterSets,
  accountFilterSets,
  provider,
}) => {
  const dispatch = useAppDispatch();
  const [editingRowId, setEditingRowId] = useState("");
  const [duplicatingRowId, setDuplicatingRowId] = useState("");

  const editOrDuplicateMode = !!(duplicatingRowId || editingRowId);
  const showEms = !!accountFilterSets && !editOrDuplicateMode;
  const showEqualisingCell =
    !!editingRowId || !!duplicatingRowId || !!accountFilterSets;

  const applyHandler = useCallback(() => {
    dispatch(getCurrentAccountFilterTemplatesThunk());
    if (onApplyCallback) {
      onApplyCallback();
    }
  }, [onApplyCallback, dispatch]);

  const filterTemplatesRowProps = {
    onApply: applyHandler,
    editOrDuplicateMode: editOrDuplicateMode,
    provider,
    editingRowId,
    setEditingRowId,
    duplicatingRowId,
    setDuplicatingRowId,
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitleClosable title="Filter Templates" onClose={onClose} />

      <DialogContent dividers>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ bgcolor: "grey.100" }}>
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                Filter Template
              </TableCell>
              {showEms && (
                <TableCell sx={{ whiteSpace: "nowrap" }}>
                  Filtered Resources
                </TableCell>
              )}
              <TableCell sx={{ whiteSpace: "nowrap" }}>
                Applied Accounts
              </TableCell>
              {showEqualisingCell && <TableCell />}
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {accountFilterSets?.map((accountFilterSet) => (
              <FilterTemplatesRow
                key={accountFilterSet.filter_set.id}
                filterSet={accountFilterSet.filter_set}
                ems={accountFilterSet.ems}
                {...filterTemplatesRowProps}
              />
            ))}
            {filterSets?.map((filterSet) => (
              <FilterTemplatesRow
                hideEms
                key={filterSet.id}
                filterSet={filterSet}
                {...filterTemplatesRowProps}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
    </Dialog>
  );
};
