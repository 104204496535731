import { FC } from "react";
import { Card, Skeleton, Stack, Tooltip, Typography } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface UtilizationAndCoverageMetadataCardProps {
  primary: string;
  value: string;
  loading: boolean;
  valueColor?: string;
  tooltip: string;
}

export const UtilizationAndCoverageMetadataCard: FC<
  UtilizationAndCoverageMetadataCardProps
> = ({ value, valueColor, loading, primary, tooltip }) => {
  return (
    <Card
      variant="outlined"
      sx={{ px: 2, py: 1, flexGrow: 1, borderRadius: 1.5 }}
    >
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography variant="body2" fontWeight="medium" color="text.secondary">
          {primary}
        </Typography>

        <Tooltip title={tooltip} placement="top" arrow>
          <InfoOutlinedIcon sx={{ fontSize: 16, color: "action.disabled" }} />
        </Tooltip>
      </Stack>

      <Typography mt={0.5} variant="h5" fontWeight="medium" color={valueColor}>
        {loading ? loadingSkeleton : value}
      </Typography>
    </Card>
  );
};

const loadingSkeleton = <Skeleton variant="text" height={32} width={150} />;
