import { FilterOperatorType } from "../../../../../../services/cloudchipr.api";

export const getFilterValueDataTypeByOperator = (
  operator: FilterOperatorType,
) => {
  if (["in", "not_in"].includes(operator)) {
    return "array";
  }

  return "string";
};
