import { createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import { enqueueSnackbar } from "notistack";
import {
  cloudChiprApi,
  Date,
  DateDataPointNullable,
  DateLabelNullable,
  MultiCloudFilter,
  PriceDirectionNullable,
  ResourceExplorerDataOverrideFilters,
  ResourceExplorerGrouping,
  SortByNullable,
  TrendType,
  WidgetType,
} from "../../../../../services/cloudchipr.api";
import { generateDateFromDateLabelV1 } from "../../../../../components/common/date-range-picker/utils/helpers/generateDateFromDateLabelV1";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";
import { getFetchWidgetViewDataThunkFixedCacheKey } from "../../../utils/helpers/fixedCahcheKeys";
import { RootState } from "../../../../store";
import { organisationQuarterStartMonthSelector } from "../../../../common/selectors/org-current-settings/organisationQuarterStartMonthSelector";
import { widgetDataNotFoundSelector } from "../../../selectors/widgets/common/widgetDataNotFoundSelector";

interface FetchCostBreakdownWidgetDataThunkArgs {
  widgetId?: string;
  widgetType: WidgetType;
  resourceExplorerViewId: string;
  dateTo: Date | null;
  dateFrom?: Date | null;
  dateLabel: DateLabelNullable;
  grouping?: ResourceExplorerGrouping;
  groupValues?: string[] | null;
  frequency?: DateDataPointNullable;
  filters?: MultiCloudFilter[];
  fixedCacheKey?: string;
  onlySummary?: ResourceExplorerDataOverrideFilters["only_summary"];
  loadDetailedPreviousPeriodData?: ResourceExplorerDataOverrideFilters["load_detailed_previous_period_data"];
  priceDirection?: PriceDirectionNullable;
  sortingBy?: SortByNullable;
  itemsCount?: number | null;
  minCostChange?: number | null;
  minPercentageChange?: number | null;
  quarterlyForecast?: boolean;
  monthlyForecast?: boolean;
  trendType?: TrendType;
}

export const getWidgetDataByViewIdThunk = createAsyncThunk(
  "dashboards/getWidgetDataByViewId",
  async (
    {
      widgetId,
      resourceExplorerViewId,
      dateFrom,
      dateTo,
      grouping,
      groupValues,
      dateLabel,
      frequency,
      fixedCacheKey,
      onlySummary,
      loadDetailedPreviousPeriodData,
      priceDirection,
      sortingBy,
      itemsCount,
      minCostChange,
      widgetType,
      minPercentageChange,
      quarterlyForecast,
      monthlyForecast,
      filters,
      trendType,
    }: FetchCostBreakdownWidgetDataThunkArgs,
    { dispatch, getState },
  ) => {
    const state = getState() as RootState;
    const {
      postUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId,
    } = cloudChiprApi.endpoints;

    const quarterStartMonth = organisationQuarterStartMonthSelector(state);

    const range = generateDateFromDateLabelV1(dateLabel, quarterStartMonth);
    const to = formatDate(moment(range?.endDate));
    const from = formatDate(moment(range?.startDate));
    const to_date = dateTo ?? to;
    const from_date = dateFrom ?? from;

    const widgetNotFound = widgetDataNotFoundSelector(
      state,
      widgetId ?? "",
      widgetType,
    );

    const resourceExplorerDataOverrideFilters: ResourceExplorerDataOverrideFilters =
      {
        grouping,
        filters,
        to_date: dateLabel ? null : to_date,
        from_date: dateLabel ? null : from_date,
        date_label: dateLabel ?? null,
        group_values: groupValues ?? undefined,
        data_point: frequency,
        only_summary: onlySummary,
        price_direction: priceDirection,
        sort_by: sortingBy,
        items_count: itemsCount,
        min_cost_change: minCostChange,
        min_percentage_change: minPercentageChange,
        load_detailed_previous_period_data: loadDetailedPreviousPeriodData,
        load_quarterly_forecast_for_grouping_items: quarterlyForecast,
        load_monthly_forecast_for_grouping_items: monthlyForecast,
        trend_type: trendType,
      };

    try {
      return await dispatch(
        postUsersMeOrganisationsCurrentResourceExplorerViewsByResourceExplorerViewId.initiate(
          {
            resourceExplorerViewId,
            resourceExplorerDataOverrideFilters,
          },
          {
            fixedCacheKey:
              fixedCacheKey ??
              getFetchWidgetViewDataThunkFixedCacheKey(widgetId),
          },
        ),
      ).unwrap();
    } catch (e) {
      if (!widgetNotFound) {
        // @ts-expect-error todo: api fix
        const errMessage = e?.data?.message || "Something went wrong";
        enqueueSnackbar(errMessage, {
          variant: "snackbarAlert",
          AlertSnackBarProps: { severity: "error" },
        });
      }
    }
  },
);
