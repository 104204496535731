import { ChangeEvent, FC, useCallback, useState } from "react";
import { useDebounce, useEffectOnceWhen } from "rooks";
import { TextField } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../../../../store/hooks";
import { setAutomationData } from "../../../../../../store/automations/automationsSlice";
import { automationDescriptionSelector } from "../../../../../../store/automations/selectros/common/fields/automationDescriptionSelector";

export const AutomationDescription: FC = () => {
  const dispatch = useAppDispatch();

  const description = useAppSelector(automationDescriptionSelector);

  const [value, setValue] = useState<string | null>(null);

  const descriptionChangeDispatcher = useCallback(
    (description: string) => {
      dispatch(
        setAutomationData({
          description,
        }),
      );
    },
    [dispatch],
  );

  const handleDescriptionChange = useDebounce(descriptionChangeDispatcher, 500);

  const nameChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      setValue(value);
      handleDescriptionChange(value);
    },
    [handleDescriptionChange],
  );

  useEffectOnceWhen(
    () => setValue(description),
    !!(description && value === null),
  );

  return (
    <TextField
      fullWidth
      size="small"
      value={value ?? ""}
      type="text"
      sx={{ mt: 0.5, bgcolor: "white" }}
      label="Description"
      onChange={nameChangeHandler}
    />
  );
};
