import { RootState } from "../../../../store";
import { automationSliceSelector } from "../../common/automationSliceSelector";
import { PostV2UsersMeOffHoursSchedulesApiArg } from "../../../../../services/cloudchipr.api";
import { formatWorkflowNotificationsFromHtmlToSlack } from "../../../../../components/pages/schedule/classic/utils/helpers/formatWorkflowNotificationsFromHtmlToSlack";
import { generateCronForPayload } from "../../../utils/helpers/cron/generateCronForPayload";
import { formatDate } from "../../../../../utils/helpers/date-time/datetime-format";

export const offHoursPayloadDataSelector = (
  state: RootState,
): PostV2UsersMeOffHoursSchedulesApiArg["body"] => {
  const slice = automationSliceSelector(state);

  const automationData = slice.data;
  const offHoursData = slice.offHoursSpecific;
  const frequency = automationData.frequency;
  const repeatValue = offHoursData?.scheduler?.repeatValue;
  const stopTime = offHoursData?.scheduler?.stopTime ?? null;
  const startTime = offHoursData?.scheduler?.startTime ?? null;
  const stopWeekDays = offHoursData?.scheduler?.stopWeekDays;
  const startWeekDays = offHoursData?.scheduler?.startWeekDays;

  const stopCron = generateCronForPayload(
    frequency,
    stopTime,
    repeatValue,
    stopWeekDays,
  );
  const startCron = generateCronForPayload(
    frequency,
    startTime,
    repeatValue,
    startWeekDays,
  );

  const updatedNotifications = formatWorkflowNotificationsFromHtmlToSlack(
    automationData.notifications,
  );

  const startDate = automationData.startDate
    ? formatDate(automationData.startDate)
    : null;

  const endDate = automationData.endDate
    ? formatDate(automationData.endDate)
    : null;

  const formatedStopTime = stopTime ? formatDate(stopTime) : null;
  const formatedStartTime = startTime ? formatDate(startTime) : null;

  const startDateTime = frequency === "once" ? formatedStopTime : startDate;
  const endDateTime = frequency === "once" ? formatedStartTime : endDate;

  return {
    name: automationData.name,
    description: automationData.description || null,

    stop_cron: stopCron,
    start_cron: startCron,
    start_date_time: startDateTime,
    end_date_time: endDateTime,
    time_zone: automationData.timeZone,

    account_ids: automationData.accountIds,
    regions: automationData.regions,
    min_threshold: automationData?.threshold,

    action: offHoursData?.action ?? "silent",
    notifications: updatedNotifications,
    emails: automationData.emails,

    filter: automationData.filter,
  };
};
