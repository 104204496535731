import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import { DimensionDrawerHeader } from "./DimensionDrawerHeader";
import { DimensionName } from "./DimensionName";
import { AddCategoryButton } from "./categories/AddCategoryButton";
import { DimensionFormActions } from "./DimensionFormActions";
import { DimensionsCategoriesList } from "./categories/DimensionsCategoriesList";

interface DimensionDrawerFormProps {
  onClose(): void;
}

export const DimensionDrawerForm: FC<DimensionDrawerFormProps> = ({
  onClose,
}) => {
  return (
    <Fragment>
      <DimensionDrawerHeader onClose={onClose} />

      <Stack spacing={2} p={2} pb={0}>
        <DimensionName />

        <DimensionsCategoriesList />

        <AddCategoryButton />
      </Stack>

      <DimensionFormActions onClose={onClose} />
    </Fragment>
  );
};
