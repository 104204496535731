import { FC } from "react";
import { Box, Card } from "@mui/material";
import { UtilizationAndCoverageGaugeChart } from "./chart/gauge-chart/UtilizationAndCoverageGaugeChart";
import { UtilizationAndCoverageDataGrid } from "./data-grid/UtilizationAndCoverageDataGrid";
import { UtilizationAndCoverageMultiTypeChart } from "./chart/multi-type-chart/UtilizationAndCoverageMultiTypeChart";
import { CommitmentsRecommendationsDataGrid } from "./recommendations/CommitmentsRecommendationsDataGrid";

export const UtilizationAndCoverageContent: FC = () => {
  return (
    <Card sx={{ p: 2 }} variant="outlined">
      <UtilizationAndCoverageMultiTypeChart />
      <UtilizationAndCoverageGaugeChart />

      <Box mt={4}>
        <UtilizationAndCoverageDataGrid />
        <CommitmentsRecommendationsDataGrid />
      </Box>
    </Card>
  );
};
