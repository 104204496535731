import { FC, Fragment } from "react";
import { Stack } from "@mui/material";
import {
  NullableDate,
  ScheduleStatus,
} from "../../../../../../services/cloudchipr.api";
import { SchedulesDataGridStatusChip } from "../../../common/list/SchedulesDataGridStatusChip";
import { ScheduledMoreNextRuns } from "../common/ScheduledMoreNextRuns";
import { ScheduledNextRunDateTime } from "../common/ScheduledNextRunDateTime";
import { ScheduledNextRunDateTimeZone } from "../common/ScheduledNextRunDateTimeZone";
import { useAppSelector } from "../../../../../../store/hooks";
import { scheduleNextRunsByScheduleIdSelector } from "../../../../../../store/schedules/selectors/schedule/next-run/scheduleNextRunsByScheduleIdSelector";

interface ScheduledListGridNextRunCellProps {
  status: ScheduleStatus;
  nextRun?: NullableDate;
  timeZone: string;
  id: string;
}

export const ScheduledJobsDataGridNextRunCell: FC<
  ScheduledListGridNextRunCellProps
> = ({ status, nextRun, timeZone, id }) => {
  const nextRuns = useAppSelector((state) =>
    scheduleNextRunsByScheduleIdSelector(state, id),
  );

  if (status === "inactive") {
    return <SchedulesDataGridStatusChip status={status} />;
  }

  return (
    <Fragment>
      {nextRun ? (
        <Stack>
          <ScheduledNextRunDateTime nextRun={nextRun} />
          <Stack direction="row" spacing={1}>
            <ScheduledNextRunDateTimeZone timeZone={timeZone} />
            <ScheduledMoreNextRuns
              nextRun={nextRun}
              nextRunList={nextRuns}
              timeZone={timeZone}
            />
          </Stack>
        </Stack>
      ) : (
        "Never"
      )}
    </Fragment>
  );
};
